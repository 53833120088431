.segments-screen {
  min-height: 100vh;
}

.segments-screen__container {
  /*background-color: #fbfbfb;*/
  /* min-height: 100vh; */
}

.segments-screen-segment-list-container {
  border-left: 1px solid #eaedf3;
  border-right: 1px solid #eaedf3;
  border-top: 1px solid #eaedf3;
  padding: 0px !important;
  background-color: #ffffff;
  height: calc(100vh - 71px);
  overflow: auto;
}

.segments-screen-segment-search {
  border-bottom: 1px solid #eaedf3;
  padding: 13px 22px;
}

.segments-screen-segment-search-input {
  border: none;
}

.segment-search-input-container {
  display: inline-block;
  width: 70%;
}

.segment-invite-button-container {
  float: right;
}

.segments-screen-segment-form-container {
  height: calc(100vh - 71px);
  overflow: auto;
}
