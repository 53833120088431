.editorContainer {
  height: calc(100% - 135px) !important;
}

.noFlexZone {
  flex: none !important;
}

.closeEditorButton {
  margin-left: auto;
  font-size: 13px;
}

.onlyOfficeInstructions {
  display: block;
  height: 1.3em;
  margin-top: 10px;
  font-size: 13px;
}

.onlyOfficeHeader {
  padding: 10px;
}

.fullHeight {
  height: calc(100% - 18px) !important;

  @at-root div#{&} {
    height: 100% !important;

    @at-root div#{&} {
      height: calc(100% - 18px) !important;
    }
  }
}
