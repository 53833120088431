.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;

  .checkbox {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    background: linear-gradient(0deg, #f6f7f9 0%, #ffffff 100%);
    border-radius: 3px;
    border: 1px solid #d8dce6;
    margin: 0;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
    &.checked {
      background: #1b8c96;
      border: 1px solid #1b8c96;
    }
  }
  .label {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #3e3f42;
  }
  .checkmark {
    position: absolute;
    pointer-events: none;
    &.hidden {
      display: none;
    }
  }
}
