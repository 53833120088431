.inviteUserContainer {
  background-color: #fbfbfb;
  margin-top: 30px;
}

.inviteContainer {
  padding: 0 20px;
}

.inviteActionButtons {
  padding: 20px;
}

.formLabel {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #9ea0a5;
}

.roleSelect {
  margin-top: 0.5rem;
}

.permissionsTitle {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #9ea0a5;
}

.radiobuttonContainer {
  display: flex;
  max-width: 110px;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.libraryCheckbox {
  margin-top: 8px;
  margin-bottom: 8px;
}

.welcomeEmailNote {
  color: #8a8e96;
  font-size: 12px;
}
