.pill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  gap: 10px;

  width: 33px;
  height: 16px;

  background: #eaedf3;
  border-radius: 9.5px;
  font-family: inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: center;
}
