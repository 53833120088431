.leftPanel {
  .backContainer {
    display: flex;
    align-items: center;
    margin-top: 27px;
    margin-bottom: 33px;

    .backLink {
      color: #8a8e96;
    }

    .backText {
      font-weight: 500;
      font-size: 18px;
      color: #3e3f42;
      margin-left: 9px;
    }
  }

  .dateRemindersListContainer {
    background: white;
    border: 1px solid #eaedf3;
    border-radius: 4px;

    .header {
      padding: 28px 25px;
      border-bottom: 1px solid #eaecee;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        .title {
          color: #3e3f42;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
        }

        .description {
          color: #9ea0a5;
          font-weight: 500;
          font-size: 12px;
          line-height: 22px;
        }
      }

      .createButton {
        width: 151px;

        :global(.uik-btn__base) {
          width: 100%;
          padding: 0;
        }
      }
    }

    .body {
      .emptyContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 400px;
        padding: 0 130px;

        .textOne {
          margin-top: 40px;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #3e3f42;
        }

        .textTwo {
          margin-top: 10px;
          text-align: center;
          font-size: 12px;
        }

        .knowledgeBaseLink {
          margin-top: 10px;
          font-size: 12px;
          text-decoration: underline;
          a {
            color: #1b8c96;
          }
        }
      }

      .list {
        height: calc(100vh - 255px);
        overflow: scroll;

        .libraryDateRemindersContainer {
          margin-top: 28px;

          .libraryName {
            color: #8a8e96;
            padding: 0 29px;
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 7px;
          }

          .dateReminders {
            .dateReminderContainer {
              padding: 12px 29px;
              border-top: 1px solid #eaecee;
              cursor: pointer;

              .label {
                color: #3e3f42;
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 7px;
              }

              .daysBefore {
                color: #8a8e96;
                font-weight: 500;
                font-size: 12px;
              }

              &:last-child {
                border-bottom: 1px solid #eaecee;
              }

              &Selected {
                background: #f7fbfc;
                border-left: 3px solid #1b8c96;
              }
            }
          }
        }
      }
    }
  }
}
