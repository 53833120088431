.webViewerContainer,
.webViewer {
  width: 100%;
  height: 100%;
}

.referenceLabellerContainer {
  display: none;
  background-color: white;
  border-radius: 5px;
  float: left;
  padding: 20px 20px;
  position: absolute;
  left: 50%;
  transform: translate(-100%, 0%);
  filter: drop-shadow(0px 4px 25px rgba(117, 82, 220, 0.5));
}

.referenceLabeller .referenceLabellerField {
  display: table-cell;
  padding-right: 15px;
}

.referenceLabeller .referenceLabellerAction {
  display: table-cell;
  vertical-align: bottom;
}

.refLabelForm {
  display: flex;
}

.refLabel {
  margin-right: 8px;
  text-transform: uppercase;
}

.refLabelInput {
  border: 1px solid #d6dce8;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 10px 30px 10px 15px;
  transition: 0.2s all;
  outline: none;
  font-size: 0.875rem;
  margin-top: 10px;
  background: rgba(234, 235, 239, 1);
}

.refLabelSubmit {
  color: white;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1),
    inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
  cursor: pointer;
  background: linear-gradient(0deg, #1b8c96 0%, #1b8c96 100%) !important;
  border: 1px solid #166f77 !important;
  display: inline-flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  height: 38px;
  padding: 0 18px;
  transition: all 0.1s ease-in-out 0s;
  font-size: 0.875rem;
  font-weight: 400;
  position: relative;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif !important;
  align-self: flex-end;
}

.refLabelFormLabel {
  margin-right: 20px;
  font-weight: 600;
  letter-spacing: 0.3px;
  size: 12px;
  font-size: 12px;
}

.labelContainer {
  display: flex;
}

.closeFailedReference {
  color: rgba(57, 61, 80, 1);
  cursor: pointer;
  font-size: 32px;
  float: right;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 5%;
  right: 3.5%;
}

.hideReferenceLabellerContainer {
  display: none;
}

.inputContainer {
  display: flex;
  position: relative;
}

.pencil {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 22px;
}

.pencilHidden {
  display: none;
}

.checkmark {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 25px;
  display: none;
}

.checkmarkHidden {
  display: none;
}

.confirmationForm {
  display: none;
}

.confirmationMessageContainer {
  display: flex;
  flex-direction: column;
  min-width: 250px;
}

.confirmationMessage {
  margin: 0px 20px 0px 0px;
  font-size: 14px;
}

.changeButton {
  color: #1b8c96;
  background-image: none;
  border-color: #1b8c96;
  font-weight: 500;
  margin-right: 10px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.confirmationTitle {
  text-transform: uppercase;
  font-size: 12px;
}

.refLabelError {
  display: none;
  margin-top: 8px;
  font-size: 12px;
  color: rgba(230, 118, 112, 1);
}

.refDocLabelError {
  display: none;
  margin-top: 8px;
  font-size: 12px;
  color: rgba(230, 118, 112, 1);
}

.refDocLabelErrorContainer {
  width: 50%;
}

.refLabelErrorContainer {
  width: 63%;
}

.labelErrorContainer {
  display: flex;
}

.refIdTooltipBox {
  position: absolute;
  left: 102px;
}

.annotIdTooltipBox {
  position: absolute;
  left: 112px;
}

.toolTipContainer {
  position: relative;
}
