.sidePanelOptions {
  height: 32px;
  width: 270px;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 12px 25px 12px;
  border-radius: 3px;

  &:hover {
    background: #e6f2f3;
    .approvalsLink {
      color: rgba(5, 125, 136, 1);
    }
    .approvalsRemaining {
      background: #dadee6;
      color: #3e3f42;
    }
  }
  &Approvals {
    border-bottom: none;
    // padding-bottom: 0;
  }
  &MyApproval {
    padding: 13px 15px 20px;
  }
}

.viewerSidePanelOptions {
  height: 32px;
  width: 270px;
  font-size: 14px;
  font-weight: 600;
  padding: 20px 12px 10px 12px;
  border-radius: 3px;
  &Approvals {
    border-bottom: none;
    // padding-bottom: 0;
  }
  &MyApproval {
    padding: 13px 15px 20px;
  }
}

.approvalsLink {
  color: #3e3f42;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.viewerApprovalsLink {
  color: #3e3f42;
  font-size: 12px;
  font-weight: 500;
  cursor: default;
}

.approvals {
  display: flex;
  float: left;
  font-size: 14px;
}

.approvalsRemaining {
  float: right;
  width: 40px;
  height: 18px;
  text-align: center;
  background: rgba(234, 237, 243, 1);
  border-radius: 9.5px;
  padding-top: 3px;
  padding-left: 2px;
  padding-right: 2px;
}

.approvalsRemainingIcon {
  i {
    float: right;
    color: #b6b6b6;
    font-size: 16px;
    margin-top: 2px;
  }
}

.icon {
  margin: 2px 15px 0px 0px;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px 0px 35px 0px;
  position: relative;
  width: 302px;
}

.approvalsCard {
  display: flex;
  justify-content: space-between;
}

.progressBar {
  width: 116px;
  margin: 0px 8px;
}

.progressBarNoApprovers {
  width: 120px;
  margin: 0px 10px;
}

.progressCaption {
  font-size: 12px;
  line-height: 0px;
  color: #99a4bb;
  margin-left: 11%;
  position: absolute;
  top: 85px;
}

.progressCaptionComplete {
  font-size: 12px;
  line-height: 0px;
  color: #99a4bb;
  margin-left: -18%;
  position: absolute;
  top: 85px;
}

.progressCaptionReadonly {
  font-size: 12px;
  line-height: 0px;
  color: #99a4bb;
  margin-left: 11%;
  position: absolute;
  top: 85px;
}

.approvalCardReadOnly {
  display: flex;
}

.editLink {
  color: hsla(184.8780487804878, 69.49%, 34.71%, 1);
  display: block;
  float: right;
  font-size: 14px;
}

.divider {
  border-bottom: 1px solid #eaedf3;
  width: 90%;
  margin-bottom: 35px;
}
.approvalsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1440px) {
  .sidePanelOptions {
    height: 32px;
    width: 332px;
    font-size: 14px;
    font-weight: 600;
    padding: 20px 12px 25px 12px;
    border-radius: 3px;

    &:hover {
      background: #e6f2f3;
      .approvalsLink {
        color: rgba(5, 125, 136, 1);
      }
      .approvalsRemaining {
        background: #dadee6;
        color: #3e3f42;
      }
    }
    &Approvals {
      border-bottom: none;
      // padding-bottom: 0;
    }
    &MyApproval {
      padding: 13px 15px 20px;
    }
  }

  .viewerSidePanelOptions {
    height: 32px;
    width: 332px;
    font-size: 14px;
    font-weight: 600;
    padding: 20px 12px 10px 12px;
    border-radius: 3px;
    &Approvals {
      border-bottom: none;
      // padding-bottom: 0;
    }
    &MyApproval {
      padding: 13px 15px 20px;
    }
  }

  .sectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 0px 35px 0px;
    position: relative;
    width: 382px;
  }
  .progressBar {
    width: 185px;
    margin: 0px 14px;
  }

  .progressBarNoApprovers {
    width: 195px;
    margin: 0px 15px;
  }

  .progressCaption {
    font-size: 12px;
    line-height: 0px;
    color: #99a4bb;
    margin-left: -5%;
    position: absolute;
    top: 85px;
  }

  .progressCaptionComplete {
    font-size: 12px;
    line-height: 0px;
    color: #99a4bb;
    margin-left: 15%;
    position: absolute;
    top: 85px;
  }

  .progressCaptionReadonly {
    font-size: 12px;
    line-height: 0px;
    color: #99a4bb;
    margin-left: -5%;
    position: absolute;
    top: 85px;
  }
}
