.container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 20px 5px 20px;
  align-content: center;
  box-sizing: border-box;
  margin-top: 20px;

  .title {
    text-transform: uppercase;
    color: #9aa5bb;
    font-weight: 600;
    font-size: 11px;
  }

  .horizontalLine {
    height: 1px;
    flex: 1;
    background-color: #eaedf3;
    margin-left: 10px;
  }

  .count {
    background: #eaedf3;
    border-radius: 9.5px;
    margin-left: 10px;
    color: #3e3f42;
    width: 33px;
    text-align: center;
  }
}
