.passwordSetupPage {
  height: 100vh;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.container {
  background: #ffffff;
  border: 2px solid #eaedf3;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  min-height: auto;
  width: 384px;
  padding: 32px;
  display: flex;
  flex-direction: column;
}

.setPasswordContainer {
  margin-top: 32px;
}

.passwordCreateScreen {
  background-image: linear-gradient(
    124deg,
    #1b8c96,
    #9decf3 53%,
    #b1edf3 71%,
    #1b8c96
  );
  min-height: 100vh;
  padding: 0 !important;
  margin: 0 !important;
}

.authenticationHeader {
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  height: 70px;
  margin-bottom: 50px;
  text-align: middle;
}

.authenticationLogo {
  margin: 23px 0 0 23px;
}

.passwordCreateLoginForm {
  background-color: #fff;
  min-width: 200px;
  padding: 30px;

  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.passwordCreateLoginForm h2 {
  color: #393d50;
  margin: 0 0 20px 0;
}

.passwordCreateLoginImage {
  text-align: right;
}

.passwordCreateErrorMessage {
  background-color: #be3c25;
  color: #fff;
  font-size: 14px;
  margin: 0 0 20px 0;
  padding: 15px 30px 15px 50px;

  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.forgotPasswordLink {
  color: #aeaeae;
  text-decoration: underline;
}

.logoContainer {
  display: flex;
  justify-content: center;
}

.papercurveLogo {
  width: 45% !important;
}

.passwordSetupBackground {
  background-image: url(../../images/authentication/images/papercurve-workspace-placeholder.png) !important;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.greyFilter {
  height: 100%;
  background: rgba(57, 61, 80, 0.8);
  position: relative;
}

.userEmail {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(138, 142, 150, 1);
  margin-top: 15px;
}

.modalTitle {
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
}

.title {
  position: absolute;
  display: flex;
  top: 6.18vw;
  color: #3e3f42;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-left: 80px;
}

.eyeLogoContainer {
  position: relative;
}

.eyeLogo {
  position: absolute;
  top: 57%;
  right: 5%;
}

.eyeLogoWithError {
  position: absolute;
  top: 40%;
  right: 5%;
}

@media screen and (min-width: 1921px) {
  .title {
    position: absolute;
    display: flex;
    top: 6.5vw;
    color: #3e3f42;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin-left: 100px;
  }
}

@media screen and (max-width: 1280px) {
  .title {
    position: absolute;
    display: flex;
    top: 5.8vw;
    color: #3e3f42;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin-left: 60px;
  }
}
