.draftUploadForm {
  background: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  margin-top: 30px;
  padding-bottom: 30px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 730px;
  :global(.library_select .uik-content-title__wrapper) {
    display: none;
  }
}

.templateHeading {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(62, 63, 66, 1);
  margin-top: 30px;
  margin-bottom: 30px;
}

.libraryDropdown {
  background: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  margin: 30px auto;
  padding: 30px !important;
  width: 730px;

  :global(.library_select .uik-content-title__wrapper) {
    display: none;
  }
}

.librarySelectTitle {
  font-weight: 500;
  color: #3e3f42;
  text-transform: capitalize;
}

.fileUploadContainer {
  padding: 0px 30px 30px 30px;
}

.templateUploadContainer {
  padding: 15px 30px 30px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.templateTabContainer {
  display: flex;
  flex-direction: column;
}

.tabsContainer {
  display: flex;
  width: 100%;
}

.fileTab {
  padding: 15px 0px 15px 0px;
  text-align: center;
  width: 50%;
  text-transform: uppercase;
  font-weight: 500;
  color: #3e3f42;
  font-size: 12px;
  cursor: pointer;
  border-bottom: 1px solid #f5f5f5;
}

.fileTabActive {
  padding: 15px 0px 15px 0px;
  text-align: center;
  border-bottom: 1px solid #1b8c96;
  width: 50%;
  text-transform: uppercase;
  font-weight: 500;
  color: #1b8c96;
  font-size: 12px;
  cursor: pointer;
}

.templateTab {
  padding: 15px 0px 15px 0px;
  text-align: center;
  width: 50%;
  text-transform: uppercase;
  font-weight: 500;
  color: #3e3f42;
  font-size: 12px;
  cursor: pointer;
  border-bottom: 1px solid #f5f5f5;
}

.templateTabActive {
  padding: 15px 0px 15px 0px;
  text-align: center;
  border-bottom: 1px solid #1b8c96;
  width: 50%;
  border-bottom: 1px solid #1b8c96;
  text-transform: uppercase;
  font-weight: 500;
  color: #1b8c96;
  font-size: 12px;
  cursor: pointer;
}

.uploadError {
  width: 470px;
  height: 22px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #e67670;
  float: left;
  width: 86%;
}

.draftUploadFormActions {
  background: none;
  margin: 30px auto;
  text-align: right;
  width: 730px;
}

.mainContentUploader {
  border: 1px dashed #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
  margin-top: 15px;

  &Error {
    border-style: solid;
    border-color: #e67670;
  }
}

.templateUploader {
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
  margin-top: 15px;

  // &Error {
  //   border-style: solid;
  //   border-color: #e67670;
  // }
}

.mainContentUploading {
  background-color: #fbfbfd;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  margin-top: 15px;

  .firstRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .contentLeft {
      display: flex;
      align-items: center;

      .iconContainer {
        display: flex;

        img {
          width: 22px;
          height: 26px;
        }
      }
    }
  }
}

.documentDetailsContainer {
  margin-left: 30px;
  margin-right: 30px;
}

.innerContentUploading {
  line-height: 30px;
  width: 100%;
}

.uploaderIcon {
  color: #9ea0a5;
  display: inline-block;
  line-height: 30px;
  margin: 10px 10px 0;
  float: left;
}

.uploaderIconCheck {
  color: #15cd93;
  display: inline-block;
  line-height: 22px;
  margin: 10px 0 0 0;
}

.uploaderPlaceholder {
  color: #9ea0a5;
  font-size: 14px;
  line-height: 45px;
}

.uploaderButton {
  float: right;
}

.uploadProgress {
  height: 4px;
  width: 100%;
  border-radius: 2px;
}

.uploadingFilename {
  width: 90%;
  color: #3e3f42;
  font-size: 14px;
  line-height: 20px;
  float: left;
  padding-top: 2px;
  margin-left: 13px;
}

.referenceContentUploader {
  border: 1px dashed #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.referenceContentUploading {
  background-color: #fbfbfd;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.checkMark {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.metadataSingleLineInputFields {
  float: left;
  width: 45%;
}

.librarySelect {
  margin-bottom: 10px;
}

.audienceSelect {
  margin-bottom: 10px;
}

.metadataMultilineInputFields {
  float: left;
  margin-left: 30px;
  width: 50%;
}

.processingContainer {
  float: right;
  line-height: 32px;
}

.selectedUploadType {
  background: #1b8c96 !important;
  color: #fff;
}

.uploadFileNote {
  color: #9ea0a5;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: 4px;
  margin-bottom: 0px;
}

.uploadTemplateNote {
  width: 70%;
  color: #9ea0a5;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: 4px;
  margin-bottom: 0px;
}

.editActionButtons {
}

.sequentialLabelContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
}

.formatNoteContainer {
  margin-top: 27px;
}

.uploadFileHeader {
  font-weight: 500;
  color: #3e3f42;
  text-transform: capitalize;
}

.templateHeader {
  font-weight: 500;
  color: #3e3f42;
}

.metadataField {
  margin-top: 10px;
}

.textMetadataField {
  margin: 10px 0 10px 0;
}

.metadataFieldLabel {
  color: #9b9b9b;
  display: block;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-transform: uppercase;
}

.metadataFieldLabelSequentials {
  color: #9b9b9b;
  display: block;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-transform: uppercase;
  display: flex;
}

.metadataFieldValue {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #393d50;
}

.documentTitleHeader {
  text-transform: capitalize;
  margin-top: 27px;
  font-weight: 500;
  color: #3e3f42;
  margin-bottom: 10px;
}

.metadataContainer {
  margin-top: 27px;
}

.contentPropertiesHeader {
  font-weight: 500;
  color: #3e3f42;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.uploaderBody {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .contentLeft {
    display: flex;
    align-items: center;
  }
}

.mainDocumentUploadErrorContainer {
  display: flex;
  align-items: center;
  line-height: 22px;
  margin-top: 8.5px;

  .warningIcon {
    display: flex;
    img {
      width: 15px;
      height: 15px;
    }
  }

  .errorMessage {
    margin-left: 7px;
    font-weight: 600;
    color: #e67670;
    font-size: 12px;
  }
}

.titleInputFieldError {
  border-color: #e6492d !important;
}

.emptyTitleError {
  font-size: 12px;
  color: #e6492d;
  margin-top: 6px;
  line-height: 1.375rem;
}

.kbLink {
  color: #1b8c96;
}

.externalLink {
  margin-left: 10px;
}

.renameContentHeadline {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  padding: 5px 25px;
}

.renameContentFormContainer {
  padding: 24px;
}

.renameContentFormButtons {
  padding: 20px 30px;
}

.renameCancelButton {
  margin-right: 20px;
}

.templateNext:disabled {
  background: rgba(234, 237, 243, 1) !important;
  border: 1px solid rgba(234, 237, 243, 1) !important;
  color: rgba(186, 192, 203, 1) !important;
  margin-left: 0px;
  font-weight: 500;
  font-size: 14px;
}

.templateUploadErrorState {
  border: 1px solid #e67670;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 15px;
  padding: 15px 30px 30px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.warningIconSmall {
  margin-right: 10px;
  height: 15px;
}

.errorText {
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-bottom: 15px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(230, 118, 112, 1);
}

.autoApproveContainer {
  background: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  padding: 0px 30px 0px 30px !important;
  width: 730px;
  border-top: none;
}

.approvalToggleContainer {
  display: flex;
  align-items: center;
  min-height: 100px;
}

.approvalToggleLabel {
  display: flex;
  margin-right: 100px;
}

.toolTipContainer {
  margin-top: 2.5px;
  margin-left: 10px;
  position: relative;
}

.passwordConfirmation {
  min-height: 100px;
  padding-left: 15px;

  .confirmPasswordLabel {
    color: #9ea0a5;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  :global(.button-primary__original) {
    width: 100%;
    margin-top: 10px;
    &:disabled {
      color: white;
      border: 1px solid #166f77;
      background: linear-gradient(
        0deg,
        #1b8c96 2.22%,
        #1b8c96 56.57%,
        #1b8c96 98.44%
      );
      opacity: 0.5;
    }
  }
  :global(.uik-input__input) {
    &:focus {
      border-color: #1b8c96;
    }
  }
  &.passwordInvalid {
    .passwordInvalidLabel {
      color: #e67670;
      font-size: 12px;
    }
    :global(.uik-input__input) {
      border-color: #e67670;
    }
  }
}

.annotIdTooltipBox {
  // position: absolute;
  // left: 112px;
}

.passwordAbsentLabel {
  color: #e67670;
  font-size: 12px;
}
