.approvalCardContainer {
  padding: 16px 24px;
  border-radius: 3px;
  position: relative;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .clipboardImg {
      display: flex;
    }

    .title {
      font-weight: 600;
      font-size: 11px;
      text-transform: uppercase;
      color: #fff;
      margin-left: 10px;

      &Grey {
        color: #6b6c6f;
      }
    }
  }

  .body {
    color: white;
    .submitApproval {
      margin-top: 8px;

      .nowButton {
        background-color: #ff9a31;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        padding: 8px 0;
        cursor: pointer;
      }

      .requestNewVersion {
        margin-top: 9px;
        font-size: 11px;
        font-weight: normal;
        text-align: center;
        cursor: pointer;
      }
    }

    .approvalContainer {
      .approvalSelect {
        border: 1px solid #d6dce8;
        height: 34px;
        border-radius: 4px;
        margin-top: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 10px;
        cursor: pointer;
        background-color: white;
        color: #808080;
        font-weight: normal;

        &ReadOnly {
          cursor: auto;
        }
      }

      .approvalOptionsContainer {
        width: 100%;
        padding: 20px 8px;
        box-sizing: border-box;
        position: absolute;
        box-shadow: 0px 0px 16px rgba(28, 20, 1, 0.16);
        border-radius: 8px;
        background: white;
        z-index: 1;
        top: 116px;
        left: 0;

        .approvalSelectOptions {
          padding: 0 15px;
          .approvalOption {
            margin-bottom: 10px;
            :global(.uik-checkbox__label) {
              color: #808080;
              font-weight: normal;
            }
          }
          margin-bottom: 20px;
        }

        .passwordConfirmation {
          border-top: 1px solid #eaedf3;
          padding: 15px 15px 0;

          .confirmPasswordLabel {
            color: #9ea0a5;
            text-transform: uppercase;
            font-size: 12px;
            margin-bottom: 2px;
          }
          :global(.button-primary__original) {
            width: 100%;
            margin-top: 10px;
            &:disabled {
              color: white;
              border: 1px solid #166f77;
              background: linear-gradient(
                0deg,
                #1b8c96 2.22%,
                #1b8c96 56.57%,
                #1b8c96 98.44%
              );
              opacity: 0.5;
            }
          }
          :global(.uik-input__input) {
            &:focus {
              border-color: #1b8c96;
            }
          }
          &.passwordInvalid {
            .passwordInvalidLabel {
              color: #e67670;
              font-size: 12px;
            }
            :global(.uik-input__input) {
              border-color: #e67670;
            }
          }
        }
      }
    }
  }

  &Orange {
    background-color: #fe8303;
    border: 1px solid #fe8303;
  }

  &Teal {
    background-color: #1b8c96;
    border: 1px solid #1b8c96;
  }

  &White {
    background-color: white;
    border: 1px solid #eaedf3;
  }
}
