.filterItemContainer {
  position: relative;
}

.dropDownButton {
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #9ea0a5;
}

.menu {
  background: #fff;
  position: absolute;
  top: 100%;
  z-index: 999;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #d8dce6;
  border-radius: 4px;
  margin-top: 4px;
  opacity: 0;
  padding: 30px 30px 0;
  box-shadow: 3px 14px 40px 0 rgba(57, 61, 80, 0.24);
}

.menuShow {
  display: block;
  -webkit-animation: fadeInTop 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fadeInTop 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.menuHide {
  display: none;
  -webkit-animation: fadeOutTop 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fadeOutTop 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.buttonActive {
  border: solid 1px #1b8c96 !important;
  color: #fff;
  background: #1b8c96 !important;
}

.filterItemFooter {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.clearButton {
  color: #1b8c96;
  padding: 10px 10px 10px 0;
  cursor: pointer;
}

.clearButtonInactive {
  color: #d6dce8;
}

.saveButton {
  background: #1b8c96;
  color: #fff;
  padding: 10px 60px;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-11-22 11:44:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@-webkit-keyframes fadeInTop {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeInTop {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeOutTop {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes fadeOutTop {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0;
  }
}
