.webViewerContainer,
.webViewer {
  width: 100%;
  height: 100%;
  background: #393d50;
  position: relative;
}

.documentViewer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.headerContainer {
  margin-right: 15px;
  margin-left: 15px;
}

.errorState {
  height: 95%;
  width: 97.5%;
  background-color: #2c3041;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorStateContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(138, 142, 150, 0.495083);
}

.warningLineOne {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 44px;
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
}

.warningLineTwo {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #8a8e96;
  margin-top: 0px;
}

.loaderContainer {
  position: absolute;
  top: 45%;
  left: 50%;
}
