.diffTitle {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: rgba(214, 220, 232, 1);
  text-transform: uppercase;
  margin-bottom: 20px;
}

.claimDiffContainer {
  padding-left: 6%;
}
