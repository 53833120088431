.setpassword-container {
  .uik-content-title__wrapper {
    color: #3e3f42;
  }
  .uik-input__input {
    &:focus {
      border-color: #1b8c96;
    }
  }

  .button-primary__original {
    width: 100%;
    &:disabled {
      color: white;
      border: 1px solid #166f77;
      background: linear-gradient(
        0deg,
        #1b8c96 2.22%,
        #1b8c96 56.57%,
        #1b8c96 98.44%
      );
      opacity: 0.5;
    }
  }
}
