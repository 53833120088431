.settings {
  min-height: 100vh;
}

.settingsContainer {
  min-height: 100vh;
}

.listContainer {
  border-left: 1px solid #eaedf3;
  border-right: 1px solid #eaedf3;
  padding: 0px !important;
  border-top: 1px solid #eaedf3;
  background-color: #ffffff;
}

.createContainer {
  border-bottom: 1px solid #eaedf3;
  padding: 13px 22px;
  height: 38px;
}

.buttonContainer {
  float: right;
}
