.heading {
  margin: 0 auto;
  text-align: center;
}

.mainHeadline {
  font-weight: 500;
}

.mainContentTitle {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #6b6c6f;
  display: inline-block;
  margin-bottom: 30px;
}
