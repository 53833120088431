.hs-chat {
  height: 38px;
  width: 38px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.hs-chat-dark-mode {
  background-color: rgba(0, 0, 0, 0) !important;
}

.badge {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #fff;
  padding: 2px 5px;
  border-radius: 50%;
  background: #e67670;
  color: white;
  position: absolute;
  margin: 0 0 20px 20px;
}
