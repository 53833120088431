.mobileErrorState {
  display: none;
}

.videoContainer {
  display: flex;
  flex-direction: row;
  background-color: white;
  height: calc(100vh - 70px);
  width: 50vw;

  .documentContainer {
    margin-top: 30px;
  }

  .webViewerContainer {
    width: 100%;
    flex: 1;
  }

  .errorState {
    width: 0px;
    height: 100%;
    position: fixed;
    right: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      .header {
        padding-right: calc(100vh - 625px) !important;
        font-weight: 800;
        font-size: 50px;
        line-height: 137px;
        text-transform: uppercase;
        color: #1b8c96;
        padding: 0;
      }

      .text {
        font-weight: 500;
        font-size: 24px;
        line-height: 37px;
        color: #3e3f42;
      }
    }
  }
}

.videoContainerApprovalsPresent {
  display: flex;
  flex-direction: row;
  background-color: white;
  height: calc(100vh - 70px);
  width: 50vw;

  .documentContainer {
    margin-top: 30px;
  }

  .webViewerContainer {
    width: 100%;
    flex: 1;
  }

  .errorState {
    width: 0px;
    height: 100%;
    position: fixed;
    right: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      .header {
        padding-right: calc(100vh - 625px) !important;
        font-weight: 800;
        font-size: 50px;
        line-height: 137px;
        text-transform: uppercase;
        color: #1b8c96;
        padding: 0;
      }

      .text {
        font-weight: 500;
        font-size: 24px;
        line-height: 37px;
        color: #3e3f42;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: row;
  background-color: white;
  height: calc(100vh - 70px);
  width: calc(100vw - 320px) !important;

  .documentContainer {
    margin-top: 30px;
  }

  .webViewerContainer {
    width: 100%;
    flex: 1;
  }

  .errorState {
    width: 0px;
    height: 100%;
    position: fixed;
    right: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      .header {
        padding-right: calc(100vh - 625px) !important;
        font-weight: 800;
        font-size: 50px;
        line-height: 137px;
        text-transform: uppercase;
        color: #1b8c96;
        padding: 0;
      }

      .text {
        font-weight: 500;
        font-size: 24px;
        line-height: 37px;
        color: #3e3f42;
      }
    }
  }
}

.sidePanelSectionContainer {
  width: 320px;
  margin-top: 15px;
  position: fixed;
  right: 0;
}

.containerApprovalsPresent {
  display: flex;
  flex-direction: row;
  background-color: white;
  height: calc(100vh - 70px);
  width: calc(100vw - 320px) !important;

  .documentContainer {
    margin-top: 30px;
  }

  .webViewerContainer {
    width: 100%;
    flex: 1;
  }

  .errorState {
    width: 0px;
    height: 100%;
    position: fixed;
    right: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      .header {
        padding-right: calc(100vh - 625px) !important;
        font-weight: 800;
        font-size: 50px;
        line-height: 137px;
        text-transform: uppercase;
        color: #1b8c96;
        padding: 0;
      }

      .text {
        font-weight: 500;
        font-size: 24px;
        line-height: 37px;
        color: #3e3f42;
      }
    }
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.menuContainer {
  display: none;
}

.header {
  border-top: 1px solid #eaedf3;
  color: #3e3f42;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  padding: 20px 21px;
  background-color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 68%;
  white-space: nowrap;
}

.sidePanelContainer {
  width: 320px;
  margin-top: 15px;
  position: fixed;
  right: 0;
  margin: 50px 0px 0px 20px;
}

.videoSidePanelContainer {
  width: 320px;
  margin-top: 15px;
  position: fixed;
  right: 0;
  margin: 0px 0px 0px 20px;
}

.reviewerScreenContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}

@media (min-width: 802px) {
  .downloadButtonDesktop {
    border: 1px solid #1b8c96;
    cursor: pointer;
    color: #1b8c96;
    background: white;
    border-radius: 4px;
    width: 100px;
    height: 30px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .downloadButtonMobile {
    display: none;
    cursor: pointer;
    margin-right: 10px;
  }
}

@media (max-width: 801px) {
  .downloadButtonMobile {
    display: inline-block;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 20px;
  }

  .downloadButtonDesktop {
    display: none;
  }

  .errorState {
    display: none !important;
  }

  .submissionConfirmation {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100%;
    background-color: #15cd93;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 70px);
    background-color: white;

    width: 100vw !important;

    .documentContainer {
      margin-top: 30px;
    }

    .webViewerContainer {
      width: 100%;
      flex: 1;
    }

    .mobileErrorState {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .errorImage {
        display: flex;
        justify-content: center;

        img {
          width: 80%;
        }
      }

      .mobileErrorStateText {
        display: flex;
        flex-direction: column;
        align-items: center;
        .mobileErrorStateHeader {
          font-weight: 800;
          font-size: 40px;
          line-height: 137px;
          text-transform: uppercase;
          color: #1b8c96;
          padding: 0;
        }

        .Errortext {
          font-weight: 500;
          font-size: 24px;
          line-height: 37px;
          color: #3e3f42;
          text-align: center;
        }
      }
    }
  }

  .containerApprovalsPresent {
    display: flex;
    flex-direction: row;
    background-color: white;
    height: calc(100vh - 145px);
    width: 100vw !important;

    .errorImage {
      width: 70%;
    }

    .documentContainer {
      margin-top: 30px;
    }

    .webViewerContainer {
      width: 100%;
      flex: 1;
    }

    .mobileErrorState {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .mobileErrorStateText {
        .mobileErrorStateHeader {
          font-weight: 800;
          font-size: 40px;
          line-height: 137px;
          text-transform: uppercase;
          color: #1b8c96;
          padding: 0;
        }

        .Errortext {
          font-weight: 500;
          font-size: 24px;
          line-height: 37px;
          color: #3e3f42;
        }
      }
    }
  }

  .sidePanelContainer {
    display: none;
  }

  .videoSidePanelContainer {
    display: none;
  }

  .mobileSidePanelContainer {
    margin-top: calc(100% - 323px);
    width: 100vw;
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    height: calc(100vh - calc(100% - 794px));
  }

  .menuContainer {
    display: flex;
    padding: 20px 21px;
  }
}

@media (max-width: 600px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */

  .errorState {
    display: none !important;
  }

  .submissionConfirmation {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100%;
    background-color: #15cd93;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 70px);
    background-color: white;

    width: 100vw !important;

    .documentContainer {
      margin-top: 30px;
    }

    .webViewerContainer {
      width: 100%;
      flex: 1;
    }

    .mobileErrorState {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .errorImage {
        display: flex;
        justify-content: center;

        img {
          width: 80%;
        }
      }

      .mobileErrorStateText {
        display: flex;
        flex-direction: column;
        align-items: center;
        .mobileErrorStateHeader {
          font-weight: 800;
          font-size: 40px;
          line-height: 137px;
          text-transform: uppercase;
          color: #1b8c96;
          padding: 0;
        }

        .Errortext {
          font-weight: 500;
          font-size: 24px;
          line-height: 37px;
          color: #3e3f42;
          text-align: center;
        }
      }
    }
  }

  .containerApprovalsPresent {
    display: flex;
    flex-direction: row;
    background-color: white;
    height: calc(100vh - 145px);
    width: 100vw !important;

    .errorImage {
      width: 70%;
    }

    .documentContainer {
      margin-top: 30px;
    }

    .webViewerContainer {
      width: 100%;
      flex: 1;
    }

    .mobileErrorState {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .mobileErrorStateText {
        .mobileErrorStateHeader {
          font-weight: 800;
          font-size: 40px;
          line-height: 137px;
          text-transform: uppercase;
          color: #1b8c96;
          padding: 0;
        }

        .Errortext {
          font-weight: 500;
          font-size: 24px;
          line-height: 37px;
          color: #3e3f42;
        }
      }
    }
  }

  .sidePanelContainer {
    display: none;
  }

  .videoSidePanelContainer {
    display: none;
  }

  .mobileSidePanelContainer {
    margin-top: calc(100% - 323px);
    width: 100vw;
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    height: calc(100vh - calc(100% - 794px));
  }

  .menuContainer {
    display: flex;
    padding: 20px 21px;
  }
}

@media screen and (min-width: 1440px) {
  .containerApprovalsPresent {
    display: flex;
    flex-direction: row;
    background-color: white;
    height: calc(100vh - 70px);
    width: calc(100vw - 400px) !important;

    .documentContainer {
      margin-top: 30px;
    }

    .webViewerContainer {
      width: 100%;
      flex: 1;
    }

    .errorState {
      width: 0px;
      height: 100%;
      position: fixed;
      right: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        .header {
          padding-right: calc(100vh - 625px) !important;
          font-weight: 800;
          font-size: 50px;
          line-height: 137px;
          text-transform: uppercase;
          color: #1b8c96;
          padding: 0;
        }

        .text {
          font-weight: 500;
          font-size: 24px;
          line-height: 37px;
          color: #3e3f42;
        }
      }
    }
  }

  .container {
    display: flex;
    flex-direction: row;
    background-color: white;
    height: calc(100vh - 70px);
    width: calc(100vw - 400px) !important;

    .documentContainer {
      margin-top: 30px;
    }

    .webViewerContainer {
      width: 100%;
      flex: 1;
    }

    .errorState {
      width: 0px;
      height: 100%;
      position: fixed;
      right: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        .header {
          padding-right: calc(100vh - 625px) !important;
          font-weight: 800;
          font-size: 50px;
          line-height: 137px;
          text-transform: uppercase;
          color: #1b8c96;
          padding: 0;
        }

        .text {
          font-weight: 500;
          font-size: 24px;
          line-height: 37px;
          color: #3e3f42;
        }
      }
    }
  }

  .sidePanelSectionContainer {
    width: 400px;
    margin-top: 15px;
    position: fixed;
    right: 0;
  }

  .sidePanelContainer {
    width: 400px;
    margin-top: 15px;
    position: fixed;
    right: 0;
    margin: 50px 0px 0px 20px;
  }

  .videoSidePanelContainer {
    width: 400px;
    margin-top: 15px;
    position: fixed;
    right: 0;
    margin: 0px 0px 0px 20px;
  }
}

.approvalCardContainerSidePanel {
  margin: 0 15px;
}
