@charset "UTF-8";
@font-face {
  font-family: "uikon";
  src: url("./fonts/uikon.eot?65atsz");
  src: url("./fonts/uikon.eot?65atsz#iefix") format("embedded-opentype"), url("./fonts/uikon.ttf?65atsz") format("truetype"), url("./fonts/uikon.woff?65atsz") format("woff"), url("./fonts/uikon.svg?65atsz#uikon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="uikon"],
[class*="uikon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "uikon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.uikon-dropdown_arrow:before {
  content: ""; }

.uikon-arrow_left:before {
  content: ""; }

.uikon-arrow_right:before {
  content: ""; }

.uikon-plus_rounded:before {
  content: ""; }

.uikon-pin_rounded_circle:before {
  content: ""; }

.uikon-message_2:before {
  content: ""; }

.uikon-calendar_2:before {
  content: ""; }

.uikon-building_plan:before {
  content: "";
  color: #9ea0a5; }

.uikon-tap_click_force_touch:before {
  content: ""; }

.uikon-ruler:before {
  content: ""; }

.uikon-video_camera:before {
  content: ""; }

.uikon-phone_two:before {
  content: ""; }

.uikon-smile:before {
  content: ""; }

.uikon-attachment:before {
  content: ""; }

.uikon-picture:before {
  content: ""; }

.uikon-view_simple:before {
  content: ""; }

.uikon-lightbulb:before {
  content: ""; }

.uikon-wallet:before {
  content: ""; }

.uikon-view_grid:before {
  content: ""; }

.uikon-view_list:before {
  content: ""; }

.uikon-profile_card:before {
  content: ""; }

.uikon-front_store:before {
  content: ""; }

.uikon-rocket:before {
  content: ""; }

.uikon-buildings:before {
  content: ""; }

.uikon-building:before {
  content: ""; }

.uikon-shopping_bag:before {
  content: ""; }

.uikon-arrow-dropdown:before {
  content: ""; }

.uikon-check:before {
  content: ""; }

.uikon-more:before {
  content: ""; }

.uikon-trending_down:before {
  content: ""; }

.uikon-trending_up:before {
  content: ""; }

.uikon-star:before {
  content: ""; }

.uikon-star-filled:before {
  content: ""; }

.uikon-add:before {
  content: ""; }

.uikon-help:before {
  content: ""; }

.uikon-message:before {
  content: ""; }

.uikon-send_round:before {
  content: ""; }

.uikon-inbox_paper_round:before {
  content: ""; }

.uikon-search_left:before {
  content: ""; }

.uikon-settings:before {
  content: ""; }

.uikon-love:before {
  content: ""; }

.uikon-love-fill:before {
  content: ""; }

.uikon-edit:before {
  content: ""; }

.uikon-multitasking:before {
  content: ""; }

.uikon-gallery_grid_view:before {
  content: ""; }

.uikon-home:before {
  content: ""; }

.uikon-profile_round:before {
  content: ""; }

.uikon-profile_plus_round:before {
  content: ""; }

.uikon-clock:before {
  content: ""; }

.uikon-container:before {
  content: ""; }

.uikon-globe_network:before {
  content: ""; }

.uikon-calendar:before {
  content: ""; }

.uikon-stats:before {
  content: ""; }

.uikon-money_round:before {
  content: ""; }

.uikon-cloud_up:before {
  content: ""; }

.uikon-image_picture:before {
  content: ""; }

.uikon-camera:before {
  content: ""; }

.uikon-desktop:before {
  content: ""; }

.uikon-phone:before {
  content: ""; }
