.documentAttachmentPanel {
  position: absolute;
  background-color: #393d50;
  color: #fff;
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 320px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.sidePanelOptions {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  height: calc(100% - 190px) !important;
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  overflow-y: auto;
  position: relative;
  display: flex;
  justify-content: center;
}

.viewerSidePanelOptions {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  height: calc(100% - 225px) !important;
  overflow-y: auto;
  position: relative;
  display: flex;
  justify-content: center;
}

.emptySidePanelOptions {
  display: none;
}

.sidePanelHeader {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 20px;
}

.attachmentLink {
  color: #eaedf3 !important;
  font-weight: normal;
  width: 80%;
  cursor: pointer;
}

.attachmentContentUploading {
  background-color: #393d50;
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  padding: 24px 0px 24px 0px;
  vertical-align: middle;
  word-break: break-word;
  display: flex;
  justify-content: space-between;
  margin-left: -5%;
  margin-right: -5%;
  padding-left: 5px;
  padding-right: 5px;
}

.attachmentLinkContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.attachmentDocumentTitle {
  float: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.attachmentDocumentFileExtension {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #eaedf3;
  text-transform: uppercase;
  padding-top: 5px;
}

.attachmentDayUploaded {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #eaedf3;
  padding-top: 5px;
  position: relative;
}

.attachmentDocumentInfoContainer {
  width: 100%;
}

.attachmentDocumentDetails {
  display: flex;
  flex-direction: column;
}

.sidePanelHeadingTitle {
  font-size: 24px;
  font-weight: 500;
}

.closeAttachmentPanel {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 22px;
  float: right;
  height: 16px;
  width: 16px;
}

.emptyStateAttachmentContentUploaderBox {
  background-color: #2c3041;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 30px;
  vertical-align: middle;
  height: 100%;
}

.hideEmptyStateAttachmentContentUploaderBox {
  display: none;
}

.firstUploadProgress {
  padding-right: 8%;
  padding-left: 8%;
}

.attachmentUploaderContainer {
  position: sticky;
  bottom: 0;
  opacity: 1;
  background: #393d50;
  width: 100%;
  z-index: 99;
}

.emptyAttachmentUploaderContainer {
  height: calc(100vh - 182px);
}

.attachmentContentUploaderBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 30px;
  height: 100%;
}

.uploaderIconContainer {
  display: none;
}

.emptyStateUploaderIconContainer {
  color: #9ea0a5;
  display: inline-block;
  line-height: 22px;
  margin: 10px;
}

.emptyStateUploaderIcon {
  display: flex;
}

.uploaderIconCheck {
  color: #15cd93;
  display: inline-block;
  line-height: 22px;
  margin: 10px;
}

.emptyStateUploaderPlaceholder {
  color: white;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 30px;
}

.uploaderButton {
  float: right;
}

.uploadProgress {
  height: 4px;
  width: 100%;
  border-radius: 2px;
}

.uploadingFilename {
  color: #eaedf3;
  margin-bottom: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.warning {
  height: 20px;
  width: 20px;
  margin-right: 9px;
}

.errorBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 258px;
  height: 38px;
  border-radius: 4px;
  background-color: #464a5c;
}

.errorMessage {
  font-size: 12px;
  color: #bac0cb;
}

.sidePanelSubheading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #dccaa9;
}

.documentActionsDropDownContainer {
  height: 100%;
  color: #9ea0a5;
  cursor: pointer;
  font-size: 10px;
  display: flex;
}

.emptyStateUploaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.emptyStateDropzone {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.showPopUpUploader {
  background-color: #2c3041;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 6%;
  vertical-align: middle;
  height: 90%;
  position: absolute;
  top: 0;
  left: 1%;
  width: 78%;
}

.dashBorder {
  display: flex;
  justify-content: center;
  border: 1px dashed #5b5f72;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 98%;
  margin: 5px 0px 5px 0px;
}

.hidePopUpUploader {
  border-radius: 4px;
  vertical-align: middle;
  height: 90%;
  position: absolute;
  top: 0;
  width: 84%;
  outline: none;
}

.uploadCaption {
  margin-top: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 22px;
  color: white;
  text-align: center;
  width: 100%;
  display: inline-block;
}

.downloadText {
  color: #3e3f42;
}

.downloadLink {
  display: flex;
  align-items: center;
  color: #eaedf3 !important;
  font-weight: normal;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.attachmentDetails {
  display: flex;
  justify-content: space-between;
}

.descriptionBox {
  display: none;
}

.dayUploaded:hover ~ .descriptionBox {
  display: flex;
  position: absolute;
  top: 96%;
  right: 306%;
  z-index: 1;
}

.dayUploaded {
  position: relative;
}

.descriptionBoxImage {
  transform: rotate(90deg);
  top: -9px;
  left: 153px;
  position: absolute;
}
.descriptionText {
  width: 280px;
  min-height: 40px;
  background: #2c3041;
  border-radius: 4px;
  position: absolute;
  top: 5px;
  transform: translateX(-5%);
  color: #eaedf3;
  word-wrap: break-word;
  padding: 18px;
  box-sizing: border-box;
  font-size: 11px;
  left: -62px;
}

@media screen and (min-width: 1440px) {
  .documentAttachmentPanel {
    position: absolute;
    background-color: #393d50;
    color: #fff;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 400px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
