.versionHeader {
  position: relative;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  cursor: pointer;

  &.active {
    border-left: 4px solid #1b8c96;
  }
}

.versionHeaderActive {
  position: absolute;
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
}

.versionNamePlateContainer {
  color: #fff;
  margin-right: 10px;

  height: 28px;
  &.active {
    &:after {
      content: "\A";
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #15cd93;
      margin-left: 8px;
    }
  }
}

.versionNamePlate {
  color: #fff;
  font-size: calc(28px / 2); /* 50% of parent */
  font-weight: 500;
  line-height: 1;
  position: relative;
}

.versionName {
  height: 22px;
  color: #eaedf3;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
}

.versionDate {
  height: 18px;
  color: #9ea0a5;
  display: inline;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  position: relative;
}

.versionFromOthers {
  background-color: transparent;
  border-radius: 10px 0 10px 10px;
  border: 1px solid rgba(255, 255, 255, 0.38);
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  margin: 12px 0 30px 0;
  padding: 20px;
}

.versionFromMe {
  background-color: #0a7cb8;
  border-radius: 0 10px 10px 10px;
  border: 1px solid #0a7cb8;
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  margin: 12px 0 30px 0;
  padding: 20px;
}

.descriptionBoxImage {
  transform: rotate(-90deg);
  left: 29%;
  top: 57%;
  position: absolute;
}

.descriptionText {
  min-height: 26px;
  background: #2c3041;
  border-radius: 4px;
  transform: translateX(-5%);
  color: #eaedf3;
  word-wrap: break-word;
  box-sizing: border-box;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
}

.descriptionBox {
  display: none;
}

.uploadDate {
  position: relative;
  z-index: 1;
}

.uploadDate:hover ~ .descriptionBox {
  display: flex;
  position: absolute;
  bottom: 125%;
  z-index: 1;
  left: 61%;
}
