.tableContainer {
  width: 100%;
  border-collapse: collapse;

  .colgroup {
    width: 100%;
  }

  .col {
    width: 15%;
  }

  .colLarge {
    width: 30%;
  }

  .header {
    tr {
      color: #a8aaaf;
      font-weight: 600;
      font-size: 11px;
      line-height: 22px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      border-bottom: 1px solid #d6dce8;
      width: 100%;

      th {
        min-width: 150px;
        padding-bottom: 10px;
        padding-left: 20px;
        width: 10%;
      }

      .columnName {
        cursor: pointer;
        .container {
          display: flex;
          align-items: center;

          .text {
            &Bold {
              color: #3e3f42;
            }
            margin-top: 10px;
            white-space: nowrap;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;

            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }

        &:hover {
          .text {
            color: #3e3f42;
            margin-top: 10px;
          }
        }
      }
      .unsortableColumnName {
        cursor: default;
        .container {
          display: flex;
          align-items: center;

          .text {
            margin-top: 10px;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;

            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  .body {
    color: #3e3f42;
    tr {
      border-bottom: 1px solid #d6dce8;
      height: 60px;

      &:last-of-type {
        border-bottom: none;
      }

      td {
        width: 10%;
        min-width: 100px;
        padding: 10px 4px 10px 20px;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 0;
      }

      .link {
        .container {
          display: flex;
          justify-content: center;
          align-items: center;

          .text {
            color: #1b8c96;
            margin-right: 9px;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            margin-top: 10px;
          }
        }
      }

      // &:hover {
      //   background: #e2eef1;
      //   cursor: pointer;
      // }
    }
  }
}

.hoverContainer {
  width: 100%;
}

.tableViewContainer {
  display: flex;
  border: 1px solid #d6dce8;
  border-radius: 6px;
  background-color: #ffffff;
  margin: 20px 35px 20px 35px;
}

.titleTableContainer {
  border-collapse: collapse;

  .col {
    width: 10%;
  }

  .colLarge {
    width: 20%;
  }

  .header {
    tr {
      color: #a8aaaf;
      font-weight: 600;
      font-size: 11px;
      line-height: 22px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      border-bottom: 1px solid #d6dce8;
      width: 100%;

      th {
        min-width: 100px;
        padding-bottom: 10px;
        padding-left: 20px;
      }

      .columnName {
        cursor: pointer;
        .container {
          display: flex;
          align-items: center;

          .text {
            &Bold {
              color: #3e3f42;
            }
            margin-top: 10px;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;
            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }

        &:hover {
          .text {
            color: #3e3f42;
            margin-top: 10px;
          }
        }
      }
      .unsortableColumnName {
        cursor: default;
        .container {
          display: flex;
          align-items: center;

          .text {
            margin-top: 10px;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;

            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  .body {
    color: #3e3f42;
    tr {
      border-bottom: 1px solid #d6dce8;
      height: 60px;

      &:last-of-type {
        border-bottom: none;
      }

      td {
        width: 10%;
        min-width: 100px;
        padding: 10px 4px 10px 20px;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 0;
      }

      .link {
        .container {
          display: flex;
          justify-content: center;
          align-items: center;

          .text {
            color: #1b8c96;
            margin-right: 9px;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            margin-top: 10px;
          }
        }
      }

      // &:hover {
      //   background: #e2eef1;
      //   cursor: pointer;
      // }
    }
  }
}

.tableRow {
  position: relative;
}

.linksContainer {
  height: 100%;
  align-items: center;
  display: flex;
}

.hoverStateContainer {
  visibility: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #d6dce8;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .tableRow {
      position: relative;
      transform: scale(1, 1);
    }

    .hoverStateContainer {
      visibility: hidden;
      position: absolute;
      left: 0;
      width: 100%;
      height: 3.85%;
      display: flex;
      justify-content: space-around;
      border-top: 1px solid #d6dce8;
      border-bottom: 1px solid #d6dce8;
    }
  }
}

.tableSubContainer {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: #d6dce8 #f3f5f9;
  scrollbar-width: thin;
  width: 100%;
}

.emptyStateContainer {
  position: relative;
  height: 100px;
  margin-bottom: 75px;
}

.emptyMessageContainerAdmin {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: #ffffff;
  margin: 20px 35px 20px 35px;
  position: absolute;
  left: 175%;
  width: 200px;
  top: 20%;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #9ea0a5;
}

.emptyMessageContainer {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: #ffffff;
  margin: 20px 35px 20px 35px;
  position: absolute;
  left: 150%;
  width: 200px;
  top: 20%;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #9ea0a5;
}

.shareLinkGrey {
  height: 30px;
  margin-bottom: 20px;
}

.tableOneContainer {
  width: 25%;
  padding-left: 20px;
}

.exportButton {
  border: none;
  color: #1b8c96;
  padding: 10px 20px;
  background: none;
  margin-left: 70px;
  box-shadow: none;
}

.exportButton:hover {
  border: none;
  color: #1b8c96;
  padding: 10px 20px;
  background: none;
  margin-left: 70px;
  box-shadow: none;
}

.externalLink {
  margin-left: 7px;
}

.tableSubContainer::-webkit-scrollbar-track {
  background-color: #f3f5f9;
}

.tableSubContainer::-webkit-scrollbar {
  height: 5px;
}

.tableSubContainer::-webkit-scrollbar-thumb {
  background-color: #d6dce8;
  border-radius: 8px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .hoverStateContainer {
    height: 59px !important;
  }
}
