.tooltipContainer {
  position: relative;

  .icon {
    display: flex;
  }

  .box {
    display: none;

    &Right {
      .dimple {
        position: absolute;
        top: -25%;
        left: 23px;
        z-index: 1;
      }
      .content {
        position: absolute;
        top: 50%;
        background: #2c3041;
        left: 32px;
        transform: translateY(-50%);
        padding: 20px 30px;
        border-radius: 8px;
        z-index: 1;
      }
    }
    &Visible {
      display: block;
    }
  }
}
