@charset "UTF-8";
.sidePanelOptions {
  height: 32px;
  width: 270px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding: 20px 12px 10px 12px;
  border-radius: 3px; }
  .sidePanelOptions:hover {
    background: #e6f2f3; }
    .sidePanelOptions:hover .approvalsLink {
      color: #057d88; }
    .sidePanelOptions:hover .approvalsRemaining {
      background: #dadee6;
      color: #3e3f42; }
  .sidePanelOptionsApprovals {
    border-bottom: none;
    padding-bottom: 0; }
  .sidePanelOptionsMyApproval {
    padding: 13px 15px 20px; }

.mobileSidePanelOptions {
  height: 32px;
  width: 86%;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding: 20px 12px 10px 12px;
  border-radius: 3px;
  margin-top: 15px; }
  .mobileSidePanelOptions:hover {
    background: #e6f2f3; }
    .mobileSidePanelOptions:hover .approvalsLink {
      color: #057d88; }
    .mobileSidePanelOptions:hover .approvalsRemaining {
      background: #dadee6;
      color: #3e3f42; }
  .mobileSidePanelOptionsApprovals {
    border-bottom: none;
    padding-bottom: 0; }
  .mobileSidePanelOptionsMyApproval {
    padding: 13px 15px 20px; }

.approvalsLink {
  color: #3e3f42;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer; }

.approvals {
  display: flex;
  float: left;
  font-size: 14px; }

.approvalsRemaining {
  float: right;
  width: 40px;
  text-align: center;
  background: #eaedf3;
  border-radius: 9.5px;
  line-height: 18px; }

.approvalsRemainingIcon i {
  float: right;
  color: #b6b6b6;
  font-size: 16px;
  margin-top: 2px; }

.icon {
  margin: 2px 15px 0px 0px; }

.sectionContainer {
  display: flex;
  justify-content: center; }

@media screen and (min-width: 1440px) {
  .sidePanelOptions {
    height: 32px;
    width: 332px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    padding: 20px 12px 10px 12px;
    border-radius: 3px; }
    .sidePanelOptions:hover {
      background: #e6f2f3; }
      .sidePanelOptions:hover .approvalsLink {
        color: #057d88; }
      .sidePanelOptions:hover .approvalsRemaining {
        background: #dadee6;
        color: #3e3f42; }
    .sidePanelOptionsApprovals {
      border-bottom: none;
      padding-bottom: 0; }
    .sidePanelOptionsMyApproval {
      padding: 13px 15px 20px; } }

.editTemplate {
  color: #1b8c96;
  display: block;
  float: right;
  font-size: 14px; }

.betaBadge {
  background-color: #15cd93;
  color: #fff;
  display: block;
  font-size: 10px;
  padding: 1px 10px;
  text-transform: uppercase;
  float: right;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  text-align: center; }

.translationsSidePanelLabel:after {
  content: "✨";
  margin-left: 10px;
  float: right; }
