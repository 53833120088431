.container {
  padding: 0 15px 30px;

  .body {
    margin-top: 32px;

    .headerContainer {
      display: flex;
      justify-content: space-between;

      .downloadLinkContainer {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 15px;
      }

      .downloadLink {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: rgba(27, 140, 150, 1);
        margin-right: 10px;
      }
    }

    .title {
      color: #3e3f42;
      font-weight: 500;
      font-size: 24px;
      line-height: 44px;
      padding: 0 22px;
    }

    .description {
      color: #6b6c6f;
      font-size: 14px;
      line-height: 22px;
      padding: 0 22px;
    }

    .table {
      margin-top: 23px;
      width: 100%;
      padding: 14px 19px 0;
      box-sizing: border-box;
      background: white;
      border-radius: 6px;
      border: 1px solid #d6dce8;
    }

    .emptyState {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 102px;
      margin-top: 23px;
      width: 100%;
      background: white;
      border-radius: 6px;
      border: 1px solid #d6dce8;

      .infoIcon {
        display: flex;
        margin-right: 9px;
      }

      .text {
        color: #3e3f42;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
      }
    }

    .unauthorizedState {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        width: 35%;
        .header {
          font-weight: 800;
          font-size: 87.2083px;
          line-height: 137px;
          text-transform: uppercase;
          color: #1b8c96;
        }

        .text1 {
          font-weight: 500;
          font-size: 24px;
          line-height: 37px;
          color: #3e3f42;
        }

        .text2 {
          color: #6b6c6f;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}

.exportButton {
  border: none;
  color: #1b8c96;
  padding: 10px 20px;
  background: none;
  margin-left: 20px;
  box-shadow: none;
}

.exportButton:hover {
  border: none;
  background: #eaedf3;
  box-shadow: none;
  padding: 10px 20px;
  margin-left: 20px;
}

.externalLink {
  margin-left: 7px;
}
