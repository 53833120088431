.container {
  width: 49px;
  height: 16px;
  background-color: #2c3041;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .pill {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .indicator {
      width: 8px;
      height: 8px;
      border-radius: 50%;

      &.red {
        background-color: #e67670;
      }

      &.yellow {
        background-color: #ffd911;
      }

      &.green {
        background-color: #15cd93;
      }
    }

    .percentage {
      font-size: 10px;
      font-weight: 500;
      margin-left: 4px;
    }

    & + .hoverContainer {
      display: none;
    }

    &:hover {
      & + .hoverContainer {
        display: block;
      }
    }
  }

  .hoverContainer {
    .arrow {
      position: absolute;
      width: 0;
      height: 0;
      left: -9px;
      transform: translateY(-50%);
      top: 50%;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid #2c3041;
    }

    .body {
      position: absolute;
      width: 134px;
      height: 50px;
      border-radius: 4px;
      left: -143px;
      transform: translateY(-50%);
      top: 50%;
      background-color: #2c3041;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #eaedf3;
      font-weight: 500;
      font-size: 13px;
    }
  }
}
