.authentication-login-form {
  .body {
    color: #3e3f42;
    font-size: 14px;
  }
  .reset-button {
    margin-top: 30px;
    width: 100%;
  }
}
