.leftSideMenuContainer {
  padding: 0px !important;
  background-color: #ffffff;
  border-top: 1px solid #eaedf3;
}

.leftSideMenu {
  margin-top: 25px;
}

.leftSideMenuItem {
  border-left: solid 3px #fff;
  color: #3e3f42;
  font-size: 14px;
  /*font-family: Montserrat;*/
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  vertical-align: middle;
}

.leftSideMenuItem.selected {
  border-color: #1b8c96;
  background-color: #f6fdfd;
}

.leftSideMenuItem.header {
  color: #9ea0a5;
  text-transform: uppercase;
}

.leftSideMenuItem a {
  color: #3e3f42 !important;
  text-decoration: none;
}
