.searchInputContainer {
  padding-bottom: 16px;
  display: flex;
}

.searchInputContainerActive {
  padding-bottom: 16px;
  position: relative;
  display: flex;
}

.searchInputFieldEmpty {
  margin: 0 auto;
  margin-top: 22px;
  background-color: #2c3041;
  border: none;
  color: #8a8e96;
  &::placeholder {
    color: #8a8e96;
    background-image: url("../../../../images/icons/svg/search.svg");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
  }
}

.searchInputFieldActive {
  margin: 0 auto;
  margin-top: 22px;
  background-color: #2c3041;
  border: none;
  color: white;
}

.showCloseComment {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 26px;
  float: right;
  height: 16px;
  width: 16px;
  position: absolute;
  right: 15px;
  top: 20px;
}

.hideCloseComment {
  display: none;
}

.filterIcon {
  max-height: 23px;
  padding: 8px;
  cursor: pointer;
}

.filterIconActive {
  max-height: 23px;
  padding: 8px;
  background-color: #2c3041;
  border-radius: 4px;
  cursor: pointer;
}

.filterIconContainer {
  margin-left: 9px;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
}

.searchPanelContainer {
  display: flex;
  flex-direction: column;
}

.filterPillsContainer {
  display: flex;
  flex-direction: row;
}

.InputContainer {
  flex-grow: 1;
}

// Mozilla firefox specific styling for the input placeholder

@-moz-document url-prefix() {
  .searchInputField {
    &::placeholder {
      background-position-y: 50% !important;
      background-position-x: 96.5% !important;
    }
  }
}
