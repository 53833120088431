.metadataField {
  margin-top: 10px;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
}

.tagField {
  margin-right: 10px;
}

.tagFieldLabel {
  margin-top: 10px;
  color: #9b9b9b;
  display: block;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-transform: uppercase;
}

.textMetadataField {
  margin: 10px 0 10px 0;
}

.metadataFieldLabel {
  color: #9b9b9b;
  display: block;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-transform: uppercase;
}

.metadataFieldValue {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #393d50;
}

.description {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #393d50;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.approverListItemNameplate {
  min-width: 25%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;

  .deactive & {
    max-width: 50%;
    min-width: 50%;
  }
}

.approverSubContainerOne {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  width: 50%;
}

.approverSubContainerTwo {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.propertySubContainerOne {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 50px;
}

.propertySubContainerTwo {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.approverListItemNameplateName {
  display: block;
  color: #3e3f42;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  overflow: hidden;
  text-overflow: ellipsis;
  width: 325px;

  .deactive & {
    color: #bac0cb;
  }
}

.approverListItemNameplateTitle {
  color: #6b6c6f;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  .deactive & {
    color: #bac0cb;
  }
}

.approvalBadge {
  line-height: 1.5;
  border-radius: 4px;
  color: #fff;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  height: 21px;
  line-height: 21px;
  text-align: center;
  width: auto;
  border: 0px;
}

.observerResetBadge {
  background-color: #9ea0a5;
}

.approvedResetBadge {
  background-color: #15cd93;
}

.waitingResetBadge {
  background-color: #ff9c79;
}

.rejectedResetBadge {
  background-color: #d94c53;
}

.exportPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionContainer {
  border: 1px solid #eaedf3;
  border-radius: 4px;
  width: 1000px;
  margin-bottom: 20px;
  padding: 30px;
  box-sizing: border-box;
}

.sectionTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.approvalHeader {
  float: left;
  width: 50%;
}

.documentTitle {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.certificateHeader {
  display: flex;
  flex-direction: row;
  min-width: 1000px;
  justify-content: space-between;
  margin: 60px 0px 50px 0px;
  font-weight: 500;
  font-size: 26px;
  line-height: 44px;
  color: #3e3f42;
}

.approverContainer {
  clear: both;
  display: flex;
}

.certificateFooter {
  border: 1px solid #eaedf3;
  border-radius: 4px;
  width: 1000px;
  margin-bottom: 60px;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.dateDescriptor {
  font-weight: 600;
}

.footerTextContainer {
  display: flex;
  align-items: center;
}
.imageContainer {
  page-break-inside: avoid;
}

@media print {
  .buttonContainer {
    visibility: hidden;
  }

  .certificateFooter {
    page-break-inside: avoid;
  }

  body {
    width: 11in;
  }
}

.approvalStatus {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-left: 25px;
}
