.notificationCustomSuccess {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 33px 15px 20px;
  font-size: 14px;
  color: #fff;
  font-family: "Montserrat", sans-serif !important;
}

.type--success {
  background-color: #15cd93;
}
.type--danger {
  background-color: #dc3545;
}

.type--information {
  background-color: #0a7cb8;
  img {
    margin-right: 10px;
  }
}

.type--warning {
  background-color: #e67670;
  img {
    margin-right: 10px;
  }
}

.notificationCustomIcon {
  flex-basis: 15%;
  position: relative;
  display: inline-block;
  border: 2px solid #fff;
  border-radius: 50%;
  text-align: center;
  width: 32px;
  height: 37px;
  margin-right: 10px;
  i {
    top: 50%;
    left: 50%;

    color: #fff;
    line-height: 10px;
    font-size: 15px;
    position: absolute;

    transform: translate(-50%, -50%);
  }
}

.notificationCustomContent {
  flex-basis: 85%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: initial;
  max-width: 200px !important;
  display: block;
  -webkit-line-clamp: 2;
}
