.indicatorContainer {
  right: 0px;
  float: left;
}

.documentPageCommentIndicators {
  cursor: pointer;
  margin-right: 0px;
  margin-bottom: 5px;
}

.referenceIndicatorContainer {
  background-color: #7a6a8d;
  border-radius: 5px;
  color: #fff;
  padding: 8px 8px 6px 8px;
}
