.container {
  .searchIcon {
    position: absolute;
    top: 11px;
    left: 13px;
  }

  .crossIcon {
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;
  }

  input {
    width: 100%;
    border: 1px solid #d6dce8;
    border-radius: 4px;
    height: 38px;
    padding: 8px 40px;
    box-sizing: border-box;
    color: -internal-light-dark(white, white);

    &:focus {
      border-color: #1b8c96;
      outline: none;
    }

    &::placeholder {
      color: #bfbfbf;
    }
  }
}
