.editSegmentContainer {
  background-color: #fbfbfb;
  margin-top: 30px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 5px;
}

.editContainer {
  padding: 0 20px;
}

.editActionButtons {
  padding: 20px;
}

.formLabel {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #9ea0a5;
}

.checkbox {
  margin: 0.2rem 0;
}

.SegmentEditFormSubtitle {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #9ea0a5;
  margin-bottom: 22px;
}
