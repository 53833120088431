.dashboardMentionSuggestionsEntryAvatar {
  font-size: 12px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  line-height: 28px;
  background-color: #9ea0a5;
  color: white;
  text-align: center;
  border-radius: 50%;
}

.dashboardMentionSuggestionsEntryFocused {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 7px 9px;
  background-color: #f2f4f7;
}
.dashboardMentionSuggestionsEntry {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 7px 9px;
}

.dashboardMentionSuggestions {
  position: absolute;
  width: calc(100% - 30px);
  background: white;
  cursor: pointer;
  left: 15px !important;
  z-index: 2;
}

.dashboardMention {
  background-color: #f2f4f7;
}

.dashboardMentionSuggestionsEntryText {
  font-size: 12px;
  font-weight: 500;
}

.dashboardMentionSuggestionsTitle {
  font-size: 11px;
  font-weight: 500;
  color: #383d50;
}

.dashboardMentionSuggestionsTextBox {
  margin-left: 15px;
}
