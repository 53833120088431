.container {
  overflow: scroll;
  min-height: 100vh;
  min-width: 1128px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.top {
  display: flex;
  margin-left: 35px;
  margin-right: 35px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.recentlyViewedContainer {
  width: calc(100vw - 58px);
  background: #ffffff;
  border: 1px solid #d6dce8;
  box-sizing: border-box;
  border-radius: 9px;
  margin: 30px 10px 30px 10px;
  overflow-x: auto;
}

.recentlyViewedContainerEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 30px;
  background: #ffffff;
  box-sizing: border-box;
  margin: 30px 10px 30px 10px;
  min-width: 1280px;
  overflow-x: auto;
}

.commentsEmptyStateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}

.recentlyApprovedContainerEmpty {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 30px;
  background: #ffffff;
  box-sizing: border-box;
  margin: 30px 30px 30px 70px;
  min-width: 500px;
  overflow-x: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #026aa2;
}

.toggleContainer {
  height: 80px;
  width: 100%;
}

.toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 20px 0px 0px 25px;
  width: 323px;
  height: 44px;
  background: #f9fafb;
  border: 1px solid #f2f4f7;
  border-radius: 8px;
}

.toggleButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: 145px;
  height: 36px;
  background: #ffffff;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
}

.toggleButtonInactive {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: 145px;
  height: 36px;
  background: #f9fafb;
  color: #667085;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
}

.thumbnailContainer {
  padding: 30px 0px 30px 10px;
  display: flex;
  width: 100%;
}
.chartContainer {
  background: #ffffff;
  border: 1px solid #d6dce8;
  box-sizing: border-box;
  border-radius: 9px;
  padding: 30px;
  width: 55%;
  min-width: 550px;
}

.clicksTotal {
  // font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: #6b6c6f;
  text-align: right;
}

.downloadsTotal {
  // font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: #6b6c6f;
  text-align: right;
}

.clicksContainer {
  margin-right: 50px;
}

.totalsContainer {
  display: flex;
}

.summaryContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 2%;
  padding-bottom: 65px;
}

.clicksTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  /* identical to box height */
  text-transform: uppercase;

  color: #393d50;
}

.downloadsTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  /* identical to box height */
  text-transform: uppercase;

  color: #393d50;
}

.titleContainer {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;

  color: #393d50;
}

.pageHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 35px;
  /* identical to box height, or 140% */

  color: #3e3f42;
}

.activityLogSubheadingOne {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;
  text-transform: uppercase;
  color: #9ea0a5;
  mix-blend-mode: normal;
  opacity: 0.9;
  margin-left: 50px;
}

.activityLogSubheadingTwo {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;
  text-transform: uppercase;
  color: #9ea0a5;
  mix-blend-mode: normal;
  opacity: 0.9;
  margin-right: 150px;
}

.activityLogSubheadingContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.activityLogHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #3e3f42;
  margin-left: 30px;
  margin-bottom: 5px;
}

.eventDetails {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eaedf3;
  padding-top: 20px;
  padding-bottom: 20px;
}

.activityLogHeaderContainer {
  display: flex;
  justify-content: space-between;
}

.labelContainer {
  display: flex;
  margin-left: 30px;
  margin-bottom: 15px;
}

.linkLabel {
  font-size: 14px;
  font-family: Montserrat;
  color: #6b6c6f;
  font-weight: 600;
  margin-right: 5px;
}

.linkDocumentName {
  font-size: 12px;
  font-family: Montserrat;
  color: #9ea0a5;
  font-weight: 500;
  margin-top: 1px;
}

.eventType {
  padding-left: 50px;
  width: 50%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #3e3f42;
}

.eventDate {
  padding-left: 48px;
  width: 50%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #3e3f42;
}

.eventTypeIcon {
  margin-right: 15px;
}

.searchInputFieldEmpty {
  margin: 0 auto;
  margin-top: 22px;
  background-color: #ffffff;
  border: 1px solid #d6dce8;
  color: #8a8e96;
  &::placeholder {
    color: #8a8e96;
    background-image: url("../../images/icons/svg/search.svg");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
  }
}

.searchInputFieldEmpty:focus {
  border: 1px solid #d6dce8;
}

.searchInputFieldActive {
  margin: 0 auto;
  margin-top: 22px;
  background-color: #ffffff;
  border: 1px solid #d6dce8;
  color: rgba(62, 63, 66, 1);
}

.searchInputFieldActive:focus {
  border: 1px solid #d6dce8;
}

.shareLinkSearchContainer {
  display: flex;
  position: relative;
}

.closeIconShow {
  position: absolute;
  right: 8px;
  top: 35px;
  cursor: pointer;
}

.closeIconHide {
  display: none;
}

.bottomHeaderContainer {
  display: flex;
  margin-left: 35px;
  margin-right: 35px;
  margin-top: 50px;
  justify-content: space-between;
}

.bottomHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #3e3f42;
  display: flex;
  align-items: center;
}

.topContainer {
  display: flex;
  width: 98vw;
}

.inReview {
  position: relative;
  width: 70%;
  min-width: 915px;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: 30%;
  .commentsPanelContainer {
    max-height: 698px;
    background: #ffffff;
    border: 1px solid #d6dce8;
    box-sizing: border-box;
    border-radius: 9px;
    margin: 20px 10px 20px 30px;
    overflow-x: hidden;
    flex-grow: 1;
    padding-bottom: 20px;
  }

  .commentsPanelEmptyContainer {
    max-height: 460px;
    background: #ffffff;
    border: 1px solid #d6dce8;
    box-sizing: border-box;
    border-radius: 9px;
    margin: 20px 10px 20px 30px;
    overflow-x: auto;
    flex-grow: 1;
    padding-bottom: 20px;
  }
}

.searchContainer {
  display: flex;
  margin: 20px 0px 0px 10px;
  min-width: 750px;
  display: flex;
  border: 1px solid #d6dce8;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #ffffff;
  padding-left: 20px;
}

.ColumnTitleContainer {
  display: flex;
  margin: 0px 0px 0px 10px;
  min-width: 750px;
  display: flex;
  border: 1px solid #d6dce8;
  border-top: none;
  background-color: #ffffff;
}

.titleColumn {
  width: 33.5%;
  min-width: 100px;
  padding-left: 7.75%;
  padding-bottom: 10px;
  padding-top: 10px;
}

.dueDate {
  width: 19%;
  min-width: 100px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.progress {
  min-width: 150px;
  width: 20%;
  padding-bottom: 10px;
  padding-top: 10px;
}

.status {
  min-width: 150px;
  width: 20%;
  padding-bottom: 10px;
  padding-top: 10px;
}

.inReviewContainer {
  max-height: 646px;
  overflow-y: auto;
  margin: 0px 0px 20px 10px;
  min-width: 750px;
  border-bottom: 1px solid #d6dce8;
  border-radius: 9px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.subHeadingContainer {
  height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  top: 0px;
  background: white;
}

.searchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchInputContainer {
  margin-right: 20px;
  position: relative;
  .searchIcon {
    position: absolute;
    top: 11px;
    left: 13px;
  }

  .crossIcon {
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;
  }

  input {
    width: 100%;
    border: 1px solid #d6dce8;
    border-radius: 4px;
    height: 38px;
    padding: 8px 40px;
    box-sizing: border-box;
    color: -internal-light-dark(white, white);

    &:focus {
      border-color: #1b8c96;
      outline: none;
    }

    &::placeholder {
      color: #bfbfbf;
    }
  }
}

.subHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #393d50;
}

.subHeadingComments {
  margin-left: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #393d50;
}

.inReviewContainerEmpty {
  height: 402px;
  overflow-y: auto;
  margin: 0px 0px 20px 10px;
  min-width: 750px;
}

.uploadAction {
  background: #1b8c96 !important;
  color: #fff;
  margin-top: 15px;
  width: 100px;
  align-self: last baseline;
  margin-right: 30px;
}

.tableContainer {
  width: 100%;
  border-collapse: collapse;

  .colgroup {
    width: 100%;
  }

  .col {
    width: 20%;
  }

  .colLarge {
    width: 32%;
  }

  .header {
    tr {
      color: #a8aaaf;
      font-weight: 600;
      font-size: 11px;
      line-height: 22px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      border-bottom: 1px solid #d6dce8;
      width: 100%;

      th {
        min-width: 150px;
        padding-bottom: 10px;
        padding-left: 20px;
        width: 10%;
      }

      .columnName {
        cursor: pointer;
        .tableContainer {
          display: flex;
          align-items: center;

          .text {
            &Bold {
              color: #3e3f42;
            }
            margin-top: 10px;
            white-space: nowrap;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;

            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }

        &:hover {
          .text {
            color: #3e3f42;
            margin-top: 10px;
          }
        }
      }
      .unsortableColumnName {
        cursor: default;
        .tableContainer {
          display: flex;
          align-items: center;

          .text {
            margin-top: 10px;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;

            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  .body {
    color: #3e3f42;
    tr {
      border-bottom: 1px solid #d6dce8;
      height: 60px;

      &:last-of-type {
        border-bottom: none;
      }

      td {
        width: 10%;
        min-width: 100px;
        padding: 10px 4px 10px 20px;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // max-width: 0;
      }

      .link {
        .container {
          display: flex;
          justify-content: center;
          align-items: center;

          .text {
            color: #1b8c96;
            margin-right: 9px;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            margin-top: 10px;
          }
        }
      }

      // &:hover {
      //   background: #e2eef1;
      //   cursor: pointer;
      // }
    }
  }
}

.hoverContainer {
  width: 100%;
}

.tableViewContainer {
  min-height: 645px;
  display: flex;
  border: 1px solid #d6dce8;
  background-color: #ffffff;
}

.inReviewEmptyState {
  margin-left: 200px;
}

.titleTableContainer {
  border-collapse: collapse;

  .col {
    width: 10%;
  }

  .colLarge {
    width: 20%;
  }

  .header {
    tr {
      color: #a8aaaf;
      font-weight: 600;
      font-size: 11px;
      line-height: 22px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      border-bottom: 1px solid #d6dce8;
      width: 100%;

      th {
        min-width: 100px;
        padding-bottom: 10px;
        padding-left: 20px;
      }

      .columnName {
        cursor: pointer;
        .container {
          display: flex;
          align-items: center;

          .text {
            &Bold {
              color: #3e3f42;
            }
            margin-top: 10px;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;
            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }

        &:hover {
          .text {
            color: #3e3f42;
            margin-top: 10px;
          }
        }
      }
      .unsortableColumnName {
        cursor: default;
        .tableContainer {
          display: flex;
          align-items: center;

          .text {
            margin-top: 10px;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;

            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  .body {
    color: #3e3f42;
    tr {
      border-bottom: 1px solid #d6dce8;
      height: 60px;

      &:last-of-type {
        border-bottom: none;
      }

      td {
        width: 10%;
        min-width: 100px;
        padding: 10px 4px 10px 20px;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 0;
      }

      .link {
        .container {
          display: flex;
          justify-content: center;
          align-items: center;

          .text {
            color: #1b8c96;
            margin-right: 9px;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            margin-top: 10px;
          }
        }
      }

      // &:hover {
      //   background: #e2eef1;
      //   cursor: pointer;
      // }
    }
  }
}

.tableRow {
  position: relative;
}

.linksContainer {
  height: 100%;
  align-items: center;
  display: flex;
}

.hoverStateContainer {
  visibility: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #d6dce8;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .tableRow {
      position: relative;
      transform: scale(1, 1);
    }

    .hoverStateContainer {
      visibility: hidden;
      position: absolute;
      left: 0;
      width: 100%;
      height: 3.85%;
      display: flex;
      justify-content: space-around;
      border-top: 1px solid #d6dce8;
      border-bottom: 1px solid #d6dce8;
    }
  }
}

.tableSubContainer {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: #d6dce8 #f3f5f9;
  scrollbar-width: thin;
  width: 100%;
}

.emptyStateContainer {
  position: relative;
  height: 100px;
  margin-bottom: 75px;
}

.emptyMessageContainerAdmin {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: #ffffff;
  margin: 20px 35px 20px 35px;
  width: 200px;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #9ea0a5;
}

.emptyMessageContainer {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: #ffffff;
  margin: 20px 35px 20px 35px;
  width: 200px;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #9ea0a5;
}

.shareLinkGrey {
  height: 30px;
  margin-bottom: 20px;
}

.tableOneContainer {
  width: 7%;
  min-width: 70px;
}

.exportButton {
  border: none;
  color: #1b8c96;
  padding: 10px 20px;
  background: none;
  margin-left: 70px;
  box-shadow: none;
}

.exportButton:hover {
  border: none;
  color: #1b8c96;
  padding: 10px 20px;
  background: none;
  margin-left: 70px;
  box-shadow: none;
}

.externalLink {
  margin-left: 7px;
}

.tableSubContainer::-webkit-scrollbar-track {
  background-color: #f3f5f9;
}

.tableSubContainer::-webkit-scrollbar {
  height: 5px;
}

.tableSubContainer::-webkit-scrollbar-thumb {
  background-color: #d6dce8;
  border-radius: 8px;
}

.progressIndicator {
  min-width: 40px;
  text-align: center;
  background: #eaedf3;
  border-radius: 9.5px;
  padding-top: 0px;
  padding-left: 2px;
  padding-right: 2px;
}

.releaseToApproved {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  width: 132px;
  height: 22px;
  background: #fff1f3;
  border-radius: 16px;
  color: #c01048;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  justify-content: center;
}

.requestNewVersion {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  width: 132px;
  height: 22px;
  background: #fffaeb;
  border-radius: 16px;
  color: #b54708;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
}

.submitApproval {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  width: 132px;
  height: 22px;
  background: #fef3f2;
  border-radius: 16px;
  color: #b42318;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
}

.progressBar {
  width: 175px;
  margin-right: 20px;
}

.ownerColumn {
  color: white;
  &Bold {
    color: #3e3f42;
  }
  margin-top: 10px;
  white-space: nowrap;
}

.ownerInitials {
  width: 32px;
  height: 32px;
  background: #9e165f;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
}

.notOwnerInitials {
  width: 32px;
  height: 32px;
  background: #f2f4f7;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #475467;
  font-weight: 600;
}

.avatarContainer {
  display: flex;
  align-items: center;
  margin-left: 22px;
}

.notificationAvatarContainer {
  display: flex;
  align-items: center;
}

.notificationIndicator {
  margin-right: 10px;
}

.inReviewEmptyStateContainer {
  min-height: 400px;
  border: 1px solid #d6dce8;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.previewLink {
  cursor: pointer;
}

.loaderContainer {
  padding-top: 50px;
  padding-bottom: 100px;
}

.searchExpandIcon {
  margin-top: 5px;
  margin-right: 18px;
  cursor: pointer;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .hoverStateContainer {
    height: 59px !important;
  }
}

@media screen and (max-width: 1900px) {
  .recentlyViewedContainer {
    width: calc(100vw - 48px);
    background: #ffffff;
    border: 1px solid #d6dce8;
    box-sizing: border-box;
    border-radius: 9px;
    margin: 30px 10px 30px 10px;
    overflow-x: auto;
  }
}

@media screen and (max-width: 1850px) {
  .recentlyViewedContainer {
    width: calc(100vw - 48px);
    background: #ffffff;
    border: 1px solid #d6dce8;
    box-sizing: border-box;
    border-radius: 9px;
    margin: 30px 10px 30px 10px;
    overflow-x: auto;
  }
}

@media screen and (max-width: 1750px) {
  .recentlyViewedContainer {
    width: calc(100vw - 48px);
    background: #ffffff;
    border: 1px solid #d6dce8;
    box-sizing: border-box;
    border-radius: 9px;
    margin: 30px 10px 30px 10px;
    overflow-x: auto;
  }
}

@media screen and (max-width: 1700px) {
  .recentlyViewedContainer {
    width: calc(100vw - 48px);
    background: #ffffff;
    border: 1px solid #d6dce8;
    box-sizing: border-box;
    border-radius: 9px;
    margin: 30px 10px 30px 10px;
    overflow-x: auto;
  }
}

@media screen and (max-width: 1650px) {
  .recentlyViewedContainer {
    width: calc(100vw - 48px);
    background: #ffffff;
    border: 1px solid #d6dce8;
    box-sizing: border-box;
    border-radius: 9px;
    margin: 30px 10px 30px 10px;
    overflow-x: auto;
  }
}

@media screen and (max-width: 1600px) {
  .recentlyViewedContainer {
    width: calc(100vw - 48px);
    background: #ffffff;
    border: 1px solid #d6dce8;
    box-sizing: border-box;
    border-radius: 9px;
    margin: 30px 10px 30px 10px;
    overflow-x: auto;
  }
}

@media screen and (max-width: 1250px) {
  .inReview {
    position: relative;
    width: 69%;
    min-width: 820px;
  }
}

@media screen and (max-width: 1200px) {
  .inReview {
    position: relative;
    width: 65%;
    min-width: 820px;
  }
}
