.background {
  height: 100%;
  width: 320px;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 1;
  position: fixed;
  right: 0;
  bottom: 0;

  .container {
    color: #fff;
    background: rgb(57, 61, 80);
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 31px);
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
    animation-duration: 0.5s;
    animation-name: slideInUp;

    .header {
      padding: 20px 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-weight: 500;
        font-size: 24px;
      }

      .closeIcon {
        display: flex;
        cursor: pointer;
      }
    }

    .loadingState {
      height: 100%;
      margin: 0 30px 30px;
      background-color: #2c3041;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .aiRobot {
        img {
          width: 43px;
          height: 43px;
        }
      }

      .textContainer {
        width: 70%;

        .text1 {
          font-weight: 600;
          font-size: 13px;
          text-align: center;
        }

        .text2 {
          margin-top: 30px;
          font-weight: 500;
          font-size: 13px;
          text-align: center;
        }
      }

      .progressBar {
        width: 60%;
        height: 4px;
        border-radius: 2px;
        margin-top: 27px;
      }
    }

    .suggestionsState {
      display: flex;
      overflow-y: scroll;
      flex-direction: column;
      flex-grow: 1;

      .subheading {
        display: flex;
        align-items: center;
        margin: 0 15px;
        padding: 12px;
        border-top: 1px solid #5b5f72;
        border-bottom: 1px solid #5b5f72;

        .text {
          color: #dccaa9;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 12px;
          margin-right: 7px;
        }

        .tooltip {
          :global(.tooltip-container) {
            display: flex;
          }
          :global(.descriptionContent) {
            top: 4px;
            left: -176px;
            width: 250px;

            .body {
              .question {
                font-weight: 600;
                font-size: 16px;
              }
              .description {
                margin-top: 15px;
                font-weight: 500;
                font-size: 13px;
              }
              .link {
                font-size: 13px;
                margin-top: 15px;
                a {
                  color: #5bc1ca;
                }
              }
            }
          }
          :global(.tooltip-dimple-description) {
            top: 12px;
            transform: rotate(90deg);
          }
        }
      }

      .suggestionsContainer {
        margin: 0 15px;
        flex-grow: 1;
        .reference {
          padding: 15px;
          border-bottom: 1px solid #5b5f72;

          &:last-of-type {
            margin-bottom: 10px;
          }

          .purplePill {
            height: 15px;
            font-weight: 600;
            font-size: 10px;
            line-height: 10px;
            display: inline-flex;
            align-items: center;
            letter-spacing: 0.5px;
            color: #ffffff;
            background: #7a5dd1;
            border-radius: 30px;
            padding: 5px 10px 5px 10px;
            max-width: calc(100% - 20px);
            position: relative;

            &:hover {
              .fullLabelsContainer {
                display: block;
              }
            }

            .referenceDocumentLabel {
              overflow: hidden;
              word-wrap: break-word;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-right: 5px;
            }

            .referenceLabel {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              min-width: 15%;
            }
          }

          .greyPill {
            height: 15px;
            font-weight: 600;
            font-size: 10px;
            line-height: 10px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            letter-spacing: 0.5px;
            color: #ffffff;
            background: #6d7382;
            border-radius: 30px;
            padding: 5px 10px 5px 10px;
          }

          .fullLabelsContainer {
            display: none;

            .fullLabels {
              background-color: #2c3041;
              position: absolute;
              bottom: 29px;
              left: 50%;
              transform: translateX(-50%);
              border-radius: 2px;
              padding: 3px 10px;
              width: 100%;
              word-wrap: break-word;
              text-align: center;
            }

            .bottomArrow {
              position: absolute;
              width: 0;
              height: 0;
              left: 50%;
              top: -4px;
              border-top: 3px solid #2c3041;
              border-left: 3px solid transparent;
              border-right: 3px solid transparent;
            }
          }

          .caption {
            margin-top: 10px;
            color: white;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            font-weight: 500;
            font-size: 12px;
          }

          .linkReferenceConfidence {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;

            .linkReference {
              color: #5bc1ca;
              font-weight: 500;
              font-size: 13px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .suggestionsState + .manualLinkingButton {
      border-top: 1px solid #5b5f72;
      padding: 30px;

      :global(.button-primary) {
        width: 100%;
      }
    }

    .emptyState {
      height: 100%;
      margin: 0 30px 30px;
      background-color: #2c3041;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .linkIcon {
        img {
          max-width: 43px;
          max-height: 43px;
        }
      }

      .text {
        text-align: center;
        font-size: 13px;
        margin-top: 10px;
        margin-bottom: 25px;
      }

      .manualLinkingButton {
        width: 70%;
        :global(.button-primary) {
          width: 100%;
        }
      }
    }
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@media screen and (min-width: 1440px) {
  .background {
    height: 100%;
    width: 400px;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 1;
    position: fixed;
    right: 0;
    bottom: 0;
  }
}
