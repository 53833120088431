.referenceViewer {
  height: 100vh;
  width: 100vw;
  background-color: #393d50;
  display: flex;
}

.rightContainer {
  display: flex;
  align-items: center;
}

.annotaionLayersDropDownContainer {
  color: #393d50;
  cursor: pointer;
  font-size: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.layersIcon {
  margin-right: 10px;
}

.exportButton {
  background: #393d50;
  color: white;
}

.exportButton:hover {
  background: #393d50;
  color: white;
}

.exportButton:focus {
  background: #393d50;
  color: white;
}

.referenceDocumentContainer {
  flex-basis: 75%;
  .titleDescriptionContainer {
    display: flex;
    justify-content: space-between;
    .documentDescription {
      img {
        cursor: pointer;
      }
      .documentDescriptionBox {
        position: absolute;
        img {
          transform: rotate(90deg);
          left: 4px;
          top: -9px;
          position: absolute;
        }
        .documentDescriptionText {
          width: 320px;
          min-height: 40px;
          background: black;
          border-radius: 4px;
          position: absolute;
          top: 5px;
          transform: translateX(-50%);
          z-index: 1;
          color: #eaedf3;
          word-wrap: break-word;
          padding: 18px;
          box-sizing: border-box;
          font-size: 11px;
        }
      }
    }
  }
}

.sideBarContainer {
  flex-basis: 25%;
  overflow-y: auto;
  .referencesContainer {
    height: calc(100% - 265px) !important;
    overflow-y: auto;
    scrollbar-color: rgb(157, 168, 181) rgb(50, 53, 68);
    scrollbar-width: thin;
    padding-bottom: 20px;
    .pageNumber {
      margin-left: 42px;
      margin-bottom: 8px;
      color: #dccaa9;
      font-size: 12px;
      font-weight: 600;
      margin-top: 19px;
    }
    .dividerContainer {
      margin: 20px 30px 0px 30px;
      border-top: 1px solid #5b5f72;
    }
    .referenceCaption {
      margin: 0px 42px 22px;
      color: white;
      font-weight: 500;
      font-size: 12px;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .pinReferenceCaption {
      margin: 5px 42px 22px 20px;
      color: white;
      font-weight: 500;
      font-size: 12px;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .referenceLinks {
      margin-left: 42px;
      display: flex;
      color: #bac0cb;
      font-weight: 600;
      font-size: 12px;
      div {
        margin-left: 4px;
      }
      margin-bottom: 16px;
    }
  }
}

.headerContainer {
  box-sizing: border-box;
  height: 100px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 20px;
}

.referenceContainer {
  height: calc(100% - 100px);
}

.sidebarHeader {
  margin: 20px 42px 20px;
  .closeButton {
    display: flex;
    justify-content: flex-end;
    margin-left: 25px;
    img {
      cursor: pointer;
      width: 17px;
    }
  }
  .searchInputField {
    margin: 0 auto;
    margin-top: 22px;
    background-color: #2c3041;
    border: none;
    color: #8a8e96;
    &::placeholder {
      color: #8a8e96;
      background-image: url("../../images/icons/svg/search.svg");
      background-repeat: no-repeat;
      background-position: right;
    }
  }
}

// Mozilla firefox specific styling for the input placeholder

@-moz-document url-prefix() {
  .searchInputField {
    &::placeholder {
      background-position-y: 50% !important;
      background-position-x: 96.5% !important;
    }
  }
}

.modalTitleLabel {
  text-transform: uppercase;
  color: #9ea0a5;
  font-size: 12px;
  font-weight: 600;
}

.modalDescriptionLabel {
  text-transform: uppercase;
  color: #9ea0a5;
  font-size: 12px;
  font-weight: 600;
  margin-top: 15px;
}

.titleModalInput {
  font-size: 14px;
  padding: 8px 16px;
  border: solid 1px #d6dce8;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}

.descriptionModalInput {
  font-size: 14px;
  padding: 8px 16px;
  border: solid 1px #d6dce8;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  resize: none;
  height: 95px;
}

.deleteReferenceModalBody {
  font-size: 14px;
  font-weight: 500;
  color: #6b6c6f;
}

.header {
  font-size: 24px;
  font-weight: 500;
  color: white;
  margin-bottom: 6px;
}

.documentTitle {
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.sidePanelContainer {
  padding-left: 0 !important;
}

.sidePanel {
  border-left: 1px solid #eaedf3;
  min-height: 100vh;
  height: 100%;
}

.sidePanelHeader {
  border-bottom: 1px solid #eaedf3;
  color: #3e3f42;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 20px;
  padding-top: 26px;
}

.sidePanelOptions {
  border-bottom: 1px solid #eaedf3;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding: 13px 30px;
}

.referenceActionsDropDown {
  float: right;
}

.referenceActionsDropDownContainer {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 10px;
}

.backLink {
  color: #b6b6b6 !important;
  margin-right: 10px;
}

.renameContentContainer {
}

.renameContentHeadline {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  padding: 5px 25px;
}

.renameContentFormContainer {
  padding: 24px;
}

.renameContentFormButtons {
  padding: 20px 30px;
}

.renameCancelButton {
  margin-right: 20px;
}

.approvalsLink {
  color: #3e3f42;
  font-size: 12px;
  font-weight: 500;
}

.approvalsRemaining {
  color: hsla(184.8780487804878, 69.49%, 34.71%, 1);
  display: block;
  float: right;
}

.approvals {
  display: block;
  float: left;
}

.uploadNewVersionLink {
  color: #3e3f42 !important;
}

.conversationIconContainer {
  background-color: #ff9c79;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.conversationIcon {
  font-size: calc(28px / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(28px / 7); /* 25% of parent */
}

.closeComment {
  color: #9ea0a5;
  font-size: 20px;
  float: right;
  height: 16px;
  width: 16px;
}

.notCommentPanel {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.cancelButton {
  color: #fff;
}

.confirmButton {
  color: #7a6a8d;
  margin-left: 10px;
}

.highlight {
  background-color: #daa520;
}

.container {
  display: flex;
  justify-content: flex-end;
}

.captionContainer {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
}

.pindropCaptionContainer {
  display: flex;
  flex-direction: row;
  margin-left: 44px;
  position: relative;
}

.pinIcon {
  position: absolute;
  top: 3px;
}

.referenceActionsDropDownContainer {
  margin-top: 5px;
}

.doneReferenceButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  bottom: 0;
  background-color: #393d50;
  height: 100px;
  box-shadow: 0px 0px 0px #000, 0px -5px 5px rgba(50, 50, 50, 0.45);
  :global(.button-primary) {
    width: 90%;
  }
}

.referencesContainer::-webkit-scrollbar-track {
  background-color: rgb(50, 53, 68);
}

.referencesContainer::-webkit-scrollbar {
  width: 8px;
}

.referencesContainer::-webkit-scrollbar-thumb {
  background-color: rgb(146, 157, 170);
  border-radius: 8px;
}

.attachmentReferenceIdContainer {
  display: flex;
  margin: 20px 42px 10px;
  justify-content: space-between;
}

.referenceDocumentLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;
}

.referenceLabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 15%;
}

.attachmentReferenceId {
  height: 15px;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  background: #7a5dd1;
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
  max-width: 80%;
  position: relative;

  &:hover {
    .fullLabelsContainer {
      display: block;
    }
  }
}

.emptyAttachmentReferenceId {
  height: 15px;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  background: #6d7382;
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
  max-width: 80%;
}

.table {
  width: 100%;
}

.tableContainer {
  height: 10em;
}

.table {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}
.table thead {
  /* head takes the height it requires, 
    and it's not scaled when table is resized */
  // flex: 0 0 auto;
  width: 95%;
}
.table tbody {
  /* body takes all the remaining available space */
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
}

.table tbody tr {
  width: 95%;
  display: flex;
  // table-layout: fixed;
  justify-content: space-between;
}

// .table thead {
//   display: table;
//   table-layout: fixed;
// }

.nameContainer {
  display: flex;
  align-items: center;
}
.linkImage {
  margin-right: 5px;
}
.headerName {
  width: 79%;
}
.docName {
  max-width: 250px;
  color: #2bbaca;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.docNameGray {
  max-width: 250px;
  color: #98a2b3;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  cursor: not-allowed;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.docUploadDate {
  width: 30%;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #344054;
}

.tableHeader {
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #98a2b3;
  text-transform: uppercase;
}

.nameColumn {
  width: 70%;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #98a2b3;
  text-transform: uppercase;
}

.dateColumn {
  width: 30%;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #98a2b3;
  text-transform: uppercase;
}

.traceabilityHeaderContainer {
  width: 100%;
  display: flex;
}

.traceabilityHeaderContainerScroll {
  width: 97%;
  display: flex;
}

.col {
  width: 30%;
}

.colLarge {
  width: 70%;
}

.annotationCountContainer {
  position: relative;
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
  margin: 10px 42px 0px 10px;
}

.emptyAnnotationCountContainer {
  cursor: not-allowed;
  position: relative;
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
  margin: 10px 42px 0px 10px;
}

.annotationCount {
  color: #1b8c96;
  background: none;
  border: solid 1px #1b8c96;
  border-radius: 9.5px;
  padding: 2.5px 15px 2.5px 15px;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  position: absolute;
  bottom: 5%;
}

.annotationCount:hover ~ .descriptionBoxContainer {
  display: flex;
  position: relative;
  width: 250px;
}
.descriptionBox {
  position: absolute;
  z-index: 1000;
  left: 45%;
  margin-top: 5px;
}
.descriptionBoxContainer {
  display: none;
}

.descriptionBoxImage {
  transform: rotate(90deg);
  left: 115px;
  top: -9px;
  position: absolute;
}

.descriptionText {
  width: 266px;
  min-height: 40px;
  background: #2c3041;
  border-radius: 4px;
  position: absolute;
  top: 5px;
  transform: translateX(-50%);
  color: #eaedf3;
  word-wrap: break-word;
  padding: 18px;
  box-sizing: border-box;
  font-size: 11px;
}

.fullLabelsContainer {
  display: none;
}

.fullLabels {
  background-color: #2c3041;
  position: absolute;
  bottom: 29px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  padding: 3px 10px;
  width: 100%;
  word-wrap: break-word;
  text-align: center;
}

.bottomArrow {
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  top: -4px;
  border-top: 3px solid #2c3041;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

.referenceCard {
  cursor: pointer;
}

@media screen and (max-width: 1500px) {
  .descriptionBoxImage {
    transform: rotate(90deg);
    left: 100px;
    top: -9px;
    position: absolute;
  }
}
