.container {
  width: 320px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background: #393d50;
  padding: 15px;
  box-sizing: border-box;
  overflow: auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 15px;
    border-bottom: 1px solid #5b5f72;

    .text {
      font-weight: 500;
      font-size: 24px;
      color: white;
    }

    .closeIcon {
      display: flex;
      cursor: pointer;
    }
  }

  .body {
    height: calc(100% - 92px);
    .signedBy {
      color: white;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin-top: 23px;
    }

    .signedAt {
      margin-top: 17px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;

      color: #eaedf3;
    }
  }
}

@media screen and (min-width: 1440px) {
  .container {
    width: 400px;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    background: #393d50;
    padding: 15px;
    box-sizing: border-box;
  }
}

.page {
  .dividerContainer {
    border-top: 1px solid #5b5f72;
  }
  .title {
    text-transform: uppercase;
    margin: 0 auto;
    color: #dccaa9;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 13px;
    margin-top: 26px;
    margin-left: 6%;
  }
  .emptyMessage {
    height: 100%;
    width: 88%;
    background-color: #2c3040;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px 20px 0px;
    border-radius: 4px;
    margin: 20px 0px 20px 0px;
  }
  .messageContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .conversation {
    width: 88%;
    margin: 0 auto;
    cursor: pointer;

    .dotSeparator {
      font-size: 4px;
    }

    .metadata {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .leftContainer {
        display: flex;

        .commentSVG {
          width: 16px;
        }
        .nameDate {
          display: flex;
          align-items: center;
          .name {
            font-weight: 500;
            margin-left: 4px;
            margin-right: 4px;
            font-size: 13px;
          }
          .date {
            margin-left: 4px;
            font-size: 12px;
            color: #d6dce8;
            position: relative;

            .fullDateTimeContainer {
              display: none;

              .fullDateTime {
                position: absolute;
                background-color: black;
                top: -25px;
                left: 50%;
                transform: translateX(-50%);
                white-space: nowrap;
                border-radius: 2px;
                padding: 3px 10px;
                color: white;
              }

              .bottomArrow {
                position: absolute;
                width: 0;
                height: 0;
                left: 50%;
                top: -4px;
                border-top: 3px solid black;
                border-left: 3px solid transparent;
                border-right: 3px solid transparent;
              }
            }
          }
          .date:hover .fullDateTimeContainer {
            display: block;
          }
        }
        .numberComments {
          font-size: 12px;
          margin-left: 4px;
          margin-right: 4px;
          color: #d6dce8;
          display: flex;
          align-items: center;
        }
      }
      .rightContainer {
        display: flex;

        .noCommentContainer {
          display: flex;
          font-size: 10px;
          align-items: center;
          .arrowRight {
            display: flex;
            margin-left: 10px;
          }
        }
        .checkmarkContainer {
          position: relative;
          .toggleDisabledContainer {
            display: none;
            font-size: 12px;

            .disabledText {
              position: absolute;
              background-color: black;
              top: -28px;
              right: 0;
              white-space: nowrap;
              border-radius: 2px;
              padding: 3px 10px;
              color: white;

              .alignDot {
                line-height: 4px;
                vertical-align: middle;
              }
            }

            .bottomArrow {
              position: absolute;
              width: 0;
              height: 0;
              left: 50%;
              transform: translateX(-50%);
              top: -7px;
              border-top: 3px solid black;
              border-left: 3px solid transparent;
              border-right: 3px solid transparent;
            }
          }
        }

        .checkmarkContainer:hover .toggleDisabledContainer {
          display: block;
        }
      }
    }

    .latestCommentText {
      font-size: 13px;
      font-weight: 500;
      margin-top: 10px;
      line-height: 24px;
    }
  }
}

.diffDividerContainer {
  border-top: 1px solid #5b5f72;
  margin-top: 25px;
  margin-bottom: 20px;
}

.diffClaimSuggester {
  display: flex;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  padding-left: 6%;
  margin-top: 20px;
}

.dotSeparator {
  font-size: 4px;
  margin-left: 5px;
  margin-right: 5px;
}

.date {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(214, 220, 232, 1);
}

.conversationActionsDropDownContainer {
  margin-right: 20px;
  margin-top: 5px;
}

.backArrow {
  margin-right: 10px;
  cursor: pointer;
}

.noSearchResultsEmptyStateContainer {
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;

  .titleText {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
  }

  .text {
    margin: 20px 40px 20px 40px;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
  }
}

.emptyState {
  height: 100%;
  margin: 30px 15px 30px 15px;
  background-color: #2c3041;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .linkIcon {
    img {
      max-width: 43px;
      max-height: 43px;
    }
  }

  .text {
    text-align: center;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 25px;
    margin-right: 25px;
    margin-left: 25px;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
  }

  .manualLinkingButton {
    width: 70%;
    :global(.button-primary) {
      width: 100%;
    }
  }
}
