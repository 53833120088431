.draftUpload {
}

.draftUploadHeading {
  margin: 0 auto;
  text-align: center;
}

.mainHeadline {
  font-weight: 500;
}
