.step-container {
  color: #45546e;
  font-weight: 500;
  position: relative;

  .step-circle {
    display: flex;
    justify-content: center;
    align-content: center;
    border: 2px solid #45546e;
    padding: 8px 8px;
    height: 19px;
    border-radius: 50%;
    width: 19px;
  }

  .text {
    position: absolute;
    white-space: nowrap;
    top: 43px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
  }

  &__completed {
    .step-circle {
      border-color: #1b8c96;
      background-color: #1b8c96;
    }

    .text {
      color: #1b8c96;
    }
  }
}
