.container {
  .backLink {
    margin-top: 10px;
    margin-left: 20px;
    color: #1b8c96;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .backArrow {
      display: flex;
    }

    .text {
      margin-left: 5px;
    }
  }

  .label {
    margin: 10px 20px;
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    color: #b7bec8;
  }

  .records {
    margin-bottom: 20px;

    .link {
      .recordContainer {
        padding: 5px 20px 5px 20px;
        display: flex;
        align-items: center;

        .logo {
          display: flex;
        }

        .title {
          margin-left: 6px;
          color: #3e3f42;
          font-weight: 500;
          font-size: 13px;
        }
      }
      .recordContainer:hover {
        padding: 5px 20px 5px 20px;
        background: #deebff;
        display: flex;
        align-items: center;

        .logo {
          display: flex;
        }

        .title {
          margin-left: 6px;
          color: #3e3f42;
          font-weight: 500;
          font-size: 13px;
        }
      }
    }
  }
}
.highlight {
  background-color: #fff0bd;
  color: black;
}

.documentDetails {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #8e9ab3;
  margin-left: 6px;
}

.documentMetadata {
  display: flex;
}
