.commentPanel {
  background-color: white;
  color: #353949;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  // position: fixed;
  // top: 0;
  // right: 0;
  width: 100%;
  overflow-y: auto;
}

.mobileCommentPanel {
  background-color: #393d50;
  color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  overflow-y: auto;
}

.comment {
  padding: 0 20px;
  word-break: break-word;
  white-space: pre-wrap;
  cursor: pointer;
}

.messageInput {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  opacity: 1;
  background: rgba(44, 48, 65, 1);
  width: 100%;
  overflow-y: auto;
  z-index: 99;
  min-height: 150px;
  background-color: white;
  color: #353949;
  border-top: 1px solid #dfe4ed;
}

.messageInput::-webkit-scrollbar-track {
  background-color: white;
}

.messageInput::-webkit-scrollbar {
  width: 8px;
}

.messageInput::-webkit-scrollbar-thumb {
  background-color: rgb(146, 157, 170);
  border-radius: 8px;
}

.messageInput {
  scrollbar-color: #868e96 white;
  scrollbar-width: thin;
}

.messageInputField,
.messageInputField:focus {
  border: none;
  background-color: white;
  color: #353949;
  font-size: 14px;
  border-width: 0px;
  outline: none !important;
  padding: 20px 20px;
  resize: none;
  width: 80%;
}

.messageInputSubmit {
  display: flex;
  padding: 20px 15px 0px 15px;
  justify-content: space-between;
  background-color: white;
  color: #353949;
  border-top: 1px solid #dfe4ed;
  .sendButton {
    cursor: pointer;
    background: none;
    border: none;
    width: 80px;
    height: 34px;
    overflow: hidden;
    padding: 0;
    color: #9d9fa4;
    font-size: 20px;
    background: #1b8c96;
    border-radius: 4px;

    .hideSendButton {
      display: none;
    }

    .loading {
      width: 16px;
      height: 16px;
      animation: spin 1s infinite;
      margin-right: 10px;
      margin-left: 7.5px;
    }

    &:focus {
      outline: none;
    }
  }
}

.sendContainer {
  display: flex;
}

.buttonText {
  margin: auto;
  color: white;
  font-size: 0.875rem;
  margin-left: 0px;
}

.buttonTextMention {
  margin: auto;
  color: #9d9fa4;
  font-size: 0.875rem;
}

.messageInputSubmitGuest {
  display: flex;
  padding: 20px 15px 20px 15px;
  justify-content: flex-end;
  border-top: 1px solid rgba(234, 237, 243, 0.2);
  background: #2c3041;
  .sendButton {
    cursor: pointer;
    background: none;
    border: none;
    width: 80px;
    height: 34px;
    overflow: hidden;
    padding: 0;
    color: #9d9fa4;
    font-size: 20px;
    background: #1b8c96;
    border-radius: 4px;

    .hideSendButton {
      display: none;
    }

    .loading {
      width: 16px;
      height: 16px;
      animation: spin 1s infinite;
      margin-right: 10px;
      margin-left: 7.5px;
    }

    &:focus {
      outline: none;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sidePanelOptions {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding: 20px 0px 0px;
  overflow-y: auto;
  height: calc(100% - 245px);
}

.sidePanelOptions::-webkit-scrollbar-track {
  background-color: white;
}

.sidePanelOptions::-webkit-scrollbar {
  width: 8px;
}

.sidePanelOptions::-webkit-scrollbar-thumb {
  background-color: rgb(146, 157, 170);
  border-radius: 8px;
}

.sidePanelOptions {
  scrollbar-color: #868e96 white;
  scrollbar-width: thin;
}

.sidePanelHeader {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  background-color: white;
  color: #353949;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 20px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  opacity: 1;

  .checkmark {
    display: flex;
    float: right;
    margin: 2px 0px 0 0;
    cursor: pointer;
    .resolve {
      color: #2f839f;
      margin-right: 10px;
    }
  }
}

.conversationIconContainer {
  background-color: #ff9c79;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.conversationIcon {
  font-size: calc(28px / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(28px / 7); /* 25% of parent */
}

.closeComment {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 26px;
  float: right;
  height: 16px;
  width: 16px;
}

.conversationActionsDropDownContainer {
  float: right;
  margin: 1px 25px 0 0;
  cursor: pointer;
}

.commentSendIcon {
  border: none;
}

.moreIcon {
  position: relative;
  top: 3px;
}

.addTimestamp {
  font-size: 0.75rem;
  margin: 5px 20px;
}

.editor {
  width: calc(100% - 40px);
  margin-left: 20px;
  padding: 20px 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.editor :global(.public-DraftEditor-content) {
  overflow: scroll;
  max-height: 50vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.sidePanelHeadingTitle {
  font-weight: 600;
  font-size: 22px;
}

.backArrow {
  margin-right: 10px;
  cursor: pointer;
}

.showSendButton {
  background: #1b8c96;
  padding: 7px 9px 7px 9px;
  height: 20px;
  border-radius: 10%;
}

.atButton {
  cursor: pointer;
  background: none;
  border: none;
  width: 95px;
  height: 34px;
  overflow: hidden;
  padding: 0;
  color: #9d9fa4;
  font-size: 20px;
  display: flex;
  padding-bottom: 2.5px;
  padding-top: 2.5px;
  padding-left: 2.5px;
}

.atButton:hover {
  background-color: #f2f4f7;
  border-radius: 10%;
}

.foundClaimContainer {
  background: #2c3041;
  border-radius: 10px;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 20px;
}

.foundClaimTop {
  display: flex;
  justify-content: space-between;
  margin: 15px 15px 0px 15px;
}

.foundClaimBottom {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}

.expandIcon {
  cursor: pointer;
}

.foundClaimTitleContainer {
  display: flex;
  width: 50%;
}

.dividerContainer {
  border-top: 1px solid #5b5f72;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
}

.foundClaimTitle {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: white;
  text-transform: uppercase;
  margin-left: 10px;
}

.helpText {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(214, 220, 232, 1);
}

@media screen and (max-width: 1350px) {
  .sidePanelHeadingTitle {
    font-weight: 600;
    font-size: 18px;
  }
}
