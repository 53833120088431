.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  width: 100vw;

  .header {
    padding: 3px 20px;
    color: #3e3f42;
    font-size: 18px;
    font-weight: 500;
  }

  .documentContainer {
    margin-top: 30px;
  }

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .webViewerContainer {
    width: 100%;
    flex: 1;
  }

  .errorState {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      .header {
        font-weight: 800;
        font-size: 87.2083px;
        line-height: 137px;
        text-transform: uppercase;
        color: #1b8c96;
        padding: 0;
      }

      .text {
        font-weight: 500;
        font-size: 24px;
        line-height: 37px;
        color: #3e3f42;
      }
    }
  }
}

@media (min-width: 802px) {
  .downloadButtonDesktop {
    border: 1px solid #1b8c96;
    cursor: pointer;
    color: #1b8c96;
    background: white;
    border-radius: 4px;
    width: 100px;
    height: 30px;
    margin-right: 20px;
    margin-top: 20px;
  }

  .downloadButtonMobile {
    display: none;
    cursor: pointer;
    margin-right: 10px;
  }
}

@media (max-width: 801px) {
  .downloadButtonMobile {
    display: inline-block;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 20px;
  }

  .downloadButtonDesktop {
    display: none;
  }
}
