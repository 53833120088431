.indicatorContainer {
  right: 0px;
  float: left;
}

.documentPageCommentIndicators {
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
}

.conversationIndicatorContainer {
  background-color: #ff9c79;
  border-radius: 5px;
  color: #fff;
  padding: 8px 8px 6px 8px;
}
