.createFormContainer {
  .createForm {
    background: white;
    border: 1px solid #eaedf3;
    border-radius: 4px;
    margin-top: 82px;

    .header {
      padding: 28px 25px;
      border-bottom: 1px solid #eaecee;
      .title {
        color: #3e3f42;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
      }

      .description {
        color: #9ea0a5;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
      }
    }

    .body {
      padding: 28px;

      .libraryDropdown {
        font-size: 14px;
      }

      .dateFieldsDropdown {
        margin-top: 28px;
        font-size: 14px;
      }

      .setReminder {
        margin-top: 33px;
        display: flex;
        align-items: center;

        .textBeforeInput,
        .textAfterInput {
          color: #3e3f42;
          letter-spacing: 0.3px;
          font-size: 12px;
          line-height: 18px;
        }

        .numberInput {
          width: 79px;
          height: 43px;
          margin: 0 10px;

          input {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid hsl(0, 0%, 80%);
            border-radius: 4px;
            padding: 8px;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
              -moz-appearance: textfield;
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 31px;
      border-top: 1px solid #eaecee;
    }
  }
}
