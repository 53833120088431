.container {
  width: 320px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background: #393d50;
  padding: 15px 15px 0px 15px;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 15px;
    border-bottom: 1px solid #5b5f72;

    .text {
      font-weight: 500;
      font-size: 24px;
      color: white;
    }

    .closeIcon {
      display: flex;
      cursor: pointer;
    }
  }

  .body {
    height: 100%;
    overflow-y: scroll;
    .signedBy {
      color: white;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin-top: 23px;
    }

    .signedAt {
      margin-top: 17px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;

      color: #eaedf3;
    }
  }
}

@media screen and (min-width: 1440px) {
  .container {
    width: 400px;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    background: #393d50;
    padding: 15px 15px 0px 15px;
    box-sizing: border-box;
  }
}

.inputContainer {
  padding: 0px 3% 19px 3%;
}

.containerStructuredContent {
  // margin: 20px 0px 0px 0px;
  padding-bottom: 20px;
  background: #2c3041;
  border-radius: 10px;
  padding: 15px;
  position: relative;
}

// .search {
//   position: absolute;
// }

.formContainer {
  padding-right: 15px;
  padding-left: 15px;
}

.claimsForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
  height: 100%;
}

.formButton {
  display: flex;
  justify-content: center;
  padding: 30px;
  border-top: 1px solid rgba(234, 237, 243, 0.2);
}

.searchInputField {
  margin: 0 auto;
  margin-top: 10px;
  background-color: #2c3041;
  border: none;
  color: #8a8e96;
}

.searchInputStructured {
  margin: 0 auto;
  // margin-top: 10px;
  background: #393d50;
  border: none;
  color: #8a8e96;
  background: url(../../../../images/icons/svg/search-grey-small.svg) no-repeat
    scroll 9px 9px;
  padding-left: 35px;
}

.searchInputFieldStructured {
  margin: 0 auto;
  margin-top: 10px;
  background: #393d50;
  border: none;
  color: #8a8e96;
}

.searchInputField:disabled {
  margin: 0 auto;
  margin-top: 10px;
  background-color: #2c3041;
  border: none;
  color: #8a8e96;
}

.inputHeader {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: rgba(158, 160, 165, 1);
  text-transform: uppercase;
  margin-bottom: 20px;
}

.scInputContainer {
  margin-top: 20px;
  margin-left: 22px;
  margin-right: 22px;
  padding-bottom: 20px;
  border-bottom: 1px solid #5b5f72;
}

.claimsFormContainer {
  height: 100%;
}

.labelPlaceHolder {
  width: 117px;
  height: 9px;

  background: #2c3041;
  border-radius: 2px;
}

.inputPlaceHolder {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: 35px;

  background: #2c3041;
  border-radius: 2px;
}

.structuredContentIcon {
  margin-right: 5px;
}

.emptyState {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
}

.emptyStateDropzone {
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
}

.fileName {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  margin-bottom: 10px;
}

.fileType {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #eaedf3;
}

.progressContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: rgba(44, 48, 65, 1);
  border-radius: 4.1px;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 20px;
}

.successIcon {
  padding-bottom: 10px;
}

.successIconApproved {
  padding-bottom: 10px;
}

.showPopUpUploader {
  background-color: #2c3041;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 6%;
  vertical-align: middle;
  height: 90%;
  position: absolute;
  top: 0;
  left: 1%;
  width: 78%;
}

.emptyStateUploaderIconContainer {
  color: #9ea0a5;
  display: inline-block;
  line-height: 22px;
  margin: 10px;
}

.emptyStateUploaderIcon {
  display: flex;
}

.emptyStateUploaderPlaceholder {
  color: white;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 30px;
}

.uploadCaption {
  margin-top: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 22px;
  color: white;
  text-align: center;
  width: 100%;
  display: inline-block;
}

.uploadButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(44, 48, 65, 1);
  border-radius: 4.1px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.uploaderContainer {
  width: 100%;
}

.imageFieldInput {
  margin-top: 20px;
  margin-left: 22px;
  margin-right: 22px;
  padding-bottom: 20px;
  border-bottom: 1px solid #5b5f72;
}

.fileDetailsContainer {
  margin-bottom: 15px;
}

.dimensionInputContainer {
  width: 45%;
}

.imageDimensionsInputContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.deleteImageField {
  display: none;
}

.progressContainer:hover .deleteImageField {
  display: flex;
  cursor: pointer;
  padding-bottom: 10px;
}

.progressContainer:hover .successIcon {
  display: none;
}

.structuredContentCard {
  background-color: #4f5366;
  color: #fff;
  font-size: 12px;
  padding: 10px;
  border: 1px solid #606676;
  border-radius: 10px;
  margin: 5px;
}

.structuredContentCardBody {
  display: flex;
  justify-content: space-between;
}

.cardBodyContainer {
  display: flex;
  flex-direction: column;
}

.groupedTemplateFieldItem {
  width: 58px;
  height: 50px;
  position: relative;
}

.templateString {
  margin-bottom: 5px;
}

.propertyValue {
  color: #ff9a31;
  font-weight: 600;
}

.leftContainer {
  display: flex;
  width: 90%;
}

.affectedContent {
  margin-left: 40px;
  text-transform: capitalize;
}

.categoryName {
  display: flex;
  justify-content: space-between;
  margin: 40px;
}

.loaderContainer {
  padding-top: 200px;
  padding-bottom: 200px;
}

.backArrow {
  width: 25px;
  margin-right: 20px;
  cursor: pointer;
}

.tableContainer {
  margin: 40px;
}

.groupedTemplateFieldsContainer {
  margin-bottom: 30px;
}

.selectedContentCardContainer {
  margin: 40px;
}

.selectedContentCard {
  border: 1px solid #d6dce8;
  border-radius: 4px;
  padding: 0px 15px 15px 15px;
}

.modalContainer {
  position: relative;
}

.searchInputContainer {
  margin: 30px 40px 10px 40px;
  position: relative;
  .searchIcon {
    position: absolute;
    top: 11px;
    left: 13px;
  }

  .crossIcon {
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;
  }

  input {
    width: 100%;
    border: 1px solid #d6dce8;
    border-radius: 4px;
    height: 38px;
    padding: 8px 40px;
    box-sizing: border-box;
    color: -internal-light-dark(white, white);

    &:focus {
      border-color: #1b8c96;
      outline: none;
    }

    &::placeholder {
      color: #bfbfbf;
    }
  }
}

.groupedTemplateFieldItem::before {
  content: "";
  height: 70px;
  width: 2px;
  position: absolute;
  right: 10px;
  background: #ff9a31;
}

.groupedTemplateFieldItem::after {
  content: "\00B7";
  color: #ff9a31;
  background: white;
  position: absolute;
  top: 0;
  right: 2px;
  font-size: 0px;
  padding: 2px;
  border: 2px solid #ff9a31;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentPropertyItemContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.itemContainer {
  display: flex;
  height: 70px;
}

.structuredContentCardCaption {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 5px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.structuredContentCardLabel {
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #b9c0ca;
  display: flex;
  align-items: center;
}

.categoryIcon {
  margin-right: 5px;
}

.structuredContentSearchResults {
  background-color: #4f5366;
  border-radius: 10px;
  padding: 5px !important;
  margin-top: 5px !important;
  overflow: auto;
  max-height: 500px;
}

.selectedStructuredContent {
  background-color: #4f5366;
  border-radius: 10px;
  padding: 5px !important;
  margin-top: 5px !important;
}

.selectedStructuredContentCard {
  background-color: #2c3041;
  color: #fff;
  font-size: 12px;
  padding: 10px;
  border: 1px solid #606676;
  border-radius: 10px;
  margin: 5px;
}

.image {
  height: 100%;
  width: 100%;
  border-radius: 4.1px;
}

.imageSelected {
  height: 100%;
  width: 100%;
  border-radius: 4.1px;
  // padding-top: 30px;
  // padding-bottom: 30px;
}

.imageHeading {
  display: flex;
  justify-content: space-between;
  color: white;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.closeIcon {
  cursor: pointer;
}
