.metadataField {
  margin-bottom: 10px;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  .tooltip {
    margin-top: 0;

    .tooltipContent {
      width: 300px;
      color: #eaedf3;
      font-size: 13px;
      font-weight: 500;

      .title {
        font-weight: 700;
      }

      .dateRemindersList {
        ul {
          list-style-type: unset !important;
          margin: revert !important;
          padding-inline-start: 20px !important;

          li {
            margin-top: 5px;
            .daysBefore {
              font-weight: 700;
            }
          }
        }
      }

      .link {
        margin-top: 10px;
        display: flex;

        .text {
          color: #5bc1ca;
          text-decoration: underline;
        }
        .icon {
          display: flex;
          margin-left: 5px;
        }
      }
    }
  }

  .label {
    margin-right: 10px;
  }
}
