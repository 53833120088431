.container {
  overflow: scroll;
  min-height: 100vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.top {
  display: flex;
  margin-left: 35px;
  margin-right: 35px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.topLinksContainer {
  background: #ffffff;
  border: 1px solid #d6dce8;
  box-sizing: border-box;
  border-radius: 9px;
  padding: 30px;
  width: 40%;
  min-width: 390px;
}

.chartContainer {
  background: #ffffff;
  border: 1px solid #d6dce8;
  box-sizing: border-box;
  border-radius: 9px;
  padding: 30px;
  width: 55%;
  min-width: 550px;
}

.clicksTotal {
  // font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: #6b6c6f;
  text-align: right;
}

.downloadsTotal {
  // font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: #6b6c6f;
  text-align: right;
}

.clicksContainer {
  margin-right: 50px;
}

.totalsContainer {
  display: flex;
}

.summaryContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 2%;
  padding-bottom: 65px;
}

.clicksTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  /* identical to box height */
  text-transform: uppercase;

  color: #393d50;
}

.downloadsTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  /* identical to box height */
  text-transform: uppercase;

  color: #393d50;
}

.titleContainer {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;

  color: #393d50;
}

.pageHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 35px;
  /* identical to box height, or 140% */

  color: #3e3f42;
}

.activityLogSubheadingOne {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;
  text-transform: uppercase;
  color: #9ea0a5;
  mix-blend-mode: normal;
  opacity: 0.9;
  margin-left: 50px;
}

.activityLogSubheadingTwo {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;
  text-transform: uppercase;
  color: #9ea0a5;
  mix-blend-mode: normal;
  opacity: 0.9;
  margin-right: 150px;
}

.activityLogSubheadingContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.activityLogHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #3e3f42;
  margin-left: 30px;
  margin-bottom: 5px;
}

.eventDetails {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #eaedf3;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  position: relative;
}

.linkAnalyticsContainer {
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.5px;
}

.linkDownloadsContainer {
  background: #e2eef1;
  border-radius: 9.5px;
  display: flex;
  align-items: center;
  height: 17px;
  width: 42px;
  justify-content: center;
  text-align: center;
  padding-top: 1px;
  padding-bottom: 1px;
}

.linkClicksContainer {
  background: #eeebff;
  border-radius: 9.5px;
  display: flex;
  align-items: center;
  height: 17px;
  width: 42px;
  margin-left: 10px;
  justify-content: center;
  text-align: center;
  padding-top: 1px;
  padding-bottom: 1px;
}

.linkLabelContainer {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #3e3f42;
}

.linkDocumentNameContainer {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #9ea0a5;
}

.linkStatIcon {
  margin-right: 5px;
}

.topLinksHeaderContainer {
  display: flex;
  justify-content: space-between;
}

.closeButton {
  margin-right: 30px;
  cursor: pointer;
}

.labelContainer {
  display: flex;
  margin-left: 30px;
  margin-bottom: 15px;
}

.linkLabel {
  font-size: 14px;
  font-family: Montserrat;
  color: #6b6c6f;
  font-weight: 600;
  margin-right: 5px;
}

.linkDocumentName {
  font-size: 12px;
  font-family: Montserrat;
  color: #9ea0a5;
  font-weight: 500;
  margin-top: 1px;
}

.eventType {
  padding-left: 50px;
  width: 50%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #3e3f42;
}

.eventDate {
  padding-left: 48px;
  width: 50%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #3e3f42;
}

.eventTypeIcon {
  margin-right: 15px;
}

.topShareLinksContainer {
  height: 100%;
}

.emptyStateContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyMessageContainer {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: #ffffff;
  margin: 20px 35px 20px 35px;
  width: 200px;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #9ea0a5;
}

.shareLinkGrey {
  height: 30px;
  margin-bottom: 20px;
}

.linksContainer {
  height: 100%;
  align-items: center;
  // justify-content: space-around;
  display: flex;
}

.exportButton {
  border: none;
  color: #1b8c96;
  padding: 5px 20px;
  background: none;
  box-shadow: none;
}

.viewContentButton {
  border: none;
  color: #1b8c96;
  padding: 5px 20px;
  background: none;
  box-shadow: none;
}

.exportButton:hover {
  border: none;
  color: #1b8c96;
  padding: 5px 20px;
  background: none;
  box-shadow: none;
}

.viewContentButton:hover {
  border: none;
  color: #1b8c96;
  padding: 5px 20px;
  background: none;
  box-shadow: none;
}

.externalLink {
  margin-left: 7px;
}

.hoverStateContainer {
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d6dce8;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1330px) {
  .exportButton {
    border: none;
    color: #1b8c96;
    padding: 5px 10px;
    background: none;
    box-shadow: none;
  }

  .viewContentButton {
    border: none;
    color: #1b8c96;
    padding: 5px 10px;
    background: none;
    box-shadow: none;
  }

  .exportButton:hover {
    border: none;
    color: #1b8c96;
    padding: 5px 10px;
    background: none;
    box-shadow: none;
  }

  .viewContentButton:hover {
    border: none;
    color: #1b8c96;
    padding: 5px 10px;
    background: none;
    box-shadow: none;
  }
}
