.block {
  border: 1px solid #eaedf3;
  background-color: #ffffff;
  margin-left: 23px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  .content {
    max-width: 466px;
    margin: 24px;
    .heading {
      font-size: 18px;
      font-weight: 500;
      color: #3e3f42;
    }
    .date {
      margin-bottom: 10px;
      .react-datepicker-wrapper {
        display: block;
        margin-top: 5px;
        & ~ .error {
          margin-top: 5px;
          color: #e67670;
          font-size: 12px;
        }
        input.error {
          border-color: #e67670;
        }
      }
    }
  }
  .reports {
    .report-name {
      cursor: pointer;
      color: #3e3f42;
      font-size: 14px;
      margin: 10px 30px;
      font-weight: 500;
    }
  }
}
button {
  &.clear {
    border: none;
    cursor: pointer;
    background: none;
    font-size: 14px;
    color: rgb(27, 140, 150);
    font-weight: 500;
    &:focus {
      outline: none;
    }
  }
}

.passwordChangeFormContainer {
  padding: 24px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px;
}

.cancelButton {
  color: #1b8c96;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}

.submitButton {
  font-size: 14px;
}

.backContainer {
  display: flex;
  align-items: center;
  padding-top: 32px;
  padding-left: 20px;
}

.description {
  margin: 8px 0px 0px 0px;
  color: #9ea0a5;
  font-size: 12px;
}

.backLink {
  color: #8a8e96 !important;
}

.screenSubHeading {
  margin: 0px 0px 0px 11px;
  font-size: 18px;
}

.notificationsContainer {
  display: flex;
  flex-direction: column;
  margin: 20px 0px 20px 25px;
}

.allowAlertsContainer {
  margin: 40px 0px 30px 25px;
}

.toggleContainer {
  margin: 10px 0px 10px 0px;
  display: flex;
  align-items: center;
}

.notificationSubheading {
  font-size: 14px;
  line-height: 40px;
  padding-left: 20px;
  vertical-align: middle;
  color: #9ea0a5;
  text-transform: uppercase;
}
