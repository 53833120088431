.tabsContainer {
  display: flex;
  width: 100%;
}

.tab {
  padding: 15px 0px 15px 0px;
  text-align: center;
  width: 50%;
  text-transform: uppercase;
  font-weight: 500;
  color: #3e3f42;
  font-size: 12px;
  cursor: pointer;
  border-bottom: 1px solid #f5f5f5;
}

.tabActive {
  padding: 15px 0px 15px 0px;
  text-align: center;
  border-bottom: 1px solid #1b8c96;
  width: 50%;
  text-transform: uppercase;
  font-weight: 500;
  color: #1b8c96;
  font-size: 12px;
  cursor: pointer;
}

.userCounter {
  background: rgba(234, 237, 243, 1);
  border-radius: 9.5px;
  display: inline-block;
  line-height: 18px;
  margin-left: 20px;
  text-align: center;
  width: 40px;
}
