.pill {
  background: #2c3041;
  border-radius: 9.5px;
  padding: 2px 15px 2px 15px;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  height: 95%;
  margin-right: -8px;
  color: white;
}
