.versionsPanel {
  background-color: #393d50;
  color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  position: absolute;
  top: 0;
  right: 0;
  width: 320px;
  height: 100vh;
}

.version {
  padding: 0 20px;
}

.versionsList {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  overflow-y: auto;
  min-height: 0px;
  flex: 1 1 auto;
}

.versionsListHeader {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 20px;
  opacity: 1;
  background: #393d50;
  z-index: 99;
  flex: 0 1 auto;
}

.uploadNewVersion {
  flex: 0 1 auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  padding: 31px 0;
  box-sizing: border-box;
}

.uploadButton {
  background: #1b8c96;
  border: 1px solid #1b8c96;
  color: #ffff;
  width: 120px;
  margin-left: 20px;
  font-weight: 500;
  font-size: 14px;
}

.compareButton {
  background: #1b8c96;
  border: 1px solid #1b8c96;
  color: #ffff;
  width: 120px;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
}

.compareButton:focus {
  background: #1b8c96;
  border: 1px solid #1b8c96;
  color: #ffff;
  width: 120px;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
}

.compareButton:disabled {
  background: #5b5f72;
  border: 1px solid #5b5f72;
  color: #9094a8;
  width: 120px;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
}

.compareButton:disabled:hover {
  background: #5b5f72;
  border: 1px solid #5b5f72;
  color: #9094a8;
  width: 120px;
  margin-right: 20px;
  font-weight: 500;
  font-size: 14px;
}

.viewerCompareButton {
  background: #1b8c96;
  border: 1px solid #1b8c96;
  color: #ffff;
  width: 80%;
  margin-left: 0px;
  font-weight: 500;
  font-size: 14px;
}

.viewerCompareButton:hover {
  background: #5b5f72;
  border: 1px solid #1b8c96;
  color: #ffff;
  width: 80%;
  margin-left: 0px;
  font-weight: 500;
  font-size: 14px;
}

.viewerCompareButton:disabled {
  background: #5b5f72;
  border: 1px solid #5b5f72;
  color: #9094a8;
  width: 80%;
  margin-left: 0px;
  font-weight: 500;
  font-size: 14px;
}

.viewerCompareButton:disabled:hover {
  background: #5b5f72;
  border: 1px solid #5b5f72;
  color: #9094a8;
  width: 80%;
  margin-left: 0px;
  font-weight: 500;
  font-size: 14px;
}

.compareButton:hover {
  background: #1b8c96;
  border: 1px solid #1b8c96;
  color: #ffff;
  font-weight: 500;
  font-size: 14px;
}

.closeVersions {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 26px;
  float: right;
  height: 16px;
  width: 16px;
}

.sidePanelHeadingTitle {
  font-size: 24px;
  font-weight: 500;
}

.newTab {
  margin-left: 10px;
}

@media screen and (min-width: 1440px) {
  .versionsPanel {
    background-color: #393d50;
    color: #fff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 70px);
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    height: 100vh;
  }

  .uploadButton {
    background: #1b8c96;
    border: 1px solid #1b8c96;
    color: #ffff;
    width: 150px;
    margin-left: 20px;
    font-weight: 500;
    font-size: 14px;
  }

  .compareButton {
    background: #1b8c96;
    border: 1px solid #1b8c96;
    color: #ffff;
    width: 150px;
    margin-right: 20px;
    font-weight: 500;
    font-size: 14px;
  }

  .compareButton:focus {
    background: #1b8c96;
    border: 1px solid #1b8c96;
    color: #ffff;
    width: 150px;
    margin-right: 20px;
    font-weight: 500;
    font-size: 14px;
  }

  .compareButton:disabled {
    background: #5b5f72;
    border: 1px solid #5b5f72;
    color: #9094a8;
    width: 150px;
    margin-right: 20px;
    font-weight: 500;
    font-size: 14px;
  }

  .compareButton:disabled:hover {
    background: #5b5f72;
    border: 1px solid #5b5f72;
    color: #9094a8;
    width: 150px;
    margin-right: 20px;
    font-weight: 500;
    font-size: 14px;
  }
}
