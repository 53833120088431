.container {
  width: 100%;
  // height: 650px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.cardContainer {
  align-items: flex-start;
  padding: 15px 15px 0px;
  margin: 20px 20px 0px 20px;
  min-height: 137px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 10px;
  overflow: hidden;
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 5px;
}

.actionButton {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: #0e6f90;
  cursor: pointer;
}

.loaderContainer {
  height: 100%;
  margin-top: 150px;
}

.documentTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #393d50;
}

.notificationIndicator {
  margin-right: 10px;
}

.commentBody {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #393d50;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.commenterName {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #98a2b3;
  padding-bottom: 10px;
  border-bottom: 1px solid #eaecf0;
  margin-bottom: 5px;
}

.dotSeparator {
  font-size: 8px;
  margin-left: 5px;
  margin-right: 5px;
}

.commenterName {
  display: flex;
}

.closeButton {
  cursor: pointer;
}

.documentTitleSubContainer {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
