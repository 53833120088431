.selectVersionsContainer {
  width: 60%;
}

.selectVersions {
  display: flex;
  justify-content: space-between;
}

.versionSelectField {
  width: 45%;
}

.swapButton {
  cursor: pointer;
  background: #2c3041;
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 4px;
}

.container {
  background: #393d50;
  padding: 20px 0px 20px 20px;
}

.heading {
  margin-top: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;

  /* identical to box height, or 100% */

  color: #ffffff;
}

@media screen and (min-width: 1440px) {
  .selectVersionsContainer {
    width: 40%;
  }
}
