.leftSideMenuContainer {
  padding: 0px !important;
  background-color: #ffffff;
  border-top: 1px solid #eaedf3;
  height: 92vh;
}

.leftSideMenu {
  padding: 36px 16px;
}

.leftSideMenuHeader {
  color: #393d50;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 40px;
}

.countPill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  gap: 10px;

  width: 33px;
  height: 16px;

  background: #eaedf3;
  border-radius: 9.5px;
  font-family: inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: center;
}

.categoryIcon {
  margin-right: 10px;
}

.leftSideMenuItem {
  display: flex;
  justify-content: space-between;
  color: #344054;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  vertical-align: middle;
  padding: 10px;
  border-radius: 7px;
}

.leftSideMenuItem.selected {
  border-color: #f0f9ff;
  background: #e6f2f3;
  color: #1b8c96;
}

.leftSideMenuItem.header {
  color: #9ea0a5;
  text-transform: uppercase;
}

.leftSideMenuItem a {
  color: #3e3f42 !important;
  text-decoration: none;
}

.currentTeamContainer {
  display: flex;
  margin-top: 15px;
}

.currentTeamName {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 18px;
  height: 22px;
  background: #eaedf3;
  border-radius: 16px;
  color: #344054;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
  text-transform: capitalize;
}

.team {
  border-top: 1px solid #eaedf3;
  font-size: 11px;
}

.currentTeam {
  text-decoration: underline;
  font-weight: 200;
}
