.documentReferencePanel {
  position: absolute;
  background-color: #393d50;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 320px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.sidePanelOptions {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  height: calc(100% - 166px);
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.emptySidePanelOptions {
  background-color: #2c3041;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 30px;
  vertical-align: middle;
  height: calc(100vh - 155px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyStateMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.emptyStateMessageLineOne {
  margin: 20px 0px 20px 0px;
}

.sidePanelHeader {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 20px;
}

.documentReferenceIconContainer {
  background-color: #ff9c79;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.documentReferenceIcon {
  font-size: calc(28px / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(28px / 7); /* 25% of parent */
}

.closeComment {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 26px;
  float: right;
  height: 16px;
  width: 16px;
}

.button {
  margin: 10px auto 10px auto;
  position: relative;
  width: 90%;
}

.referenceLink {
  color: #eaedf3 !important;
  font-weight: normal;
}

.referenceContentUploading {
  background-color: #393d50;
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  padding: 20px 20px;
  vertical-align: middle;
  word-break: break-word;
}

.referenceCaption {
}

.referenceDocumentTitle {
  float: right;
  word-break: break-word;
}

.attachmentDocumentTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-top: 10px;
}

.referenceActionsDropDownContainer {
  float: right;
  margin-right: 15px;
  cursor: pointer;
}

.moreIcon {
  position: relative;
  top: -3px;
}

.sidePanelHeadingTitle {
  font-size: 24px;
  font-weight: 500;
}

.attachmentReferenceId {
  height: 15px;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  background: #7a5dd1;
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
}

.emptyAttachmentReferenceId {
  height: 15px;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  background: #6d7382;
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
}

.attachmentReferenceIdContainer {
  display: flex;
}

.attachmentDocumentDescription {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #eaedf3;
  margin-top: 15px;
}

.attachmentDocumentLink {
  color: rgba(91, 193, 202, 1) !important;
  font-weight: 500;
}

.attachmentCardFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.annotationCount {
  background: #2c3041;
  border-radius: 9.5px;
  padding: 0px 15px 0px 15px;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  height: 95%;
}

.annotationCount:hover ~ .descriptionBox {
  display: flex;
  position: absolute;
  top: 120%;
  left: -38%;
}
.descriptionBox {
  display: none;
}
.descriptionBoxImage {
  transform: rotate(90deg);
  left: 115px;
  top: -9px;
  position: absolute;
}
.descriptionText {
  width: 280px;
  min-height: 40px;
  background: #2c3041;
  border-radius: 4px;
  position: absolute;
  top: 5px;
  transform: translateX(-50%);
  color: #eaedf3;
  word-wrap: break-word;
  padding: 18px;
  box-sizing: border-box;
  font-size: 11px;
}
.annotationCountContainer {
  position: relative;
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
}

.filter {
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 35%;
  z-index: 1050;
  position: absolute;
}

.addReferenceDocumentButton {
  position: fixed;
  bottom: 0;
  width: 320px;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #5b5f72;

  :global(.button-primary) {
    width: 280px;
  }
}

@media screen and (min-width: 1440px) {
  .addReferenceDocumentButton {
    position: fixed;
    bottom: 0;
    width: 400px;
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #5b5f72;

    :global(.button-primary) {
      width: 350px;
    }
  }
  .documentReferencePanel {
    position: absolute;
    background-color: #393d50;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 400px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
