.container {
  .header {
    padding: 0 26px 14px;
    border-bottom: 1px solid #5b5f72;
    display: flex;
    justify-content: space-between;

    .title {
      color: #dccaa9;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
    }

    .collapseExpandButton {
      cursor: pointer;
    }
  }
}
