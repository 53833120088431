.tooltip-container {
  position: relative;
  .tooltip {
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .tooltip-dimple {
    position: absolute;
    top: -5px;
    left: 22px;
    &.hidden {
      display: none;
    }
  }
  .tooltip-dimple-description {
    position: absolute;
    top: -18px;
    left: 4px;
    transform: rotate(-90deg);
    &.hidden {
      display: none;
    }
  }

  .approved-dimple {
    position: absolute;
    top: 10px;
    left: 4px;
    transform: rotate(90deg);
    &.hidden {
      display: none;
    }
  }

  .content {
    position: absolute;
    top: 0;
    left: 30px;
    background-color: black;
    transform: translateY(-45%);
    border-radius: 8px;
    z-index: 1;
    padding: 10px;
    margin: 24px 24px 24px 0px !important ;
    &.hidden {
      display: none;
    }

    .referenceIdDescription {
      color: white;
    }

    .list {
      list-style-type: none;
      font-size: 12px;
      .item {
        &-allowed {
          font-weight: 600;
          color: white;
        }
        &-disallowed {
          color: #cccccc;
        }
        display: flex;
        align-items: center;
        margin: 8px;
        .text {
          margin-left: 4px;
          white-space: nowrap;
        }
      }
    }
  }
}
.descriptionContent {
  width: 350px;
  color: white;
  position: absolute;
  left: 30px;
  background: #2c3041;
  transform: translateX(-25%);
  top: -206px;
  border-radius: 8px;
  z-index: 1;
  padding: 10px;
  margin: 24px 24px 24px 0px !important ;
  padding: 20px 20px 20px 20px;
  &.hidden {
    display: none;
  }
}

.autoApproveDescriptionContent {
  width: 406px;
  color: white;
  position: absolute;
  left: 30px;
  background: #2c3041;
  transform: translateX(-25%);
  top: -187px;
  border-radius: 8px;
  z-index: 1;
  padding: 10px;
  margin: 24px 24px 24px 0px !important ;
  padding: 20px 30px 20px 30px;
  &.hidden {
    display: none;
  }
}

.approvedContent {
  width: 260px;
  color: white;
  position: absolute;
  left: -180px;
  background: #2c3041;
  transform: translateX(-25%);
  top: 2px;
  border-radius: 8px;
  z-index: 1;
  padding: 10px;
  margin: 24px 24px 24px 0px !important;
  padding: 20px 20px 20px 20px;
  &.hidden {
    display: none;
  }
}

.descriptionText {
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.kbLink {
  color: #1b8c96;
  display: "table-cell";
}

.kbLinkSequential {
  color: #1b8c96;
  display: "table-cell";
  margin-left: 10px;
}

.headerContainer {
  display: flex;
  align-content: center;
  align-items: center;
  margin: 8px 0px;
  justify-content: space-between;
}

.header {
  margin: 0;
}

.closeButton {
  font-size: 31px;
  cursor: pointer;
}

.externalLinkIcon {
  margin-left: 10px;
}

.sequentialDescriptionContent {
  width: 406px;
  color: white;
  position: absolute;
  left: 30px;
  background: #2c3041;
  transform: translateX(-25%);
  top: -158px;
  border-radius: 8px;
  z-index: 1;
  padding: 10px;
  margin: 24px 24px 24px 0px !important ;
  padding: 20px 30px 20px 30px;
  &.hidden {
    display: none;
  }
  .headerContainer {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin: 0px;
    .header {
      line-height: 0px;
    }
  }
  .descriptionText {
    text-transform: none;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin: 0px 0px 5px 0px;
  }
}

.autoApproveDescriptionContent {
  width: 406px;
  color: white;
  position: absolute;
  left: 30px;
  background: #2c3041;
  transform: translateX(-25%);
  top: -158px;
  border-radius: 8px;
  z-index: 1;
  padding: 10px;
  margin: 24px 24px 24px 0px !important ;
  padding: 20px 30px 20px 30px;
  &.hidden {
    display: none;
  }
  .headerContainer {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin: 0px;
    .header {
      line-height: 0px;
    }
  }
  .descriptionText {
    text-transform: none;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin: 0px 0px 5px 0px;
  }
}
