.library_select {
  margin-bottom: 10px;
  &-error {
    div {
      div {
        border-color: #e6492d;
      }
    }
  }
}
