.referencePanel {
  color: #fff;
  background-color: #393d50;
  z-index: 2000;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100vh;
}

.linkingReferenceContainer {
  height: 100%;
  width: 320px;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 1;
  position: fixed;
  right: 0;
  bottom: 0;
}

.linkingReference {
  color: #fff;
  background: rgb(57, 61, 80);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 31px);
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  animation-duration: 0.5s;
  animation-name: slideInUp;
}

.sidePanelOptions {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  height: calc(100% - 355px);
  overflow: auto;
  padding: 254px 0 101px;
}

.sidePanelOptionsDocRefs {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  height: calc(100vh - 164px);
  overflow: auto;
}

.emptySidePanelOptions {
  background-color: #2c3041;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 30px;
  vertical-align: middle;
  height: calc(100vh - 180px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyStateMessage {
  margin: 20px 0px 20px 0px;
  font-size: 13px;
}

.editControlsContainer {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  width: 320px;
  padding: 30px 0px 30px 0px;
}

.applyChangesButton {
  width: 50%;
}

.applyChangesButton:disabled {
  width: 50%;
  background: #d9d9d9 !important;
  color: white !important;
  border: none !important;
}

.cancelEditButton {
  background: #393d50;
  color: #ffffff;
  width: 35%;
}

.cancelButton {
  margin-right: 10px;
}

.cancelEditButton:hover {
  background: #393d50;
  color: #ffffff;
  width: 35%;
}

.referencesContainer {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  height: calc(100vh - 65px - 100px);
  overflow: scroll;
}

.sidePanelHeader {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 20px;
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #2c3041;
  margin: 28px;
  height: calc(100% - 311px);
}

.emptyStateContainerAnnotations {
  :global(.button-primary) {
    width: 205px;
  }
}

.referenceIconContainer {
  background-color: #7a6a8d;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.referenceIcon {
  font-size: calc(28px / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(28px / 7); /* 25% of parent */
}

.closeComment {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 22px;
  float: right;
  height: 16px;
  width: 16px;
}

.referenceContentUploader {
  background-color: #464b5b;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  vertical-align: middle;
}

.referenceContentUploaderBox {
  background-color: #464b5b;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 25px;
  cursor: pointer;
  vertical-align: middle;
}

.referenceContentUploading {
  margin: 20px auto;
  width: 90%;
  padding: 0 5px 20px;
  word-break: break-word;
  cursor: pointer;
  background-color: #393d50;
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  box-sizing: border-box;
}

.topContainer {
  display: flex;
  justify-content: space-between;
}

.deleteContainer {
  display: flex;
  align-items: center;
  color: #207a87;
  cursor: pointer;
}

.trashContainer {
  margin-right: 5px;
}

.documentReferenceCard {
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  width: 90%;
  padding: 0 5px 20px;
  word-break: break-word;
  background-color: #393d50;
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  box-sizing: border-box;
}

.documentReferenceCardSubContainer {
  width: 100%;
}

.attachmentDocumentLink {
  color: rgba(91, 193, 202, 1) !important;
  font-weight: 500;
}

.referenceLink {
  color: #eaedf3 !important;
  font-weight: normal;
}

.uploaderIcon {
  color: #9ea0a5;
  display: inline-block;
  line-height: 22px;
  margin: 10px;
}

.uploaderIconCheck {
  color: #15cd93;
  display: inline-block;
  line-height: 22px;
  margin: 10px;
}

.uploaderPlaceholder {
  color: #9ea0a5;
  font-size: 14px;
  line-height: 22px;
}

.uploaderButton {
  float: right;
}

.uploadProgress {
  height: 4px;
  width: 100%;
  border-radius: 2px;
}

.uploadingFilename {
  color: #eaedf3;
  margin-bottom: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.warning {
  height: 20px;
  width: 20px;
  margin-right: 9px;
}

.errorBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 258px;
  height: 38px;
  border-radius: 4px;
  background-color: #464a5c;
}

.errorMessage {
  font-size: 12px;
  color: #bac0cb;
}

.referenceDocumentTitle {
  float: right;
  word-break: break-word;
}

.referenceActionsDropDownContainer {
  float: right;
  margin-right: 15px;
  cursor: pointer;
}

.moreIcon {
  position: relative;
  top: -3px;
}

.searchInputField {
  margin: 0 auto;
  margin-top: 22px;
  background-color: #2c3041;
  border: none;
  color: #8a8e96;
  &::placeholder {
    color: #8a8e96;
    background-image: url("../../images/icons/svg/search.svg");
    background-repeat: no-repeat;
    background-position: right;
  }
}

.newReferenceHeader {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.46px;
  color: #cabdf6;
  text-transform: uppercase;
}

.chooseDocumentHeader {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  padding-bottom: 26px;
  font-size: 24px;
  font-weight: 500;
  margin-top: 8px;
}

.chooseDocumentTextNew {
  font-size: 24px;
  font-weight: 500;
}

.backArrow {
  margin-right: 10px;
  cursor: pointer;
}

.newReferenceSidePanelHeader {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 5%;
  position: absolute;
  border-top-left-radius: 14px;
  padding: 16px 0 0 5%;
  width: 90%;
  z-index: 2;
  background: rgba(57, 61, 80, 0.85);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.newReferenceLinkContainer {
  background-color: #393d50;
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  margin: 0 auto;
  vertical-align: middle;
  word-break: break-word;
  padding: 15px 3% 15px;
  width: 88%;
}

.addReferenceButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 320px;
  bottom: 0;
  background-color: #393d50;
  height: 100px;
  border-top: 1px solid #5b5f72;
  :global(.button-primary) {
    width: 280px;
  }
}

.addReferenceButtonNew {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  bottom: 0;
  background-color: #393d50;
  height: 100px;
  box-shadow: 0px 0px 0px #000, 0px -5px 5px rgba(50, 50, 50, 0.45);
  :global(.button-primary) {
    width: 90%;
  }
}

.addReferenceDocumentButtonContainer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  background: rgba(57, 61, 80, 0.85);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  width: 90%;
  margin-right: 5%;
  padding-left: 5%;
  z-index: 2;

  .addReferenceDocumentButton {
    height: 100%;
    display: flex;
    border-top: 1px solid #5b5f72;
    align-items: center;
    width: 100%;

    :global(.button-primary) {
      width: 100%;
    }
  }
}

.attachmentReferenceIdContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attachmentReferenceAnnotIdContainer {
  display: flex;
  justify-content: space-between;
}

.attachmentDocumentTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-top: 10px;
}

.attachmentDocumentDescription {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #eaedf3;
  margin-top: 15px;
}

.attachmentCardFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.attachmentDocumentLink {
  color: rgba(91, 193, 202, 1) !important;
  font-weight: 500;
}

.attachmentReferenceId {
  height: 15px;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  background: #7a5dd1;
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
  max-width: calc(100% - 20px);
  position: relative;

  &:hover {
    cursor: default;
    .fullLabelsContainer {
      display: block;
    }
  }
}

.emptyAttachmentReferenceId {
  height: 15px;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  background: #6d7382;
  border-radius: 30px;
  padding: 5px 10px 5px 10px;
}

.referenceDocumentLabel {
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;
}

.referenceLabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 15%;
}

.referenceCaption {
  margin-top: 10px;
  color: white;
}

.linkingReferenceCaption {
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  &Search {
    display: block;
    -webkit-box-orient: initial;
    overflow: initial;
  }
}

.fullLabelsContainer {
  display: none;
}

.fullLabels {
  background-color: #2c3041;
  position: absolute;
  bottom: 29px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  padding: 3px 10px;
  width: 100%;
  word-wrap: break-word;
  text-align: center;
}

.bottomArrow {
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  top: -4px;
  border-top: 3px solid #2c3041;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

.annotationCountContainer {
  position: relative;
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
}

.countContainer {
  position: relative;
  min-width: 100px;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.annotationCount {
  background: #2c3041;
  border-radius: 9.5px;
  padding: 0px 15px 0px 15px;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  height: 95%;
}

.annotationCountLinkScreen {
  background: #2c3041;
  border-radius: 9.5px;
  padding: 0px 15px 0px 15px;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  position: absolute;
  right: 0;
  height: 95%;
}

.annotationCount:hover ~ .descriptionBox {
  display: flex;
  position: absolute;
  top: 120%;
  left: -38%;
  z-index: 1;
}
.descriptionBox {
  display: none;
}
.descriptionBoxImage {
  transform: rotate(90deg);
  left: 115px;
  top: -9px;
  position: absolute;
}
.descriptionText {
  width: 280px;
  min-height: 40px;
  background: #2c3041;
  border-radius: 4px;
  position: absolute;
  top: 5px;
  transform: translateX(-50%);
  color: #eaedf3;
  word-wrap: break-word;
  padding: 18px;
  box-sizing: border-box;
  font-size: 11px;
}

.searchInputContainer {
  border-top: 1px solid rgba(234, 237, 243, 0.2);
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  padding: 0px 3% 19px 3%;
}

.stageOneDetailsContainer {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  width: 90%;
  position: absolute;
  z-index: 5000;
  margin: 73px 5% 0 0;
  padding-left: 5%;
  background: rgba(57, 61, 80, 0.85);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.stageTwoDetailsContainer {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  width: 90%;
  position: absolute;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 5000;
  margin: 73px 5% 0 0;
  padding-left: 5%;
  background: rgba(57, 61, 80, 0.85);
}

.stepsBlock {
  position: relative;
}

.statusContainer {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  width: 88%;
  padding: 20px 3% 20px 0%;
  display: flex;
}

.statusSteps {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stepOneTextDisabled {
  margin: 0px 0px 0px 10px;
}

.stepOneTextActive {
  color: #5bc1ca !important;
  margin: 0px 0px 0px 10px;
}

.stepTwoTextDisabled {
  margin: 0px 0px 0px 10px;
}

.stepTwoTextActive {
  color: #5bc1ca !important;
  margin: 0px 0px 0px 10px;
}

.stepIconContainer {
  display: flex;
  flex-direction: column;
  height: 60px;
}

.line {
  margin: 3px 0px 3px 0px;
  height: 12px;
}

.pageNumber {
  margin: 10px auto 0;
  color: #dccaa9;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  text-transform: uppercase;
  padding-bottom: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  width: 90%;
}

.referencesContainer {
  height: calc(100% - 265px) !important;
  overflow-y: auto;
  scrollbar-color: rgb(157, 168, 181) rgb(50, 53, 68);
  scrollbar-width: thin;
  padding-bottom: 20px;
  .dividerContainer {
    margin: 0 30px;
    border-top: 1px solid #5b5f72;
  }
  .referenceCaption {
    margin: 0px 42px 22px;
    color: white;
    font-weight: 500;
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .referenceLinks {
    margin-left: 42px;
    display: flex;
    color: #bac0cb;
    font-weight: 600;
    font-size: 12px;
    div {
      margin-left: 4px;
    }
    margin-bottom: 16px;
  }
}

.referenceCard {
  margin: 20px auto 0;
  padding: 0px 10px 15px 10px;
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  width: 90%;
  box-sizing: border-box;
}

.linkAnnotation {
  color: #5bc1ca !important;
  cursor: pointer;
}

.documentReferencesContainer {
  animation-duration: 3s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    -webkit-transform: translate3d(400%, 0, 0);
    transform: translate3d(400%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.highlight {
  background-color: #fff104;
  color: black;
}

.resultsHeader {
  color: #dccaa9;
  font-weight: 600;
  font-size: 12px;
  padding: 12px 10px;
  border-bottom: 1px solid #5b5f72;
  width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
}

.referenceDocumentMenu {
  margin-left: 10px;
}

.referencesContainer::-webkit-scrollbar-track {
  background-color: #2e313f;
}

.referencesContainer::-webkit-scrollbar {
  width: 8px;
}

.referencesContainer::-webkit-scrollbar-thumb {
  background-color: #868e96;
  border-radius: 8px;
}

// Mozilla firefox specific styling for the input placeholder

@-moz-document url-prefix() {
  .searchInputField {
    &::placeholder {
      background-position-y: 50% !important;
      background-position-x: 96.5% !important;
    }
  }

  .newReferenceSidePanelHeader,
  .addReferenceDocumentButtonContainer,
  .stageOneDetailsContainer,
  .stageTwoDetailsContainer {
    background: rgba(57, 61, 80, 0.97);
  }
}

.pinIcon {
  position: absolute;
  top: 10px;
}

.pindropCaptionContainer {
  display: flex;
  flex-direction: row;
  width: 90%;
  position: relative;
}

.sidePanelHeadingTitle {
  font-size: 19px;
  font-weight: 500;
}

.pinReferenceCaption {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 22px;
  margin: 10px 0px 10px 20px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (min-width: 1440px) {
  .editControlsContainer {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    width: 400px;
    padding: 30px 0px 30px 0px;
  }

  .sidePanelHeadingTitle {
    font-size: 24px;
    font-weight: 500;
  }

  .referencePanel {
    color: #fff;
    background-color: #393d50;
    z-index: 2000;
    position: fixed;
    top: 0;
    right: 0;
    width: 400px;
    height: 100vh;
  }
  .addReferenceButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 400px;
    bottom: 0;
    background-color: #393d50;
    height: 100px;
    border-top: 1px solid #5b5f72;
    :global(.button-primary) {
      width: 350px;
    }
  }
  .linkingReferenceContainer {
    height: 100%;
    width: 400px;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 1;
    position: fixed;
    right: 0;
    bottom: 0;
  }
}
