.libraryEditContainer {
  background-color: #fbfbfb;
  margin-top: 30px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 5px;
}

.headerContainer {
  padding: 24px 13px 22px 30px;

  .header {
    color: #3e3f42;
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 28px;
  }

  .description {
    font-size: 12px;
    color: #9ea0a5;
    font-weight: 500;
    line-height: 22px;
  }
}

.bodyContainer {
  padding: 28px 30px 35px;

  .contentPropertiesTitle {
    text-transform: uppercase;
    font-size: 12px;
    color: #9ea0a5;
    font-weight: 600;
  }

  .libraryName {
    margin-bottom: 22px;
  }
}

.contentPropertiesList {
  margin-top: 11px;
}

.contentProperty {
  display: flex;
}

.contentProperty :global(.uik-checkbox__wrapper) {
  margin-top: 17px;
}

.contentPropertyDetails {
  margin-left: 20px;
  border: 1px solid #d6dce8;
  width: 100%;
  padding: 11px 86px 11px 20px;
}

.contentProperty:first-child .contentPropertyDetails {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: none;
}

.contentProperty:last-child .contentPropertyDetails {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.contentProperty:only-child .contentPropertyDetails {
  border-bottom: 1px solid #d6dce8;
}

.contentPropertyLabel {
  color: #393d50;
  font-size: 14px;
  font-weight: 500;
}

.contentPropertyFields {
  color: #9ea0a5;
  font-size: 14px;
  margin-top: 15px;
}

.buttonsContainer {
  padding: 30px;
  display: flex;
  justify-content: space-between;

  :global(.button-primary) {
    width: 150px;

    &:nth-of-type(2) {
      border: 1px solid #ba0b15;
      background-color: #e1391b;
    }
  }
}

.lockedContentProperty {
  font-size: 14px;
  margin-top: 10px;

  .lockedContentPropertyLabel {
    display: inline;
    font-weight: 500;
  }

  .lockedContentPropertyFields {
    display: inline;
  }
}
