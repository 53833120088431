.authentication-screen {
  background-image: linear-gradient(
    124deg,
    #1b8c96,
    #9decf3 53%,
    #b1edf3 71%,
    #1b8c96
  );
  min-height: 100vh;
}

.authentication-header {
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  height: 70px;
  margin-bottom: 50px;
  text-align: middle;
}

.authentication-logo {
  margin: 23px 0 0 30px;
}

.authentication-button {
  background: #1b8c96 !important;
  background-color: #1b8c96 !important;
  border: #1b8c96 !important;
}

.authentication-error-message {
  background-color: #be3c25;
  color: #fff;
  font-size: 14px;
  margin: 0 0 20px 0;
  padding: 15px 30px 15px 50px;

  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.authentication-login-form {
  background-color: #fff;
  min-width: 200px;
  padding: 30px;

  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.authentication-login-form .authentication-forgot-password {
  font-size: 14px;
  margin: 20px 0 0 0;
  text-align: center;
}

.authentication-login-form .authentication-forgot-password a {
  font-size: 14px;
  color: #6b6c6f !important;
  text-decoration: none !important;
}

.authentication-login-form h2 {
  color: #393d50;
  margin: 0 0 20px 0;
}

.authentication-login-image {
  text-align: right;
}

.hs-chat-container {
  float: right;
  margin-top: 16px;
  margin-right: 30px;
}
