.passwordCreateScreen {
  background-image: linear-gradient(
    124deg,
    #1b8c96,
    #9decf3 53%,
    #b1edf3 71%,
    #1b8c96
  );
  min-height: 100vh;
  padding: 0 !important;
  margin: 0 !important;
}

.authenticationHeader {
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  height: 70px;
  margin-bottom: 50px;
  text-align: middle;
}

.authenticationLogo {
  margin: 23px 0 0 23px;
}

.passwordCreateLoginForm {
  background-color: #fff;
  min-width: 200px;
  padding: 30px;

  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.passwordCreateLoginForm h2 {
  color: #393d50;
  margin: 0 0 20px 0;
}

.passwordCreateLoginImage {
  text-align: right;
}

.passwordCreateErrorMessage {
  background-color: #be3c25;
  color: #fff;
  font-size: 14px;
  margin: 0 0 20px 0;
  padding: 15px 30px 15px 50px;

  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.authenticationForgotPassword {
  font-size: 14px;
  margin: 20px 0 0 0;
  text-align: center;
}

.authenticationForgotPassword a {
  font-size: 14px;
  color: #6b6c6f !important;
  text-decoration: none !important;
}
