.container {
  background: rgba(43, 46, 61, 0.76);
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  z-index: 100;

  .modal {
    height: 45%;
    min-height: 300px;
    bottom: 0;
    right: 0;
    position: fixed;
    width: 320px;
    background: #393d50;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    color: #fff;
    display: flex;
    flex-direction: column;

    .header {
      padding: 29px 27px;
      border-bottom: 1px solid #5b5f72;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 24px;
        font-weight: 500;
      }

      .closeButton {
        display: flex;
        img {
          cursor: pointer;
        }
      }
    }

    .body {
      padding: 20px;
      flex-grow: 1;

      .labelInput {
        color: #dccaa9;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;

        .text {
          margin-bottom: 10px;
        }

        input {
          background: #2c3041;
          border-radius: 4px;
          border: none;
          outline: none;
          color: #8a8e96;
          padding: 10px 15px;
          width: 100%;
          box-sizing: border-box;
        }
      }
    }

    .button {
      height: 100px;
      padding: 30px 20px;
      box-sizing: border-box;
      border-top: 1px solid #5b5f72;

      :global(.button-primary) {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .container,
  .modal {
    width: 400px !important;
  }
}
