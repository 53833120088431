.emailsScreen {
  min-height: 100vh;
}

.emailsTableContainer {
  margin: 20px;
}

.emailsHeaderControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  margin-bottom: 0px;
}

.bounced {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 18px;
  height: 22px;
  background: #fff1f3;
  border-radius: 16px;
  color: #c01048;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  justify-content: center;
  text-transform: capitalize;
  width: 132px;
}

.cancelled {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 18px;
  height: 22px;
  background: #fff1f3;
  border-radius: 16px;
  color: #c01048;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  justify-content: center;
  text-transform: capitalize;
  width: 132px;
}

.pending,
.processing,
.wait {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 18px;
  height: 22px;
  background: #eaedf3;
  border-radius: 16px;
  color: #344054;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
  text-transform: capitalize;
  width: 132px;
}

.delivered {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 18px;
  height: 22px;
  background: #f5fefd;
  border-radius: 16px;
  color: #1d9791;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
  text-transform: capitalize;
  width: 132px;
}

.recipientName {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
