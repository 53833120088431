.documentContainer {
  border: 1px solid #eaedf3;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  width: 250px;
  margin: 15px;
  display: inline-block;
  float: left;
  overflow: hidden;
  cursor: pointer;
}

.documentContainerDark {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #2c3041;
  width: 250px;
  height: 294px;
  margin: 15px;
  display: inline-block;
  float: left;
  overflow: hidden;
  cursor: pointer;
}

.documentContainer:hover {
  border-color: #d4d9e2;
  box-shadow: 6px 6px 20px 4px #ececec;
  opacity: 1;
}

.documentContainerDark:hover {
  box-shadow: 6px 6px 20px 4px #2c3041;
  opacity: 1;
}

.documentContainer:hover .thumbnail {
  transform: scale(1.05);
  background-color: #fbfbfb;
}
.documentContainer:hover .nothumbnail {
  transform: scale(1.08);
  background-color: #fbfbfb;
}

.heading {
  color: #3e3f42 !important;
  padding: 15px 15px 0px 15px;
  width: 220px;
  height: 45px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.headingText {
  width: 95%;
  display: block;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.closeFailedReference {
  color: rgba(57, 61, 80, 1);
  cursor: pointer;
  font-size: 32px;
  float: right;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 5%;
  right: 5%;
}

.loaderHeading {
  color: #3e3f42 !important;
  padding: 15px 15px 0px 15px;
  width: 220px;
  height: 45px;
  overflow: hidden;
  background-color: #d3d4d9;
  display: flex;
  flex-direction: row;
}

.createdTime {
  position: static;
  bottom: 0px;
  padding-top: 5px;
  float: none;
  clear: none;
  color: #9ea0a5;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.annotationCount {
  margin-left: 7px;
}

.progressBar {
  display: flex;
  justify-content: center;
  background-color: #d3d4d9;
}

.progressBarError {
  display: flex;
  background-color: white;
}

.docInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
}

.thumbnail {
  background-color: #fbfbfb;
  display: block;
  width: 250px;
  height: 210px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-size: cover !important;
  transform: scale(1);
  transition: all 0.3s;
  animation: opac 0.8s;
}
@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.errorMessage {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #e67670;
}

.warning {
  height: 15px;
  margin-right: 5px;
}

.errorBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px 10px 15px;
}

.documentLabelContainer {
  padding: 20px 20px 0;
  box-sizing: border-box;
  max-width: 100%;
  display: inline-block;
  position: relative;

  .documentLabel {
    color: white;
    background: #6955b1;
    padding: 5px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 22px;
    font-weight: 600;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.5px;
    border-radius: 30px;
    box-sizing: border-box;
  }

  .fullLabelsContainer {
    display: none;
  }
  .fullLabels {
    background-color: #2c3041;
    color: white;
    font-weight: 600;
    font-size: 10px;
    position: absolute;
    top: 46px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    padding: 3px 10px;
    width: 100%;
    word-wrap: break-word;
    box-sizing: border-box;
    z-index: 1;
  }

  .bottomArrow {
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    top: 43px;
    border-bottom: 3px solid #2c3041;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
  }

  &:hover {
    .fullLabelsContainer {
      display: block;
    }
  }
}

.emptyDocumentLabelContainer {
  padding: 20px 20px 0;
  box-sizing: border-box;
  max-width: 100%;
  display: inline-block;

  .documentLabel {
    color: white;
    background: #6d7382;
    padding: 5px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 22px;
    font-weight: 600;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.5px;
    border-radius: 30px;
    box-sizing: border-box;
  }
}
