.conversation {
  padding: 15px 6%;
  margin: 0 auto;
  background: #2c3041;
  border-radius: 10px;
  margin-top: 20px;

  .dotSeparator {
    font-size: 4px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .metadata {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftContainer {
      display: flex;

      .commentSVG {
        width: 16px;
      }
      .numberComments {
        font-size: 12px;
        margin-left: 4px;
        margin-right: 4px;
        color: #d6dce8;
        display: flex;
        align-items: center;
      }
    }
    .rightContainer {
      display: flex;

      .noCommentContainer {
        display: flex;
        font-size: 10px;
        align-items: center;
        .arrowRight {
          display: flex;
          margin-left: 10px;
        }
      }
      .checkmarkContainer {
        position: relative;
        .toggleDisabledContainer {
          display: none;
          font-size: 12px;

          .disabledText {
            position: absolute;
            background-color: black;
            top: -28px;
            right: 0;
            white-space: nowrap;
            border-radius: 2px;
            padding: 3px 10px;
            color: white;

            .alignDot {
              line-height: 4px;
              vertical-align: middle;
            }
          }

          .bottomArrow {
            position: absolute;
            width: 0;
            height: 0;
            left: 50%;
            transform: translateX(-50%);
            top: -7px;
            border-top: 3px solid black;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
          }
        }
      }

      .checkmarkContainer:hover .toggleDisabledContainer {
        display: block;
      }
    }
  }

  .claimCaption {
    margin-top: 10px;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
  }
}

.typeIndicator {
  width: 14px;
  margin-right: 10px;
}

.labelContainer {
  background: #247c57;
  border-radius: 9.5px;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  color: rgba(224, 255, 219, 1);
  padding: 2.5px 15px 2.5px 15px;
}

.nameDate {
  display: flex;
  font-family: Montserrat;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(186, 192, 203, 1);
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
