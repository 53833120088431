.container {
  width: 100%;
  min-width: 500px;
  background: white;
  border-radius: 4px;
  border: 1px solid #d6dce8;
  position: absolute;
  top: 42px;
  z-index: 2;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 15px;
  max-height: 80vh;
  overflow: auto;
  scrollbar-color: #d6dce8 #f3f5f9;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d6dce8;
    border-radius: 8px;
  }

  .emptyState {
    margin-left: 20px;
    margin-bottom: 20px;
    color: #3e3f42;
    font-weight: 500;
    padding-top: 20px;
  }

  .nonEmptyState {
    margin-bottom: 20px;
    .section {
      .link {
        .recordContainer {
          padding: 5px 20px 5px 20px;
          display: flex;
          align-items: center;

          .logo {
            display: flex;
          }

          .title {
            margin-left: 6px;
            color: #3e3f42;
            font-weight: 500;
            font-size: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .recordContainer:hover {
          padding: 5px 20px 5px 20px;
          background: #deebff;
          display: flex;
          align-items: center;

          .logo {
            display: flex;
          }

          .title {
            margin-left: 6px;
            color: #3e3f42;
            font-weight: 500;
            font-size: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        cursor: pointer;
      }
      .viewMore {
        color: #1b8c96;
        font-weight: 500;
        font-size: 12px;
        margin-left: 20px;
        margin-top: 15px;
        cursor: pointer;
      }

      &:last-of-type {
        .viewMore {
          margin-bottom: 0;
        }
      }
    }
  }
}

.filtersContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.searchOptions {
}

.searchSubheading {
  margin-left: 3.8%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;

  /* identical to box height, or 200% */
  text-transform: uppercase;

  color: #b7bec8;
}

.searchDescriptor {
  margin-top: 20px;
  margin-left: 3.8%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  /* identical to box height, or 150% */

  color: #6b6c6f;
}

.filterButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 25%;
  border-radius: 4px;
  border: 1px solid #1b8c96;
  font-weight: 500;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  background: #f7fbfc;
  color: #1b8c96;
}

.filterButtonInactive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 25%;
  font-weight: 500;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  background: transparent;
  color: #1b8c96;

  color: #3e3f42;
  background-color: white;
  border: 1px solid #e2e5ed;
  border-radius: 4px;
}

.highlight {
  background-color: #fff0bd;
  color: black;
}

.documentDetails {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #8e9ab3;
  margin-left: 6px;
}

.documentMetadata {
  display: flex;
}
