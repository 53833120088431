.conversationsPanel {
  background-color: #393d50;
  color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed !important;
  top: 0;
  right: 0;
  width: 320px;
  overflow-y: auto;

  .header {
    margin: 0 auto;
    padding: 16px 16px 16px 16px;
    width: 88%;

    .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftContainer {
        .title {
          font-size: 24px;
          font-weight: 500;
          display: flex;
          align-items: center;
        }
      }

      .rightContainer {
        display: flex;
        align-items: center;

        .sortContainer {
          margin-right: 10px;
          position: relative;

          .sortButton {
            cursor: pointer;
            display: flex;
            height: 20px;
            width: 20px;
          }

          .sortOptions {
            position: absolute;
            width: 150px;
            background-color: white;
            right: 0;
            box-shadow: 0 6px 22px 0 rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            z-index: 1;

            .option {
              cursor: pointer;
              display: flex;
              align-items: center;
              text-transform: capitalize;
              height: 44px;
              box-sizing: border-box;
              padding: 11px 20px;
              color: #393d50;
              font-size: 14px;
              font-weight: 500;

              &.optionSelected {
                color: #1b8c96;
                justify-content: space-between;

                .checkmark {
                  display: flex;
                }
              }
            }

            & > .option:not(:last-child) {
              border-bottom: 1px solid #dddddd;
            }
          }
        }

        .closeButton {
          display: flex;
          align-items: center;
          cursor: pointer;

          .closeIcon {
            height: 16px;
            width: 16px;
          }
        }
      }
    }

    .searchInputContainer {
      display: none !important;
      .searchInputField {
        margin: 0 auto;
        margin-top: 22px;
        background-color: #2c3041;
        border: none;
        color: #8a8e96;
        &::placeholder {
          color: #8a8e96;
          background-image: url("../../../../images/icons/svg/search.svg");
          background-repeat: no-repeat;
          background-position: top right;
        }
      }
    }
  }

  .editModeHeader {
    margin: 0 auto;
    padding: 16px 16px 16px 16px;
    width: 88%;

    .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftContainer {
        .title {
          font-size: 24px;
          font-weight: 500;
          display: flex;
          align-items: center;
        }
      }

      .rightContainer {
        display: flex;
        align-items: center;

        .sortContainer {
          margin-right: 10px;
          position: relative;

          .sortButton {
            cursor: pointer;
            display: flex;
            height: 20px;
            width: 20px;
          }

          .sortOptions {
            position: absolute;
            width: 150px;
            background-color: white;
            right: 0;
            box-shadow: 0 6px 22px 0 rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            z-index: 1;

            .option {
              cursor: pointer;
              display: flex;
              align-items: center;
              text-transform: capitalize;
              height: 44px;
              box-sizing: border-box;
              padding: 11px 20px;
              color: #393d50;
              font-size: 14px;
              font-weight: 500;

              &.optionSelected {
                color: #1b8c96;
                justify-content: space-between;

                .checkmark {
                  display: flex;
                }
              }
            }

            & > .option:not(:last-child) {
              border-bottom: 1px solid #dddddd;
            }
          }
        }

        .closeButton {
          display: flex;
          align-items: center;
          cursor: pointer;

          .closeIcon {
            height: 16px;
            width: 16px;
          }
        }
      }
    }

    .searchInputContainer {
      display: none !important;
      .searchInputField {
        margin: 0 auto;
        margin-top: 22px;
        background-color: #2c3041;
        border: none;
        color: #8a8e96;
        &::placeholder {
          color: #8a8e96;
          background-image: url("../../../../images/icons/svg/search.svg");
          background-repeat: no-repeat;
          background-position: top right;
        }
      }
    }
  }
  .body {
    height: calc(100vh - 49px);
    overflow: auto;
    .page {
      .dividerContainer {
        border-top: 1px solid #5b5f72;
      }
      .title {
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0 auto;
        color: #dccaa9;
        font-size: 12px;
        font-weight: 600;
        margin-top: 10px;
        margin-left: 6%;
        display: flex;
        align-items: center;
      }
      .emptyMessage {
        height: 100%;
        width: 88%;
        background-color: #2c3040;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0px 20px 0px;
        border-radius: 4px;
        margin: 20px 0px 20px 0px;
      }
      .messageContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .conversation {
        width: 88%;
        margin: 0 auto;
        cursor: pointer;

        .dotSeparator {
          font-size: 4px;
        }

        .metadata {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .leftContainer {
            display: flex;

            .commentSVG {
              width: 16px;
            }
            .nameDate {
              display: flex;
              align-items: center;
              .name {
                font-weight: 500;
                margin-left: 4px;
                margin-right: 4px;
                font-size: 13px;
              }
              .date {
                margin-left: 4px;
                font-size: 12px;
                color: #d6dce8;
                position: relative;

                .fullDateTimeContainer {
                  display: none;

                  .fullDateTime {
                    position: absolute;
                    background-color: black;
                    top: -25px;
                    left: 50%;
                    transform: translateX(-50%);
                    white-space: nowrap;
                    border-radius: 2px;
                    padding: 3px 10px;
                    color: white;
                  }

                  .bottomArrow {
                    position: absolute;
                    width: 0;
                    height: 0;
                    left: 50%;
                    top: -4px;
                    border-top: 3px solid black;
                    border-left: 3px solid transparent;
                    border-right: 3px solid transparent;
                  }
                }
              }
              .date:hover .fullDateTimeContainer {
                display: block;
              }
            }
            .numberComments {
              font-size: 12px;
              margin-left: 4px;
              margin-right: 4px;
              color: #d6dce8;
              display: flex;
              align-items: center;
            }
          }
          .rightContainer {
            display: flex;

            .noCommentContainer {
              display: flex;
              font-size: 10px;
              align-items: center;
              .arrowRight {
                display: flex;
                margin-left: 10px;
              }
            }
            .checkmarkContainer {
              position: relative;
              .toggleDisabledContainer {
                display: none;
                font-size: 12px;

                .disabledText {
                  position: absolute;
                  background-color: black;
                  top: -28px;
                  right: 0;
                  white-space: nowrap;
                  border-radius: 2px;
                  padding: 3px 10px;
                  color: white;

                  .alignDot {
                    line-height: 4px;
                    vertical-align: middle;
                  }
                }

                .bottomArrow {
                  position: absolute;
                  width: 0;
                  height: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  top: -7px;
                  border-top: 3px solid black;
                  border-left: 3px solid transparent;
                  border-right: 3px solid transparent;
                }
              }
            }

            .checkmarkContainer:hover .toggleDisabledContainer {
              display: block;
            }
          }
        }

        .latestCommentText {
          font-size: 13px;
          font-weight: 500;
          margin-top: 10px;
          line-height: 24px;
        }
      }
    }
  }
}

.editModeBody {
  height: calc(100vh - 150px);
  overflow: auto;
  .page {
    .dividerContainer {
      border-top: 1px solid #5b5f72;
    }
    .title {
      font-size: 24px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0 auto;
      color: #dccaa9;
      font-size: 12px;
      font-weight: 600;
      margin-top: 10px;
      margin-left: 6%;
      display: flex;
      align-items: center;
    }
    .emptyMessage {
      height: 100%;
      width: 88%;
      background-color: #2c3040;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0px 20px 0px;
      border-radius: 4px;
      margin: 20px 0px 20px 0px;
    }
    .messageContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .conversation {
      width: 88%;
      margin: 0 auto;
      cursor: pointer;

      .dotSeparator {
        font-size: 4px;
      }

      .metadata {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .leftContainer {
          display: flex;

          .commentSVG {
            width: 16px;
          }
          .nameDate {
            display: flex;
            align-items: center;
            .name {
              font-weight: 500;
              margin-left: 4px;
              margin-right: 4px;
              font-size: 13px;
            }
            .date {
              margin-left: 4px;
              font-size: 12px;
              color: #d6dce8;
              position: relative;

              .fullDateTimeContainer {
                display: none;

                .fullDateTime {
                  position: absolute;
                  background-color: black;
                  top: -25px;
                  left: 50%;
                  transform: translateX(-50%);
                  white-space: nowrap;
                  border-radius: 2px;
                  padding: 3px 10px;
                  color: white;
                }

                .bottomArrow {
                  position: absolute;
                  width: 0;
                  height: 0;
                  left: 50%;
                  top: -4px;
                  border-top: 3px solid black;
                  border-left: 3px solid transparent;
                  border-right: 3px solid transparent;
                }
              }
            }
            .date:hover .fullDateTimeContainer {
              display: block;
            }
          }
          .numberComments {
            font-size: 12px;
            margin-left: 4px;
            margin-right: 4px;
            color: #d6dce8;
            display: flex;
            align-items: center;
          }
        }
        .rightContainer {
          display: flex;

          .noCommentContainer {
            display: flex;
            font-size: 10px;
            align-items: center;
            .arrowRight {
              display: flex;
              margin-left: 10px;
            }
          }
          .checkmarkContainer {
            position: relative;
            .toggleDisabledContainer {
              display: none;
              font-size: 12px;

              .disabledText {
                position: absolute;
                background-color: black;
                top: -28px;
                right: 0;
                white-space: nowrap;
                border-radius: 2px;
                padding: 3px 10px;
                color: white;

                .alignDot {
                  line-height: 4px;
                  vertical-align: middle;
                }
              }

              .bottomArrow {
                position: absolute;
                width: 0;
                height: 0;
                left: 50%;
                transform: translateX(-50%);
                top: -7px;
                border-top: 3px solid black;
                border-left: 3px solid transparent;
                border-right: 3px solid transparent;
              }
            }
          }

          .checkmarkContainer:hover .toggleDisabledContainer {
            display: block;
          }
        }
      }

      .latestCommentText {
        font-size: 13px;
        font-weight: 500;
        margin-top: 10px;
        line-height: 24px;
      }
    }
  }
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: end;
  align-content: center;
  align-items: center;
  min-height: 250px;
  padding: 0px 50px 0px 50px;
  text-align: center;
}

.emptyStateMessage {
  height: 95%;
  background: #2c3041;
  margin: 10px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.emptyStateMessageLineOne {
  margin: 20px 0px 20px 0px;
}

.emptyStateHeader {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 20px;
}

.emptyStateBody {
  font-size: 12px;
  font-weight: 400;
}

.forMeEmptyState {
  text-align: center;
  background: rgb(44, 48, 65);
  width: 50%;
  border-radius: 9px;
  padding: 15px 60px;
  font-size: 13px;
  line-height: 22px;
}

.forMeEmptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
}

.sortedTopDividerContainer {
  border-top: 1px solid #5b5f72;
  margin-top: 32px;
}

.loaderContainer {
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.loaderText {
  color: rgba(186, 192, 203, 1);
}

.emptyStateMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.emptyStateMessageLineOne {
  margin: 20px 0px 20px 0px;
  font-size: 14px;
}

.emptyStateMessageLineTwo {
  font-size: 14px;
  width: 90%;
}

.noAnnotatedCommentsSidePanelOptions {
  background-color: #2c3041;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 30px;
  vertical-align: middle;
  height: calc(100vh - 356px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noGeneralCommentsSidePanelOptions {
  background-color: #2c3041;
  border-radius: 4px;
  padding: 30px 20px;
  margin: 30px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.exportButton {
  border: none;
  color: #5bc1ca;
  padding: 10px 20px 0px 20px;
  background: none;
  box-shadow: none;
  font-size: 14px;
}

.exportButton:hover {
  border: none;
  color: #1b8c96;
  padding: 10px 20px 0px 20px;
  background: #2c3041;
  border: none;
  box-shadow: none;
  font-size: 14px;
}

.exportButton:focus {
  border: none;
  color: #1b8c96;
  padding: 10px 20px 0px 20px;
  background: #2c3041;
  border: none;
  box-shadow: none;
  font-size: 14px;
}

.emptySidePanelOptions {
  background-color: #2c3041;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 30px;
  vertical-align: middle;
  height: calc(100vh - 209px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transitionState {
  display: flex;
  align-items: center;
  height: 20px;
}

.emptySidePanelOptionsGuestViewer {
  background-color: #2c3041;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 30px;
  vertical-align: middle;
  height: calc(100vh - 133px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  margin-left: 10px;
  margin-right: 10px;
}

.addGeneralCommentButton {
  width: 214px;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;

  :global(.button-primary) {
    width: 280px;
  }
}

.noCommentsDivider {
  border-top: 1px solid #5b5f72;
  margin-top: 10px;
}

.emptyStateMessageNoAnnotatedComments {
  margin: 20px 0px 20px 0px;
  font-size: 14px;
  width: 95%;
}

.emptyStateMessageNoAnnotatedComments {
  margin: 20px 0px 0px 0px;
  font-size: 14px;
  width: 95%;
}

.error {
  border: 1px solid #ff6666;
}

.errorMessage {
  color: #ff6666;
}

.error:focus {
  border: 1px solid #ff6666;
}

.noGeneralCommentsTitle {
  text-transform: uppercase;
  margin: 0 auto;
  color: #dccaa9;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 13px;
  margin-top: 26px;
  margin-left: 6%;
}

.mobileConversationsPanel {
  background-color: #393d50;
  color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  overflow-y: auto;

  .header {
    margin: 0 auto;
    padding: 16px 16px 16px 16px;
    width: 88%;

    .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftContainer {
        .title {
          font-size: 24px;
          font-weight: 500;
          display: flex;
          align-items: center;
        }
      }

      .rightContainer {
        display: flex;
        align-items: center;

        .sortContainer {
          margin-right: 10px;
          position: relative;

          .sortButton {
            cursor: pointer;
            display: flex;
            height: 20px;
            width: 20px;
          }

          .sortOptions {
            position: absolute;
            width: 150px;
            background-color: white;
            right: 0;
            box-shadow: 0 6px 22px 0 rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            z-index: 1;

            .option {
              cursor: pointer;
              display: flex;
              align-items: center;
              text-transform: capitalize;
              height: 44px;
              box-sizing: border-box;
              padding: 11px 20px;
              color: #393d50;
              font-size: 14px;
              font-weight: 500;

              &.optionSelected {
                color: #1b8c96;
                justify-content: space-between;

                .checkmark {
                  display: flex;
                }
              }
            }

            & > .option:not(:last-child) {
              border-bottom: 1px solid #dddddd;
            }
          }
        }

        .closeButton {
          display: flex;
          align-items: center;
          cursor: pointer;

          .closeIcon {
            height: 16px;
            width: 16px;
          }
        }
      }
    }

    .searchInputContainer {
      display: none !important;
      .searchInputField {
        margin: 0 auto;
        margin-top: 22px;
        background-color: #2c3041;
        border: none;
        color: #8a8e96;
        &::placeholder {
          color: #8a8e96;
          background-image: url("../../../../images/icons/svg/search.svg");
          background-repeat: no-repeat;
          background-position: top right;
        }
      }
    }
  }
  .body {
    .page {
      .dividerContainer {
        border-top: 1px solid #5b5f72;
      }
      .title {
        text-transform: uppercase;
        margin: 0 auto;
        color: #dccaa9;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 13px;
        margin-top: 26px;
        margin-left: 6%;
      }
      .emptyMessage {
        height: 100%;
        width: 88%;
        background-color: #2c3040;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0px 20px 0px;
        border-radius: 4px;
        margin: 20px 0px 20px 0px;
      }
      .messageContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .conversation {
        width: 88%;
        margin: 0 auto;
        cursor: pointer;

        .dotSeparator {
          font-size: 4px;
        }

        .metadata {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .leftContainer {
            display: flex;

            .commentSVG {
              width: 16px;
            }
            .nameDate {
              display: flex;
              align-items: center;
              .name {
                font-weight: 500;
                margin-left: 4px;
                margin-right: 4px;
                font-size: 13px;
              }
              .date {
                margin-left: 4px;
                font-size: 12px;
                color: #d6dce8;
                position: relative;

                .fullDateTimeContainer {
                  display: none;

                  .fullDateTime {
                    position: absolute;
                    background-color: black;
                    top: -25px;
                    left: 50%;
                    transform: translateX(-50%);
                    white-space: nowrap;
                    border-radius: 2px;
                    padding: 3px 10px;
                    color: white;
                  }

                  .bottomArrow {
                    position: absolute;
                    width: 0;
                    height: 0;
                    left: 50%;
                    top: -4px;
                    border-top: 3px solid black;
                    border-left: 3px solid transparent;
                    border-right: 3px solid transparent;
                  }
                }
              }
              .date:hover .fullDateTimeContainer {
                display: block;
              }
            }
            .numberComments {
              font-size: 12px;
              margin-left: 4px;
              margin-right: 4px;
              color: #d6dce8;
              display: flex;
              align-items: center;
            }
          }
          .rightContainer {
            display: flex;

            .noCommentContainer {
              display: flex;
              font-size: 10px;
              align-items: center;
              .arrowRight {
                display: flex;
                margin-left: 10px;
              }
            }
            .checkmarkContainer {
              position: relative;
              .toggleDisabledContainer {
                display: none;
                font-size: 12px;

                .disabledText {
                  position: absolute;
                  background-color: black;
                  top: -28px;
                  right: 0;
                  white-space: nowrap;
                  border-radius: 2px;
                  padding: 3px 10px;
                  color: white;

                  .alignDot {
                    line-height: 4px;
                    vertical-align: middle;
                  }
                }

                .bottomArrow {
                  position: absolute;
                  width: 0;
                  height: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  top: -7px;
                  border-top: 3px solid black;
                  border-left: 3px solid transparent;
                  border-right: 3px solid transparent;
                }
              }
            }

            .checkmarkContainer:hover .toggleDisabledContainer {
              display: block;
            }
          }
        }

        .latestCommentText {
          font-size: 13px;
          font-weight: 500;
          margin-top: 10px;
          line-height: 24px;
        }
      }
    }
  }
}

.shiftingUpTitle {
  display: flex;
}

.editControlsContainer {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  width: 320px;
  padding: 30px 0px 30px 0px;
}

.cancelButton {
  margin-right: 10px;
}

.reviewButton:disabled {
  background: #d9d9d9 !important;
  color: white !important;
  border: none !important;
}

.cancelEditButton {
  background: #393d50;
  color: #ffffff;
  width: 35%;
}

.cancelEditButton:hover {
  background: #393d50;
  color: #ffffff;
  width: 35%;
}

.applyChangesButton {
  width: 50%;
}

.moveIcon {
  margin-right: 10px;
}

.referenceDocumentMenu {
  margin-left: 10px;
  cursor: pointer;
}

.pageTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 6%;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 20px;
}

.shiftToPage {
  color: #ff9a31;
}

@media screen and (min-width: 1440px) {
  .editControlsContainer {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    width: 400px;
    padding: 30px 0px 30px 0px;
  }
  .conversationsPanel {
    background-color: #393d50;
    color: #fff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    overflow-y: auto;
  }
  .addGeneralCommentButton {
    width: 214px;
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;

    :global(.button-primary) {
      width: 280px;
    }
  }
  .emptyStateMessageLineTwo {
    font-size: 14px;
    width: 80%;
  }
  .emptyStateMessageNoAnnotatedComments {
    margin: 20px 0px 20px 0px;
    font-size: 14px;
    width: 90%;
  }
}
