.container {
  overflow: auto;
  margin-left: 30px;
  margin-right: 30px;
  height: 300px;
}

.emptyContainer {
  margin-left: 30px;
  margin-right: 30px;
  height: 300px;
  background: #ffffff;
  border: 1px solid #d6dce8;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyStateText {
  color: rgba(62, 63, 66, 1);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.infoIcon {
  margin-right: 10px;
}

.top {
  display: flex;
  margin-left: 35px;
  margin-right: 35px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.templateTitle {
  cursor: pointer;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 20px;
  margin-right: 10px;
  color: rgba(62, 63, 66, 1);
}

.topLinksContainer {
  background: #ffffff;
  border: 1px solid #d6dce8;
  box-sizing: border-box;
  border-radius: 9px;
  padding: 30px;
  width: 40%;
  min-width: 390px;
}

.chartContainer {
  background: #ffffff;
  border: 1px solid #d6dce8;
  box-sizing: border-box;
  border-radius: 9px;
  padding: 30px;
  width: 55%;
  min-width: 550px;
}

.clicksTotal {
  // font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: #6b6c6f;
  text-align: right;
}

.downloadsTotal {
  // font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
  color: #6b6c6f;
  text-align: right;
}

.clicksContainer {
  margin-right: 50px;
}

.totalsContainer {
  display: flex;
}

.summaryContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 2%;
  padding-bottom: 65px;
}

.clicksTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  /* identical to box height */
  text-transform: uppercase;

  color: #393d50;
}

.downloadsTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  /* identical to box height */
  text-transform: uppercase;

  color: #393d50;
}

.titleContainer {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;

  color: #393d50;
}

.pageHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 35px;
  /* identical to box height, or 140% */

  color: #3e3f42;
}

.activityLogSubheadingOne {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;
  text-transform: uppercase;
  color: #9ea0a5;
  mix-blend-mode: normal;
  opacity: 0.9;
  margin-left: 20px;
}

.activityLogSubheadingTwo {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;
  text-transform: uppercase;
  color: #9ea0a5;
  mix-blend-mode: normal;
  opacity: 0.9;
  margin-right: 150px;
}

.activityLogSubheadingContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.activityLogHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #3e3f42;
  margin-left: 30px;
  margin-bottom: 5px;
}

.highlightTemplate {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eaedf3;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-between;
  background-color: #e2eef1;
}

.templateDetails {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eaedf3;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-between;
  word-wrap: break-word;
  word-break: break-word;
}

.templateDetails:focus {
  background-color: #e2eef1;
}

.lastTemplate {
  display: flex;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-between;
  word-wrap: break-word;
  word-break: break-word;
  margin-bottom: 30px;
  border-bottom: 1px solid #eaedf3;
}

.allContainer {
  position: relative;
}

.scrollIndicator {
  position: absolute;
  width: 100%;
  z-index: 2000;
  bottom: -30px;
  height: 35px;
  background: linear-gradient(
    360deg,
    rgba(138, 142, 150, 0.13) 0%,
    rgba(255, 255, 255, 0) 63.68%
  );
}

.lastTemplate:focus {
  background-color: #e2eef1;
}

.activityLogHeaderContainer {
  display: flex;
  justify-content: space-between;
}

.closeButton {
  margin-right: 30px;
  cursor: pointer;
}

.labelContainer {
  display: flex;
  margin-left: 30px;
  margin-bottom: 15px;
}

.linkLabel {
  font-size: 14px;
  font-family: Montserrat;
  color: #6b6c6f;
  font-weight: 600;
  margin-right: 5px;
}

.linkDocumentName {
  font-size: 12px;
  font-family: Montserrat;
  color: #9ea0a5;
  font-weight: 500;
  margin-top: 1px;
}

.eventType {
  padding-left: 50px;
  width: 50%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #3e3f42;
}

.eventDate {
  padding-left: 48px;
  width: 50%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #3e3f42;
}

.eventTypeIcon {
  margin-right: 15px;
}

.rightContainer {
  display: flex;
  align-items: flex-start;
  word-break: normal;
  margin-right: 20px;
}

.previewLink {
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(27, 140, 150, 1);
  margin-right: 20px;
  cursor: pointer;
}

.editIcon {
  margin-right: 20px;
  cursor: pointer;
}

.deleteIcon {
  cursor: pointer;
}
