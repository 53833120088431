.uik-checkbox__wrapper.uik-checkbox__radio {
  .uik-checkbox__label {
    color: #3e3f42;
    font-weight: 500;
  }
  .uik-checkbox__checkbox:checked {
    & + .uik-checkbox__label {
      color: #1b8c96;
      font-weight: bold;
      &:before {
        background-image: linear-gradient(0deg, #1b8c96 0%, #1b8c96 100%);
      }
      &:after {
        height: 6px;
        width: 6px;
        top: 6px;
        left: 6px;
      }
    }
  }
}
