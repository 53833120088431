.editTagContainer {
  background-color: #fbfbfb;
  margin-top: 30px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 5px;
}

.editContainer {
  padding: 0 20px;
}

.editActionButtons {
  padding: 20px;
}

.formLabel {
  font-weight: 500;
  font-size: 0.75rem;
  color: #9ea0a5;
  line-height: 1.125rem;
  text-transform: uppercase;
}

.roleSelect {
  margin-top: 0.5rem;
}

.checkbox {
  margin: 0.2rem 0;
}
