.inviteContactContainer {
  background-color: #fbfbfb;
  margin-top: 30px;
}

.inviteContainer {
  padding: 0 20px;
}

.inviteActionButtons {
  padding: 20px;
}

.formLabel {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #9ea0a5;
}
