.leftSideMenuContainer {
  padding: 0px !important;
  background-color: #ffffff;
  border-top: 1px solid #eaedf3;
  max-width: 370px;
  min-width: 370px;
  width: 370px;
}

.leftSideMenu {
  padding: 36px 16px;
}

.leftSideMenuItem {
  display: flex;
  justify-content: space-between;
  color: #344054;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  vertical-align: middle;
  width: 100%;
  padding: 10px;
  border-radius: 7px;
}

.categoryContainer {
  display: flex;
}

.categoryIcon {
  margin-right: 10px;
}

.countPill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  gap: 10px;

  width: 33px;
  height: 16px;

  background: #eaedf3;
  border-radius: 9.5px;
  font-family: inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: center;
}

.newCategory {
  border-top: 1px solid #eaedf3;
  padding-top: 10px;
  margin-top: 10px;
}

.newCategoryIcon {
  margin-right: 10px;
}

.leftSideMenuItem.selected {
  border-color: #f0f9ff;
  background: #e6f2f3;
  color: #1b8c96;
}

.leftSideMenuHeader {
  color: #393d50;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 40px;
}

.leftSideMenuItem a {
  color: #3e3f42 !important;
  text-decoration: none;
}

.newCategoryFormCol {
  float: left;
  width: 49%;
}

.formContainer {
  display: block;
}

.customInputContainer {
  justify-content: space-between;
  margin-bottom: 20px;
  padding-top: 7px;
}

.customInputLabel {
  color: #9b9b9b;
  display: block;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-transform: uppercase;
}

.statusDescription {
  left: 0px;
  right: 0px;
  top: 56px;
  bottom: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #6d7382;
  min-width: 500px;
}

.inputFieldContainer {
  margin-bottom: 20px;
}

.radiobuttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.formLabel {
  font-size: 0.75rem;
  color: #9ea0a5;
  line-height: 1.125rem;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.errorMessage {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #e67670;
  margin-top: 0;
}

.inputFieldContainerError {
  border: 1px solid #e67670;
}

.spaceCol {
  float: left;
  width: 2%;
}

.categoryTypeSelection {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #393d50;
  display: flex;
}

.categoryType {
  cursor: pointer;
  display: flex;
  margin-right: 5px;
  padding: 10px;
  width: 135px;
  border: 1px solid #d6dce8;
  border-radius: 7px;
}

.categoryType.selected {
  background-color: #e6f2f3;
}

.editableFieldsActive {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  position: relative;
  top: -5px;
}

.newCategoryButton {
  color: #1b8c96;
}

.kbLink {
  color: rgba(5, 125, 136, 1);
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
}
