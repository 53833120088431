.referenceLibrariesContainer {
  width: 100%;
  position: absolute;
  top: 71px;
  background-color: #393d50;
  z-index: 1;
  min-height: calc(100% - 71px);
  margin-left: -15px;
  padding: 0px 15px 0px 15px;
  max-width: calc(100% - 30px);
}

.searchTitleDisabled {
  color: white;
  float: left;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  margin-left: 15px;
}

.documentContainer {
  border-radius: 4px;
  background-color: rgba(44, 48, 65, 1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  width: 250px;
  margin: 15px;
  display: inline-block;
  float: left;
}

.heading {
  color: rgba(255, 255, 255, 1) !important;
  display: block;
  padding: 15px;
  width: 85%;
  /*height: 95px;*/
  height: 25px;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.libraryIcon {
  width: 170;
  height: 170px;
  padding-bottom: 15px;
}

.documentList {
  clear: both;
}

.iconContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
}

.lockIcon {
  width: 48px;
  height: 48px;
  margin: 10% auto 1% auto;
  display: block;
}

.noLibraryMessage {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.47;
  text-align: center;
  color: white;
  margin: 0 0 0 0;
}

.noLibrarySubMessage {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  text-align: center;
  color: white;
}

.countPill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  gap: 10px;
  min-width: 50px;
  height: 16px;
  border-radius: 9.5px;
  font-family: inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: center;
  background: #393d50;
  color: white;
}
