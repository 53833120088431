.tableContainer {
  width: 100%;
  border-collapse: collapse;

  .col {
    width: 10%;
  }

  .colLarge {
    width: 20%;
  }

  .header {
    tr {
      color: #a8aaaf;
      font-weight: 600;
      font-size: 11px;
      line-height: 22px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      border-bottom: 1px solid #d6dce8;
      width: 100%;

      th {
        min-width: 150px;
        padding-bottom: 10px;
        padding-left: 20px;
        width: 10%;
      }

      .columnName {
        cursor: pointer;
        .container {
          display: flex;
          align-items: center;

          .text {
            &Bold {
              color: #3e3f42;
            }
            margin-top: 10px;
            white-space: nowrap;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;

            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }

        &:hover {
          .text {
            color: #3e3f42;
            margin-top: 10px;
          }
        }
      }
      .unsortableColumnName {
        cursor: default;
        .container {
          display: flex;
          align-items: center;

          .text {
            margin-top: 10px;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;

            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  .body {
    color: #3e3f42;
    tr {
      border-bottom: 1px solid #d6dce8;
      height: 60px;

      &:last-of-type {
        border-bottom: none;
      }

      td {
        min-width: 100px;
        padding: 10px 4px 10px 20px;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 0;
      }

      .link {
        .container {
          display: flex;
          justify-content: center;
          align-items: center;

          .text {
            color: #1b8c96;
            margin-right: 9px;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            margin-top: 10px;
          }
        }
      }

      &:hover {
        background: #e2eef1;
        cursor: pointer;
      }
    }
  }
}

.tableViewWrapper {
  width: calc(100% - 400px);
}

.tableViewContainer {
  display: flex;
  border: 1px solid #d6dce8;
  border-radius: 6px;
  background-color: #ffffff;
  margin-top: 20px;
  max-height: 78vh;
  overflow-y: auto;
}

.manualSendBadge {
  line-height: 1.5;
  border-radius: 4px;
  color: #fff;
  padding: 8px 8px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #15cd93;
}

.titleTableContainer {
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid #d6dce8;

  .col {
    width: 10%;
  }

  .colLarge {
    width: 20%;
  }

  .header {
    tr {
      color: #a8aaaf;
      font-weight: 600;
      font-size: 11px;
      line-height: 22px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      border-bottom: 1px solid #d6dce8;
      width: 100%;

      th {
        min-width: 100px;
        padding-bottom: 10px;
        padding-left: 20px;
      }

      .columnName {
        cursor: pointer;
        .container {
          display: flex;
          align-items: center;

          .text {
            &Bold {
              color: #3e3f42;
            }
            margin-top: 10px;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;
            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }

        &:hover {
          .text {
            color: #3e3f42;
            margin-top: 10px;
          }
        }
      }
      .unsortableColumnName {
        cursor: default;
        .container {
          display: flex;
          align-items: center;

          .text {
            margin-top: 10px;
          }

          .icon {
            margin-left: 5px;
            margin-top: 10px;

            img {
              display: none;

              &.show {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  .body {
    color: #3e3f42;
    tr {
      border-bottom: 1px solid #d6dce8;
      height: 60px;

      &:last-of-type {
        border-bottom: none;
      }

      td {
        min-width: 100px;
        padding: 10px 4px 10px 20px;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 0;
      }

      .link {
        .container {
          display: flex;
          justify-content: center;
          align-items: center;

          .text {
            color: #1b8c96;
            margin-right: 9px;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            margin-top: 10px;
          }
        }
      }

      &:hover {
        background: #e2eef1;
        cursor: pointer;
      }
    }
  }
}

.tableTwoContainer {
  overflow-x: auto;
  min-width: 75%;
  scrollbar-color: #d6dce8 #f3f5f9;
  scrollbar-width: thin;
  margin-right: 20px;
}

.emptyStateContainer {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyMessageContainer {
  display: flex;
}

.InfoIcon {
  margin-right: 10px;
}

.subContainer {
  width: 100%;
  overflow: auto;
}

.tableTwoContainer::-webkit-scrollbar-track {
  background-color: #f3f5f9;
}

.tableTwoContainer::-webkit-scrollbar {
  height: 5px;
}

.tableTwoContainer::-webkit-scrollbar-thumb {
  background-color: #d6dce8;
  border-radius: 8px;
}

.tableViewHeader {
  color: #101828;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin: 25px;
  display: flex;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
}

.rightContainer {
  display: flex;
}

.container {
  width: 100%;
}

.image {
  width: 136px;
  height: 80px;
}

.backLink {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;

  img {
    height: 18px;
  }
}

.title {
  display: flex;
  align-items: center;
}

.actionIcon {
  margin: 0 5px;
}
