.container {
  width: 320px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background: #393d50;
  padding: 15px;
  box-sizing: border-box;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 15px;
    border-bottom: 1px solid #5b5f72;

    .text {
      font-weight: 500;
      font-size: 24px;
      color: white;
    }

    .closeIcon {
      display: flex;
      cursor: pointer;
    }
  }

  .body {
    padding: 0 10px;

    .signedBy {
      color: white;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin-top: 23px;
    }

    .signedAt {
      margin-top: 17px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;

      color: #eaedf3;
    }
  }
}

@media screen and (min-width: 1440px) {
  .container {
    width: 400px;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    background: #393d50;
    padding: 15px;
    box-sizing: border-box;
  }
}
