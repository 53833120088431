.conversation {
  width: 88%;
  margin: 0 auto;
  cursor: pointer;

  .dotSeparator {
    font-size: 4px;
  }

  .metadata {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftContainer {
      display: flex;

      .commentSVG {
        width: 16px;
      }
      .nameDate {
        display: flex;
        align-items: center;
        .name {
          font-weight: 500;
          margin-right: 4px;
          font-size: 13px;
        }
        .date {
          margin-left: 4px;
          font-size: 12px;
          color: #d6dce8;
          position: relative;

          .fullDateTimeContainer {
            display: none;

            .fullDateTime {
              position: absolute;
              background-color: black;
              top: -25px;
              left: 50%;
              transform: translateX(-50%);
              white-space: nowrap;
              border-radius: 2px;
              padding: 3px 10px;
              color: white;
            }

            .bottomArrow {
              position: absolute;
              width: 0;
              height: 0;
              left: 50%;
              top: -4px;
              border-top: 3px solid black;
              border-left: 3px solid transparent;
              border-right: 3px solid transparent;
            }
          }
        }
        .date:hover .fullDateTimeContainer {
          display: block;
        }
      }
      .numberComments {
        font-size: 12px;
        margin-left: 4px;
        margin-right: 4px;
        color: #d6dce8;
        display: flex;
        align-items: center;
      }
    }
    .rightContainer {
      display: flex;

      .noCommentContainer {
        display: flex;
        font-size: 10px;
        align-items: center;
        .arrowRight {
          display: flex;
          margin-left: 10px;
        }
      }
      .checkmarkContainer {
        position: relative;
        .toggleDisabledContainer {
          display: none;
          font-size: 12px;

          .disabledText {
            position: absolute;
            background-color: black;
            top: -28px;
            right: 0;
            white-space: nowrap;
            border-radius: 2px;
            padding: 3px 10px;
            color: white;

            .alignDot {
              line-height: 4px;
              vertical-align: middle;
            }
          }

          .bottomArrow {
            position: absolute;
            width: 0;
            height: 0;
            left: 50%;
            transform: translateX(-50%);
            top: -7px;
            border-top: 3px solid black;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
          }
        }
      }

      .checkmarkContainer:hover .toggleDisabledContainer {
        display: block;
      }
    }
  }

  .latestCommentText {
    font-size: 13px;
    font-weight: 500;
    margin-top: 10px;
    line-height: 24px;
  }

  .countContainer {
    display: flex;
    justify-content: flex-end;
  }
}

.highlight {
  background-color: #fff104;
  color: black;
}
.myCommentHighlight {
  background-color: #215d84;
}

.othersCommentHighlight {
  background-color: #212536;
}
.currentUserComment {
  border-left: 3px solid #c4c4c4;
  padding-left: 20px;
}

.currentUserCommentContainer {
  padding-bottom: 15px;
}

.firstCommentorsCommentContainer {
  padding-top: 15px;
}

.threadContainer:hover {
  background: #34384a;
}
