.users-screen {
  min-height: 100vh;
}

.users-screen__container {
  /*background-color: #fbfbfb;*/
  /* min-height: 100vh; */
}

.users-screen-user-list-container {
  border-left: 1px solid #eaedf3;
  border-right: 1px solid #eaedf3;
  border-top: 1px solid #eaedf3;
  padding: 0px !important;
  background-color: #ffffff;
  height: calc(100vh - 71px);
  overflow: auto;
}

.users-screen-user-search {
  border-bottom: 1px solid #eaedf3;
  padding: 13px 22px;
}

.users-screen-user-search-input {
  border: none;
}

.user-search-input-container {
  display: inline-block;
  width: 70%;
}

.user-invite-button-container {
  float: right;
}

.users-screen-user-form-container {
  height: calc(100vh - 71px);
  overflow: auto;
}
