.draftListItem {
  padding: 20px 30px;
  border: 1px solid #eaedf3;
  margin-bottom: -1px;
  background: #fff;
}

.warningBadge {
  background-color: #ff9c79;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  text-transform: uppercase;
  margin-right: 5px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  width: 5rem;
  text-align: center;
}

.draftBadge {
  background-color: #16a2b8;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  text-transform: uppercase;

  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  width: 5rem;
  text-align: center;
}

.approvedBadge {
  background-color: #15cd93;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  text-transform: uppercase;
  float: right;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  width: 10rem;
  text-align: center;
}

.wrapper {
  width: 100%;
  float: left;
}

.rejectedBadge {
  background-color: #d94c53;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  float: right;
}

.draftListItemInner {
  float: left;
  width: 100%;
}

.draftListItem {
  position: relative;

  &:hover {
    border-style: none solid solid;
    border-color: #eaedf3;
    border-bottom-width: 1px;
    border-left-width: 1px;
    background-color: transparent;
    box-shadow: 6px 6px 20px 0 rgba(0, 0, 0, 0.05);

    .draftListItemInner {
      transition-duration: 0.2s;
      transform: translateY(-5px);
      display: block;
    }
  }
}

.draftListPlate {
  float: left;
}

.documentTitle {
  min-height: 22px;
  color: #3e3f42;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  float: left;
}

.dueDate {
  color: #8a8e96;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  float: left;
  clear: left;
  margin-top: 8px;
}

.pendingApprovals {
  color: #8a8e96;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  float: right;
}

@media screen and (min-width: 575px) and (max-width: 780px) {
  .draftListItemInner {
    display: flex;
    flex-direction: column;
  }

  .documentTitle {
    float: none;
    order: 1;
  }

  .dueDate {
    float: none;
    order: 2;
  }

  .pendingApprovals {
    float: none;
    order: 3;
  }
}

.processingContainer {
  position: absolute;
  right: 3px;
  bottom: 3px;
  font-size: 12px;

  line-height: 12px;

  img {
    width: 12px;
    height: 12px;
  }
}
