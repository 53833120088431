.draftUploadForm {
  background: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  margin-top: 30px;
  padding: 30px !important;
  width: 730px;
}

.autoApproveContainer {
  background: #fff;
  border: 1px solid #eaedf3;
  border-radius: 4px;
  padding: 20px 30px 20px 30px !important;
  width: 730px;
  border-top: none;
  display: flex;
  flex-direction: column;
}

.toggleContainer {
  display: flex;
  align-items: center;
}

.approvalToggleContainer {
  display: flex;
  align-items: center;
}

.approvalToggleLabel {
  display: flex;
  margin-right: 100px;
}

.toggleLabel {
  display: flex;
  margin-right: 20px;
}

.toolTipContainer {
  margin-top: 2.5px;
  margin-left: 10px;
  position: relative;
}

.uploadError {
  width: 470px;
  height: 22px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #e67670;
  float: left;
  width: 86%;
}

.draftUploadFormActions {
  background: none;
  margin: 30px auto;
  width: 730px;
}

.buttonRow {
  justify-content: space-between !important;
  flex-direction: row-reverse;
}

.mainContentUploader {
  border: 1px dashed #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.mainContentUploading {
  background-color: #fbfbfd;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.innerContentUploading {
  line-height: 30px;
  float: left;
  width: 100%;
}

.uploaderIcon {
  color: #9ea0a5;
  display: inline-block;
  line-height: 30px;
  margin: 10px 10px 0;
  float: left;
}

.uploaderIconCheck {
  color: #15cd93;
  display: inline-block;
  float: right;
  line-height: 22px;
  margin: 10px 0 0 0;
  float: left;
}

.uploaderPlaceholder {
  color: #9ea0a5;
  font-size: 14px;
  line-height: 45px;
}

.uploaderButton {
  float: right;
}

.uploadProgress {
  height: 4px;
  width: 100%;
  border-radius: 2px;
}

.uploadingFilename {
  color: #3e3f42;
  font-size: 14px;
  line-height: 45px;
  float: left;
  padding-top: 2px;
  width: 86%;
}

.referenceContentUploader {
  border: 1px dashed #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.referenceContentUploading {
  background-color: #fbfbfd;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 10px 20px;
  vertical-align: middle;
}

.cancelButton {
  width: 122px;
  float: left;
  border: 1px solid #1b8c96 !important;
  color: #1b8c96;
}

.nextButton {
  width: 122px;
  float: right;
}

.checkMark {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.metadataSingleLineInputFields {
  float: left;
  width: 45%;
}

.metadataMultilineInputFields {
  float: left;
  margin-left: 30px;
  width: 50%;
}

.processingContainer {
  float: right;
  line-height: 32px;
}

.notesTextarea {
  resize: none;
  margin-top: 14px;
  &:focus {
    border-color: #d6dce8;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  color: #3e3f42;
  font-weight: 500;

  .leftContainer {
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .rightContainer {
    font-size: 14px;
    display: flex;
    align-items: center;

    .toggle {
      margin-left: 15px;
    }
  }
}

.titleContainer {
  display: flex;
}

.title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(107, 108, 111, 1);
  margin-left: 10px;
}

.description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #6b6c6f;
  padding-bottom: 10px;
}

.button {
  cursor: pointer;
  border: 1px solid rgba(27, 140, 150, 1);
  border-radius: 4px;
  width: 100px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(27, 140, 150, 1);
  padding: 7px 15px 7px 15px;
}

.linkIcon {
  margin-left: 10px;
}

.passwordConfirmation {
  min-height: 100px;
  padding-left: 15px;

  .confirmPasswordLabel {
    color: #9ea0a5;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  :global(.button-primary__original) {
    width: 100%;
    margin-top: 10px;
    &:disabled {
      color: white;
      border: 1px solid #166f77;
      background: linear-gradient(
        0deg,
        #1b8c96 2.22%,
        #1b8c96 56.57%,
        #1b8c96 98.44%
      );
      opacity: 0.5;
    }
  }
  :global(.uik-input__input) {
    &:focus {
      border-color: #1b8c96;
    }
  }
  &.passwordInvalid {
    .passwordInvalidLabel {
      color: #e67670;
      font-size: 12px;
    }
    :global(.uik-input__input) {
      border-color: #e67670;
    }
  }
}

.passwordAbsentLabel {
  color: #e67670;
  font-size: 12px;
}
