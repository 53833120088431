.container {
  min-height: 100vh;
  width: 100%;
  .midContainer {
    width: 100%;
    display: flex;
    .row {
      min-height: 100vh;
      width: 100%;
      display: flex;
    }
  }
}

.loaderContainer {
  padding-top: 200px;
  padding-bottom: 200px;
}

.searchInputContainer {
  position: relative;
  .searchIcon {
    position: absolute;
    top: 11px;
    left: 13px;
  }

  .crossIcon {
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;
  }

  input {
    width: 100%;
    border: 1px solid #d6dce8;
    border-radius: 4px;
    height: 38px;
    padding: 8px 40px;
    box-sizing: border-box;
    color: -internal-light-dark(white, white);

    &:focus {
      border-color: #1b8c96;
      outline: none;
    }

    &::placeholder {
      color: #bfbfbf;
    }
  }
}

.body {
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.midContainer {
  display: flex;
}

.gearIcon {
  height: 16px;
  width: 16px;
  margin-right: 20px;
  // margin-left: 20px;
  cursor: pointer;
}
