.drafts {
}

.draftsHome {
  margin-top: 35px;
}

.draftsHeading {
  margin: 0 auto;
  text-align: left;
}

.draftsHeading h1 {
  font-size: 24px;
  margin: 10px 0 5px 0;
}

.draftsHeading span {
  font-size: 14px;
}

.draftsHomeLists {
  background: #fff;
  border-radius: 4px;
  margin: 30px auto;
}

.plusIcon {
  margin-right: 10px;
}

.empty {
  display: block;
  padding: 20px 30px;
}

.inProgressHeading {
  float: left;
}

.uploadAction {
  background: #1b8c96 !important;
  color: #fff;
  float: right;
  margin-top: 15px;
}
