.userListItem {
  border-bottom: 1px solid #eaedf3;
  border-left: solid 3px #fff;
  padding: 15px 30px;
}

.userListItem.selected {
  border-left: solid 3px #1b8c96;
  background-color: #f6fdfd;
}

.userListItemAvatar {
  background-color: #496c8b;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  float: left;
  margin-right: 25px;
  padding: 5px;
  text-align: center;
  height: 38px;
  width: 38px;
}

.deactiveUserListItemAvatar .userListItemAvatar {
  border: 1px solid #bac0cb;
  background-color: #fff;
  color: #bac0cb;
}

.userListItemNameplate span {
  text-decoration: none !important;
}

.userListItemNameplateName {
  color: #3e3f42;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.deactiveUserListItemAvatar .userListItemNameplateName {
  color: #bac0cb;
}

.userListItemNameplateTitle {
  color: #6b6c6f;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.deactiveUserListItemAvatar .userListItemNameplateTitle {
  color: #bac0cb;
}

.userListItemInitials {
  font-size: calc(34px / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(38px / 4); /* 25% of parent */
}
