.commentCanDelete {
  // &:hover {
  //   .commentDate {
  //     display: none;
  //   }
  //   .deleteCommentIcon {
  //     display: block;
  //   }
  // }
}

.editedLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 0px;
  color: #fdb022;
  margin-right: 10px;
}

.editingComment {
  background-color: #f79009;
  border-radius: 0 10px 10px 10px;
  border: 1px solid #f79009;
  color: #fff;
  cursor: default;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 12px 0 30px 0;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.commentHeader {
  display: flex;
  align-items: center;
}

.dropdownItemContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dropdownItemLeft {
  display: flex;
  align-items: center;
}

.editIcon {
  margin-right: 10px;
}

.helpText {
  //styleName: Text xs/Regular;
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
}

.commentNamePlateContainer {
  background-color: #9ea0a5;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.commentNamePlateDeactive {
  background: none;
  border: 1px solid #bac0cb;

  .commentNamePlate {
    color: #bac0cb;
  }
}

.commentNamePlate {
  color: #fff;
  font-size: 12px; /* 50% of parent */
  font-weight: 500;
  line-height: 1;
  position: relative;
  top: calc(28px / 12); /* 25% of parent */
}

.commentName {
  height: 15px;
  color: #eaedf3;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

.commentHeaderRight {
  margin-left: auto;
  display: flex;
}

.commentDate {
  height: 18px;
  color: #9ea0a5;

  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  text-align: right;
}
.commentDelete {
}

.commentFromOthers {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border-radius: 10px 0 10px 10px;
  border: 1px solid rgba(255, 255, 255, 0.38);
  color: #fff;
  cursor: default;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 12px 0 30px 0;
  padding: 20px;
}

.commentFromMe {
  background-color: #0a7cb8;
  border-radius: 0 10px 10px 10px;
  border: 1px solid #0a7cb8;
  color: #fff;
  cursor: default;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 12px 0 30px 0;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.menuIcon {
  cursor: pointer;
}

.text {
  // display: flex;
  width: 95%;
}

.deleteCommentIcon {
  float: right;
  display: none;
  cursor: pointer;
}

.timestamp {
  color: #84e2eb;
  display: block;
  font-size: 0.75rem;
  cursor: pointer;
}

.nameContainer {
  display: flex;
  align-items: center;
}

.myCommentHighlight {
  background-color: #215d84;
}

.othersCommentHighlight {
  background-color: #212536;
}

.highlight {
  background-color: #fff104;
  color: black;
}
