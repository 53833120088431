.slide-enter {
  transform: translateX(75%);
}
.slide-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.slide-exit {
  transform: translateX(0);
}
.slide-exit-active {
  transform: translateX(100%);
  transition: transform 300ms;
  transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.conversation-enter {
  transform: translateX(75%);
}
.conversation-enter-active {
  transform: translateX(0);
  transition: transform 700ms;
}
.conversation-exit {
  transform: translateX(0);
}
.conversation-exit-active {
  transform: translateX(100%);
  transition: transform 700ms;
}
